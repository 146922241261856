import React, { useContext, useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AppContext, useUIContext } from "../../Contexts/AppContextProvider";
import { SprintService } from "../../WebApiServices/Sprint.service";
import { SprintOverviewService } from "../../WebApiServices/SprintOverview.service";
import {
  ACTION_ITEM_TYPE,
  HTTPResponse,
  ROLE_ID,
  TASK_PRIO,
  ACTION_ITEM_TYPE_TITLE,
  PriorityListTitle,
  ToastMessageType,
  MaxCharacterCount,
  MonthNames,
} from "../../Helpers/Enums";
import LetteredAvatar from "./LetteredAvatar";
import Select ,{ components } from "react-select";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  AddLocalStorageData,
  GetLocalStorageData,
  GetLoggedInUserID,
  RemoveLocalStorageData,
  isStringEmpty,
} from "../../Helpers/Utilities";
import { HttpStatusCode } from "axios";
import { ActiveSprintMsg, ToastMessages } from "../../Helpers/HelperText";
import { ProjectService } from "../../WebApiServices/Project.service";

const ActiveSprints = ({ onNameChange }) => {
  const navigate = useNavigate();

  const [projectName,setProjectName]=useState(); 
  const { SetActionItemId } = useContext(AppContext);
  const { ToggleLoader,ShowToastMessage } = useContext(AppContext);
  const { LogOut } = useUIContext();
  const pageParams = useParams();
  const projectId = pageParams.projectId;

  const [columns, setColumns] = useState({});
  const [sprintList, setSprintList] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState(null);

  const [activeFilter, setActiveFilter] = useState("");
  const [sprintData, setSprintData] = useState({});
  const [remainingDays, setRemainingDays] = useState("");
  const [totalDays, setTotalDays] = useState("");
  const [selectedEmp, setSelectedEmp] = useState([]);
  //Completed Sprint Section
  const [completedSprintsSprintId, setSelectedSprintsSprintId] = useState([]);
  const [completedItemsCount, setCompletedItemsCount] = useState(0);
  const [incompleteItemsCounts, setIncompleteItemsCounts] = useState(0);
  const [selectedSprintName, setSelectedSprintName] = useState("");
  const [selectedSprintIssueCount, setselectedSprintIssueCount] = useState(0);
  const [notCompletedActionItemIds, setNotCompletedActionItemsIds] = useState(
    []
  );

  const [completedActionItemIds, setCompletedActionItemsIds] = useState(
    []
  );


  const [selectedSprintId, setSelectedSprintId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let totalmappedData = [];
  const [CompleteSprintData, setCompleteSprintData] = useState([]);
  const [activeLastItem, setActiveLastItem] = useState(0);

  const UpdateColumns = (kanbanBoard, columnOrder) => {
    const updatedColumns = Object.fromEntries(
      columnOrder.map((columnId) => [
        columnId,
        { ...kanbanBoard.get(columnId) },
      ])
    );

    setColumns(updatedColumns);
  };
  const [load, setLoad] = useState(false);
  useEffect(() => {
    GetAllActiveSprints();
    GetSprintList();
    GetActionItems();
    getProjectAssignedEmployee();
    setLoad(true);
  }, []);
     
 useEffect(() => {
    if (projectName) {
      onNameChange(projectName);
    }
  }, [projectName]);
  /* <summary>
date: 15-01-2024
Name: Mustakim Shaikh
description: API call to get the ID of last action item column
<summary>*/
  const [lastColumn, setLastColumn] = useState(0);
  // useEffect(() => {
  //   getLastColumnId();
  // }, [projectId]);
  const getLastColumnId = async () => {
    const response = await SprintService.GetLastColumnForProjectActionItem(
      projectId
    );

    setLastColumn(response.data);
    setActiveLastItem(response.data);
  };
  /* <summary>
date: 17-01-2024
Name: Mustakim Shaikh
description: Release Name
<summary>*/
  const formatDate = (dateString) => {


    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const monthName = MonthNames[date.getMonth()]; // Get month name from array
    const year = date.getFullYear();
    return `${day}/${monthName}/${year}`;
  };
  const [releaseName, setReleaseName] = useState("");
  //   startDate
  // endDate
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showDates, setShowDates] = useState(false)
  /* <summary>
date: 23-10-2023
Name: VK
description: API call for sprint data
<summary>*/
  const GetAllActiveSprints = async () => {
    try {
      ToggleLoader(true);
      const response = await SprintService.GetActiveSprints(projectId);

      if (response.status === HTTPResponse.OK) {
        setSprintData(response.data);

        const itemList = response.data.actionItemList;
        const kanbanBoard = new Map();
        const columnOrder = itemList.map((item) => item.value); // Assuming item.value contains the column IDs
        const fontColor = itemList.length > 0 ? itemList[0].fontColor : ""; // Select fontColor from the first item


        for (const item of itemList) {
          kanbanBoard.set(item.value, {
            orderIndex: item.orderIndex,
            columnId: item.value,
            title: item.label,
            color: item.color,
            fontColor: item.fontColor,
            taskList: [],
          });

          for (const x of response.data.sprintData) {
            if (kanbanBoard.has(x.actionStatusTypeId)) {
              const taskList = kanbanBoard.get(x.actionStatusTypeId).taskList;
              const filteredlist = taskList.filter(
                (y) => y.actionItemId === x.actionItemId
              );

              if (filteredlist.length === 0) {
                taskList.push(x);

                kanbanBoard.set(x.actionStatusTypeId, {
                  ...kanbanBoard.get(x.actionStatusTypeId),
                  taskList,
                });
              }
            }
          }
        }

        // Update the columns state with the kanbanBoard data and columnOrder
        UpdateColumns(kanbanBoard, columnOrder);
      }
      ToggleLoader(false);
      if (response.status === HTTPResponse.Unauthorized) {
        LogOut();
      }

    } catch (error) {
      ToggleLoader(false);
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  /* <summary>
date: 23-10-2023
Name: VK
description: API call for Sprint Dropdown
<summary>*/
  var sprintListOption = [];
  async function GetSprintList() {
    let response = await SprintService.GetActiveSprintListByProjectId(
      projectId
    );

    if (response.status === HTTPResponse.OK) {
      const employees = response?.data?.map((t) => ({
        value: t.sprintId,
        label: t.name,
        id: t.sprintId,
      }));
      const newList = [...employees];

      const newSprintList = newList.sort((a, b) => b.id - a.id);
      setSprintList(newSprintList);
      sprintListOption = newList;

      AddLocalStorageData('selectedSprint', JSON.stringify(newSprintList[0]));
    }
    if (response.status === HTTPResponse.Unauthorized) {
      LogOut();
    }
  }

  useEffect(() => {
    if (selectedSprint) {
      GetSprintDates(selectedSprint.value);

      setSelectedSprintsSprintId(selectedSprint.value);
    }
  }, [selectedSprint]);

  async function GetSprintDates(sprintId) {
    try {
      var response = await SprintService.GetSprintDates(sprintId);

      if (response.status === HttpStatusCode.Ok) {
        setRemainingDays(response.data.item1);
        setTotalDays(response.data.item2);

      }
      if (response.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  }
  /* <summary>
  date: 29-03-2024
  Name: MS
  description: to preselt the dropdown value on load.
  <summary>*/
  useEffect(() => {
    const storedSprint = GetLocalStorageData('selectedSprint');
    if (storedSprint !== undefined && storedSprint !== null && storedSprint !== "Uwxt7s/r5zp6v/kfF4wOgw==") {
      setSelectedSprint(JSON.parse(storedSprint));
    }
    if (sprintList.length > 0) {
      // If local storage is empty, preselect the first value from options
      setSelectedSprint(sprintList[0]);
    }
  }, [sprintList, load])
  /* <summary>
date: 23-10-2023
Name: VK
description: QuickFilter & SprintChange Dropdown 
<summary>*/

  const HandleSprintChange = async (selectedOption) => {
    if (selectedOption !== null && selectedOption !== undefined) {
      setSelectedSprint(selectedOption);
      //GetSprintDates(selectedOption.value);
      sprintDataForSelectedId(selectedOption.value);
      setSelectedSprintsSprintId(selectedOption.value);
      //setSelectedEmp(0);
      AddLocalStorageData('selectedSprint', JSON.stringify(selectedOption));
    }
    else {
      setSelectedSprint("");
    }
  };

  //Quick Filters handler
  const HandleFilterClick = (e, filter) => {
    
    if (e.length !=0) {
      setActiveFilter(filter);
      if (filter != "FilterEmp") {
        setSelectedEmp([]);
      }
    } else {
      setSelectedEmp([]);
      setActiveFilter("");
    }

  };

  const onDragEnd = async (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;


    // Update state immutably
    setColumns(prevColumns => {
      const newColumns = { ...prevColumns };

      if (source.droppableId === destination.droppableId) {
        const column = columns[source.droppableId];
        const sourceTaskIds = column?.actionStatusTypeId || [];
        const newTaskIds = [...sourceTaskIds];
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);

        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            actionStatusTypeId: newTaskIds,
          },
        });
      } else {
        const sourceColumn = columns[source.droppableId];
        const destinationColumn = columns[destination.droppableId];

        const sourceTaskIds = sourceColumn?.actionStatusTypeId || [];
        const destinationTaskIds = destinationColumn?.actionStatusTypeId || [];

        sourceTaskIds.splice(source.index, 1);
        destinationTaskIds.splice(destination.index, 0, draggableId);

        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            actionStatusTypeId: sourceTaskIds,
          },
          [destination.droppableId]: {
            ...destinationColumn,
            actionStatusTypeId: destinationTaskIds,
          },
        });
      }

      return newColumns;
    });

    const { task, destinationColumnId } = updateTaskOrder(
      source,
      destination,
      draggableId
    );

    await updateActionItemStatusInDatabase(task.actionItemId, destinationColumnId);
    await GetAllActiveSprints();
  };

  /* <summary>
  date: 27-10-2023
  Name: VK
  description: logic for drag and drop
  <summary>*/

  const updateTaskOrder = (source, destination, draggableId) => {
    const updatedColumns = { ...columns };

    const sourceColumnId = source.droppableId;
    const destinationColumnId = destination.droppableId;

    const taskToMove = updatedColumns[sourceColumnId].taskList.find(
      (task) => task.actionItemId.toString() === draggableId
    );

    updatedColumns[sourceColumnId].taskList = updatedColumns[
      sourceColumnId
    ].taskList.filter((task) => task.actionItemId.toString() !== draggableId);

    updatedColumns[destinationColumnId].taskList.splice(
      destination.index,
      0,
      taskToMove
    );

    setColumns(updatedColumns);
    return { task: taskToMove, destinationColumnId };
  };

  // Function to make an API call to update ActionItemStatusId in the database
  const updateActionItemStatusInDatabase = async (taskId, newStatusId) => {
    let swapId = {
      taskId: parseInt(taskId),
      newStatusId: parseInt(newStatusId),
    };

    try {
      // Make an API call to update ActionItemStatusId with taskId and newStatusId
      // You should implement this API call in SprintService or a dedicated service file

      const response = await SprintService.updateActionItemStatus(swapId);
      if (response.status === HTTPResponse.OK) {
        GetActionItems();
      }
      if (response.status === HTTPResponse.Unauthorized) {
        LogOut();
      } else {
        // Handle the error, possibly by reverting the UI to the previous state
      }
      //SYJ - OnDrag Update the Action Item Status
      await Promise.all([
        GetActionItems(),
        sprintDataForSelectedId(completedSprintsSprintId),
      ]);
      await GetCompletedDataOnDrag();
      sprintDataForSelectedId(completedSprintsSprintId);
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  /* <summary>
  date: 30-10-2023
  Name: VK
  description: To get Completed Time
  <summary>*/

  const styles = {
    control: (base) => ({
      ...base,
      borderBottom: "2px solid #483567",
      innerWidth: 15,
      innerHeight: 15,
      // backgroundColor: "#ebe6f5",
      borderColor: "#483567",
      color: "#483567!Important",
      border: "none",
      zIndex: "9",
      backgroundColor: '#fff',
      borderRadius: "0"
    }),
  };

  //SYJ 24/11/2023 - Complete Sprint Section
  //GetActionItems is used to get all the action items present in the data base
  const GetActionItems = async () => {
    try {
      const actionItemsResponse = await SprintOverviewService.GetActionItems(
        projectId, false
      );

      if (actionItemsResponse.status === HTTPResponse.OK) {
        setCompleteSprintData(actionItemsResponse.data);
         setActiveLastItem(actionItemsResponse?.data[0].lastActionItemStatus)
         const projectName = actionItemsResponse.data[0].projectName
          //AddLocalStorageData("projectName",projectName);
          setProjectName(projectName);
      }
      if (actionItemsResponse.status === HTTPResponse.Unauthorized) {
        LogOut();
      }

    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  const openModal = async () => {
    if (
      selectedSprint === null ||
      (selectedSprint != null && !selectedSprint.value > MaxCharacterCount.Empty)
    ) {
      ShowToastMessage(ActiveSprintMsg.Select, ToastMessageType.Fail);
    } else {
      //SYJ - OnDrag Update the Action Item Status
      await Promise.all([
        GetActionItems(),
        sprintDataForSelectedId(completedSprintsSprintId),
      ]);
      GetCompletedDataOnDrag();
      sprintDataForSelectedId(completedSprintsSprintId);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //SYJ - method called to handle update on OnDrag Update the Action Item Status
  const GetCompletedDataOnDrag = async () => {
    //   // Group objects by sprintId

    const groupedData = CompleteSprintData.reduce((result, item) => {
      const sprintId = item.sprintId;
      if (item.status === true) {
        if (!result[sprintId]) {
          result[sprintId] = [];
        }
        result[sprintId].push(item);
      }
      return result;
    }, {});

    // Map the grouped data as needed
    // if (groupedData.length > 0) {
    const mappedData = Object.keys(groupedData).map((sprintId) => {
      const sprintListData = groupedData[sprintId];

      const mappedItems = sprintListData.map((item) => ({
        sprintId: item.sprintId,

        key: item.key,

        title: item.title,

        sprintName: item.sprintName,

        priorityId: item.priorityId,

        actionItemTypeID: item.actionItemTypeID,

        actionItemStatusId: item.actionItemStatusId,

        designEstimates: item.designEstimates,

        devEstimates: item.devEstimates,

        qaEstimates: item.qaEstimates,

        assigneeFirstName: item.assigneeFirstName,

        assigneeLastName: item.assigneeLastName,

        actionItemId: item.actionItemId,
      }));

      const sprint_Id = sprintListData[0].sprintId;

      const sprintName = sprintListData[0].sprintName;

      const startDate = sprintListData[0].startDate;

      const endDate = sprintListData[0].endDate;

      const releaseName = sprintListData[0].releaseName;

      const itemCount = sprintListData.length;

      const status = sprintListData[0].status;

      const actionItemId = sprintListData[0].actionItemId;

      return {
        sprint_Id: sprint_Id,

        releaseName: releaseName,

        sprintName: sprintName,

        mappedItems: mappedItems,

        startDate: startDate,

        endDate: endDate,

        itemCount: itemCount,

        actionItemId: actionItemId,

        status: status,
      };
    });


    totalmappedData = mappedData;
  };
  //);
  /// ///
  const sprintDataForSelectedId = async (sprint_Id) => {
    const CompleteSprintData = totalmappedData.filter(
      (sprint) => sprint.sprint_Id == sprint_Id
    );

    if (CompleteSprintData.length > MaxCharacterCount.Empty) {
      setSelectedSprintName(CompleteSprintData[0].sprintName);
      setselectedSprintIssueCount(CompleteSprintData[0].mappedItems.length);
      // Filter the items with actionItemStatusId equal to 5
      const completedItems = CompleteSprintData[0].mappedItems.filter(
        (item) => item.actionItemStatusId === activeLastItem
      );


      const completedActionItemIds = completedItems.map(
        (item) => item.actionItemId
      );

      setCompletedActionItemsIds(
        completedActionItemIds.concat(completedActionItemIds)
      );
      /////

      const notcompletedItems = CompleteSprintData[0].mappedItems.filter(
        (item) => item.actionItemStatusId != activeLastItem
      );
      // Get the count of filtered items
      const completedItemsCount = completedItems.length;
      const notcompletedItemsCount = notcompletedItems.length;
      setCompletedItemsCount(completedItemsCount);
      setIncompleteItemsCounts(notcompletedItemsCount);
      // Get an array of actionItemIds from completedItems


      const notCompletedActionItemIds = notcompletedItems.map(
        (item) => item.actionItemId
      );

      setNotCompletedActionItemsIds(
        notCompletedActionItemIds.concat(notCompletedActionItemIds)
      );
      setSelectedSprintId(CompleteSprintData[0].sprint_Id);
    }
  };

  const handleCompleteButtonClick = async () => {

    if (completedItemsCount == 0) {
      return;
    }
    try {
      const notCompletedPromise =
        await SprintOverviewService.NotCompletedActionItemsIds(
          notCompletedActionItemIds
        );

      const selectedSprintID = selectedSprint.value;
      const selectedSprintData = {
        projectId,
        selectedSprintID,
        selectedSprintIssueCount,
        completedItemsCount,
        incompleteItemsCounts,
        createdBy: GetLoggedInUserID(),
        completedActionItemIds
      };

      const sprintClosurePromise =
        await SprintOverviewService.SprintClosureDetailsUpdation(
          selectedSprintData
        );
      // Use Promise.all to wait for both promises to complete
      AddLocalStorageData('selectedSprint', null);

      await GetAllActiveSprints();


      setShowDates(false);
      setRemainingDays("");
      await GetSprintList();
      setLoad(!load);
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }

    closeModal();
  };

  const NavigateOnActionItemClick = (task, actionItemId) => {
    SetActionItemId(actionItemId);
    AddLocalStorageData("actionItemTitle", task.key.toString());
  };
  const sortedColumnIds = Object.keys(columns).sort(
    (a, b) => columns[a].orderIndex - columns[b].orderIndex
  );
  let widthCal = Number((remainingDays / totalDays) * 100);

  //SYJ Function to darken a color
  const darkenColor = (color, percent) => {
    // Parse the color into RGB components
    let r = parseInt(color.slice(1, 3), 16);
    let g = parseInt(color.slice(3, 5), 16);
    let b = parseInt(color.slice(5, 7), 16);

    // Calculate the new darker color directly in the inline style
    r = Math.round((r * (100 - percent)) / 100);
    g = Math.round((g * (100 - percent)) / 100);
    b = Math.round((b * (100 - percent)) / 100);

    // Convert back to hex format
    const newColor =
      '#' +
      (r < 16 ? '0' : '') + r.toString(16) +
      (g < 16 ? '0' : '') + g.toString(16) +
      (b < 16 ? '0' : '') + b.toString(16);

    return newColor;
  };
  /* <summary>
  date: 14-03-2024
  Name: Mustakim Shaikh
  description: to get employees assigned to project and use it to filter tasks assigned
  <summary>*/
  const [team, setTeam] = useState([])
  const getProjectAssignedEmployee = async () => {
    try {
      const resp = await ProjectService.GetAssignedEmployees(projectId, false);

      if (resp.status === HTTPResponse.OK) {
        let employeeIdAndName = resp.data.map((employee) => ({
          employeeId: employee.employeeId,
          fullName: employee.fullName,
        }));
        const filered = employeeIdAndName.filter(emp => emp.employeeId !== GetLoggedInUserID())
        setTeam(employeeIdAndName);
      }
      if (resp.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };
  const stylesIsMulti = {
    control: (base) => ({
      ...base,
      color: "#483567!Important",
      height: '20px',
     
      zIndex: "9",
      backgroundColor: '#fff',
      //width: '200px', // Set fixed width
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flex',
      flexWrap: 'nowrap', // Prevent wrapping of selected options
      overflowX: 'auto', // Enable horizontal scrolling
    }),
    multiValue: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis', // Show ellipsis if the text is too long
      maxWidth: '100%', // Ensure it fits within the container
    }),
    multiValueLabel: (base, { data }) => ({
      ...base,
      whiteSpace: 'nowrap', // Prevent text wrapping within each selected option
      overflow: 'hidden',
      textOverflow: 'ellipsis', // Show ellipsis if the text is too long
      maxWidth: '100%',
      title:data.title
    }),
  };
  // const CheckboxOption = (props) => (
  //   <components.Option {...props}>
  //     <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
  //     {props.label}
  //   </components.Option>
  // );
  const CheckboxOption = (props) => (
    <components.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input 
          type="checkbox" 
          checked={props.isSelected} 
          onChange={() => null} 
          style={{ marginRight: '8px', verticalAlign: 'middle' }} // Checkbox centered
        />
        <div style={{ display: 'inline-block', whiteSpace: 'normal' }}>
          <span style={{ wordBreak: 'break-word' }}>{props.label}</span>
        </div>
      </div>
    </components.Option>
  );
  
  return (
    <>
      <main style={{ minHeight: "67vh" }}>
        <div id="active-sprints" className="active-sprints-page p-0">
          <div className="sprint-filters">
            <div className="sprint-specification-complete">
              <div
                className="sprint-title"
                style={{ backgroundColor: "#ebe6f5!Important" }}
              >
                <label className="">Sprint</label>
                <Select
                  id="sprint-number"
                  name="sprint"
                  inputId="sprintId"
                  styles={styles}
                  placeholder="Select Sprint"
                  value={selectedSprint}
                  options={sprintList}
                  onChange={HandleSprintChange}
                  isClearable={selectedSprint !== undefined || (!selectedSprint === null && selectedSprint?.value > 0)}
                />
              </div>
              <div className="sprint-date-completion">
                <div className="sprint-time-remaining">
                  {/* <span style={{marginBottom:'-4.5px'}}>{formatDate(endDate)}</span>  */}
                  {showDates && (<span className="sprint-period" style={{ marginBottom: '-4.5px', marginRight: "10px" }}>{formatDate(startDate)} - {formatDate(endDate)}</span>
                  )}

                  {/* {(remainingDays == 0 || remainingDays) && (
                    <div
                      className={
                        remainingDays < 0
                          ? "sprint-time-remaining-minus"
                          : "sprint-time-remaining-plus"
                      }
                    >
                      {`${remainingDays} Days Remaining`}

                      <div
                        className="progress-bar"
                        style={{ height: "12px", backgroundColor: "white" }}
                      >
                        <div
                          className={`progress-bar-fill ${remainingDays < 0 ? "negative" : "positive"
                            }`}
                          style={{
                            width: `${widthCal}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  )} */}
                  {(remainingDays == 0 || remainingDays) && (
                    <div
                      className={
                        remainingDays < 0
                          ? "sprint-time-remaining-minus"
                          : "sprint-time-remaining-plus"
                      }
                    >
                      {`${remainingDays} Days Remaining`}

                      <div
                        className="progress-bar"
                        style={{ height: "12px", backgroundColor: "white" }}
                      >
                        <div
                          className={`progress-bar-fill ${remainingDays < 0 ? "negative" : "positive"}`}
                          style={{
                            width: remainingDays < 0 ? '100%' : `${widthCal}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  )}

                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#completeSprintModal"
                    className="sprint-status-btn"
                    onClick={openModal}
                    style={{ border: "2px solid #332150" }}
                  >
                    Complete Sprint
                  </button>
                </div>
              </div>
            </div>
            {showDates && releaseName && (
              <div className="release-name" style={{ color: "#483567" }}>
                {releaseName}
              </div>
            )}
            <div className="dropFlex" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
              <div className="sprint-specification">
                <div className="quickfilter label">
                  <label>Quick filters:</label>
                </div>
                {/* <div className="quickfilter all-issues"> */}
                <div className={`quickfilter all-issues ${activeFilter === "" ? 'active' : ''}`}>

                  <button onClick={(e) => HandleFilterClick(e, "")}>
                    <img
                      src="Assets/images/all-issues-icon.png"
                      style={{ width: "18px", height: '21px', marginRight: "5px" }}
                    />
                    All Issues
                  </button>
                </div>
                <div className={`quickfilter my-issues ${activeFilter === "myIssues" ? 'active' : ''}`}>
                  <button onClick={(e) => HandleFilterClick(e, "myIssues")}>
                    <img
                      src="Assets/images/my-issues-icon (2).png"
                      style={{ width: "18px", height: '21px', marginRight: "5px" }}
                    />
                    Only My Issues
                  </button>
                </div>
                <div className={`quickfilter design-team ${activeFilter === "designTeam" ? 'active' : ''}`}>
                  <button onClick={(e) => HandleFilterClick(e, "designTeam")}>
                    <img
                      src="Assets/images/Icon metro-file-code.png"
                      style={{ width: "18px", height: '21px', marginRight: "5px" }}
                    />
                    Design Team
                  </button>
                </div>
                <div className={`quickfilter dev-team ${activeFilter === "devTeam" ? 'active' : ''}`}>
                  <button onClick={(e) => HandleFilterClick(e, "devTeam")}>
                    <img
                      src="Assets/images/Group 126.png"
                      style={{ width: "17px", height: '21px', marginRight: " 5px" }}
                    />
                    Dev Team
                  </button>
                </div>
                <div className={`quickfilter QA-team ${activeFilter === "qaTeam" ? 'active' : ''}`}>
                  <button onClick={(e) => HandleFilterClick(e, "qaTeam")}>
                    <img
                      src="Assets/images/Group 127.png"
                      style={{ width: "17px", height: '21px', marginRight: "5px" }}
                    />
                    QA Team
                  </button>
                </div>
                <div
                  style={{ alignContent: "flex-end" }}
                >
                  {" "}
                  {/* {!searcheExist && "No result found"} */}
                </div>
                <div> </div></div>

              <div className="" style={{ padding: '0px ', marginRight: "17px", width: '220px', height: "80px", marginLeft: '10px',zIndex:'2' }}>

                <label className="">Assignee</label>
                <Select
                  inputId="team"
                  placeholder="Please Select"
                  options={team.map((emp) => ({
                    value: emp.employeeId,
                    label: emp.fullName
                    ,
                  }))}
                  styles={stylesIsMulti}
                  value={selectedEmp ? selectedEmp : null}
                  components={{ Option: CheckboxOption }} // Use custom Option component
                  hideSelectedOptions={false}
                  onChange={(e) => {

                    setSelectedEmp(e); HandleFilterClick(e, "FilterEmp")
                  }}
isMulti
                  isClearable={selectedEmp !== undefined || (!selectedEmp === null && selectedEmp?.value > 0)}
                />

              </div>


            </div>
          </div>
        </div>
        {isModalOpen && (
          <div className="complete-sprint-modal">
            <div className="complete-sprint-modal">
              <div
                className="modal "
                id="completeSprintModal"
                aria-labelledby="ModalFormLabel"
                aria-hidden="true"
                style={{
                  display: "block",
                  paddingRight: "17px",
                  backgroundColor: "#00000094",
                }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="container">
                        <div className="row modal-headerr">
                          <div className="col-10 col-md-10 col-lg-10">
                            <label className="modal-head-label">
                              Complete Sprint
                            </label>
                          </div>
                          <div className="col-2 col-md-2 col-lg-2">
                            <button
                              type="button"
                              className="btn-close btn-close-white"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={closeModal}
                            />
                          </div>
                        </div>
                        <div className="complete-sprint-main">
                          <div className="container">
                            <div>
                              <p>
                                Are you sure you want to complete {" "}
                                <span className="sprintName"> {selectedSprintName}</span> ?
                              </p>
                            </div>
                            <div>
                              <p>
                                {/* There are a total of {selectedSprintIssueCount}{" "}
                                {selectedSprintIssueCount === 0 ||
                                  selectedSprintIssueCount === 1
                                  ? "item"
                                  : "items"}{" "}
                                in sprint {selectedSprintName} out of which
                                there are <br /> */}
                                <span className="complete-items">
                                  <img
                                    width="10px"
                                    src="/Assets/images/complete-items.png"
                                    alt=""
                                  />{" "}
                                  {completedItemsCount}{" "}
                                  {completedItemsCount === 1
                                    ? "completed item"
                                    : "completed items"}
                                </span>{" "}
                                and{" "}
                                <span className="incomplete-items">
                                  <img
                                    width="10px"
                                    src="/Assets/images/incomplete-items.png"
                                    alt=""
                                  />{" "}
                                  {incompleteItemsCounts}{" "}
                                  {incompleteItemsCounts === 1
                                    ? "incomplete item"
                                    : "incomplete items"}
                                </span>
                                .
                              </p>
                            </div>
                            <div className="note-div">
                              <p style={{ color: '#e30808' }}>NOTE: All incomplete items will be moved to Backlog.</p>
                              {/* <p></p> */}
                            </div>
                            {selectedSprintId != 0 &&
                              completedItemsCount == 0 && (
                                <p style={{ color: "#e30808" }}>
                                  You should complete at least one item in order
                                  to complete the sprint
                                </p>
                              )}
                          </div>

                          <div className="complete-cancel-div">
                            <button
                              className="complete-sprint-btn"
                              onClick={handleCompleteButtonClick}
                            >
                              Complete
                            </button>
                            <button
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              className="cancel-sprint-btn"
                              onClick={closeModal}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <div className="task-columns">

            {sortedColumnIds.map((columnId, columnIndex, array) => {
              const column = columns[columnId];
              const color = column.color;
              const filteredTaskList = column.taskList.filter((task) => {

                // Apply the dropdown filter
                const dropdownFilter =
                  !selectedSprint || task.sprintId === selectedSprint.value;

                // Apply the quick filter
                let quickFilter = true; // By default, no quick filter is applied

                if (activeFilter === "myIssues") {
                  quickFilter = task.assigneeId === GetLoggedInUserID();
                } else if (activeFilter === "designTeam") {
                  quickFilter = task.teamId === ROLE_ID.DESIGN;
                } else if (activeFilter === "devTeam") {
                  quickFilter = task.teamId === ROLE_ID.DEV;
                } else if (activeFilter === "qaTeam") {
                  quickFilter = task.teamId === ROLE_ID.QA;
                } else if (activeFilter === "FilterEmp") {
                  quickFilter =
                  selectedEmp.some(employee => employee.value ===  task.assigneeId);
                    //assigneeId === selectedEmp.value;
                }
                // return item.assigneeId !== null &&  item.assigneeId !== undefined && item.assigneeId !== ""
                // // && selectedEmployees.includes(item.assigneeId) ;
                //  && selectedEmployees.some(employee => employee.value === item.assigneeId);
                const sprintIdFilter = task.sprintId !== 0;
                return dropdownFilter && quickFilter && sprintIdFilter;
              });
              {
              }
              return (
                <div
                  key={columnId}
                  className="sprint-category-column"
                  style={{

                    backgroundColor: column.color || "#CCCCCC",
                    border: `2px solid ${darkenColor(column.color, 20)}`,
                    borderColor: darkenColor(column.color, 40),
                  }}
                >
                  <div
                    className="card card-border-primary column-title-name"
                    style={{
                      color: column.fontColor || "white",
                      backgroundColor: column.color || "#CCCCCC",
                      padding: "10px",
                      borderBottom: `2px solid ${darkenColor(column.color, 20)}`,
                      borderColor: darkenColor(column.color, 40),
                      borderRadius: "0px"
                    }}
                  >
                    {column.title}
                  </div>
                  <div style={{ height: "8px", backgroundColor: "#fff", width: "106%", marginLeft: "-4px" }}></div>
                  <Droppable droppableId={columnId}>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="card-body"
                        style={{
                          borderTop: `2px solid ${darkenColor(column.color, 40)}`,
                          borderColor: darkenColor(column.color, 30),
                        }}
                      >
                        <div >
                          {filteredTaskList.length === MaxCharacterCount.Empty ? (
                            <p style={{ textAlign: "center" }}>No Data Found</p>
                          ) : (
                            filteredTaskList.map((task, index) => (
                              <Draggable
                                key={task.actionItemId}
                                draggableId={task.actionItemId.toString()}
                                index={index}

                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    className="card mb-3 bg-light"
                                  >
                                    <div className="card-body p-1 task-hover" style={{ cursor: 'move' }} >
                                      <div className="taskcard-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <a
                                          // onClick={(e) =>
                                          //   NavigateOnActionItemClick(
                                          //     task,
                                          //     task.actionItemId
                                          //   )
                                          // }
                                          target="_blank"
                                          rel="noreferrer" // Add this attribute to mitigate security risk
                                          href={
                                            "/EditActionItem/" +
                                            task.key
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "blue",
                                            textDecoration:
                                              task.actionStatusTypeId ===
                                              task.lastActionItemStatus
                                                ? "line-through"
                                                : "underline",
                                            fontWeight: "normal"
                                          }}
                                        >
                                          {task.key}
                                          {setReleaseName(task.releaseName)}
                                          {setStartDate(task.startDate)}
                                          {setShowDates(true)}
                                          {setEndDate(task.endDate)}
                                        </a>
                                        <LetteredAvatar
                                          name={`${task.assigneeFirstName} ${task.assigneeLastName}`}
                                          bgcolor={task.color}
                                        />
                                      </div>
                                      <div className="task-description">
                                        <p>{task.title}</p>
                                      </div>
                                      <div className="sprint-task-details" style={{ display: "-webkit-inline-box" }}>
                                        <div className="sprint-details">
                                          <img
                                            src={
                                              ACTION_ITEM_TYPE[
                                              task.actionItemTypeID
                                              ]
                                            }
                                            alt={
                                              ACTION_ITEM_TYPE_TITLE[
                                              task.actionItemTypeID
                                              ]
                                            }
                                            title={
                                              ACTION_ITEM_TYPE_TITLE[
                                              task.actionItemTypeID
                                              ]
                                            }
                                          />
                                        </div>
                                        <div className="sprint-details" style={{ marginLeft: "10px" }}>
                                          <img
                                            src={TASK_PRIO[task.priorityId]}
                                            alt={
                                              PriorityListTitle[task.priorityId]
                                            }
                                            title={
                                              PriorityListTitle[task.priorityId]
                                            }
                                          />
                                        </div>
                                        <div className="sprint-details sprint-time" style={{ marginLeft: "10px", marginTop: "2px" }}>
                                          {`${task.designEstimates +
                                            task.devEstimates +
                                            task.qaEstimates
                                            }h`}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))
                          )}
                        </div>

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              );
            })}
          </div>
        </DragDropContext>
      </main>
    </>
  );
};

export default ActiveSprints;
