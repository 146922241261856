import React, { useContext, useEffect, useState, useRef } from "react";
import {
    ActionItem,
    ActionItemTypes,
    AllowedFileFormats,
    HTTPResponse,
    MonthNames,
    PriorityList,
    ToastMessageType,
    ckEditorRemoveButtons,
    toolbarOptions,
    toolbarOptionsEditor,
    StatusList,
    RootCauseTypeList,
    ROLE_TYPE,
    TEAM_TYPE,
    ESTIMATION_AREA_OPTIONLIST,
} from "../Helpers/Enums";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { EditorState, convertToRaw, convertToHTML, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
//import CKEditor from "react-ckeditor-component";
//import { useRef } from "react";
import RichTextEditor from "../Components/RichTextEditor";

//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
    AllowPositiveNumbersWithTwoDecimal,
    ConvertFromHTML,
    GetLocalStorageData,
    GetLoggedInUserID,
    isStringEmpty,
    AddLocalStorageData,
    changePageTitle,
    RemoveLocalStorageData,
    formatLoggedHours,
    formatCorrectHours,
} from "../Helpers/Utilities";
import { ActionItemService } from "../WebApiServices/ActionItem.service";
import Select from "react-select";
import {
    CommonErrorMessages,
    ToastMessages,
    CreateActionItemErrorMsg,
    AddEditActionItemErrorMsg,
    ReportErrorMessage,
} from "../Helpers/HelperText";
import ErrorField from "../Components/ErrorField";
import AddEditComments from "../Components/AddEditComments";
import MultipleFileUpload from "../Components/MultipleFileUpload";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import TimeBar from "../Components/TimeBar";
import { ProjectService } from "../WebApiServices/Project.service";
import { BacklogService } from "../WebApiServices/Backlog.service";
import DeleteModal from "../Components/DeleteModal";
import LetteredAvatar from "./Overview/LetteredAvatar";
import { SprintService } from "../WebApiServices/Sprint.service";
import ScrollPage from "../Components/ScrollPage";
import { SprintOverviewService } from "../WebApiServices/SprintOverview.service";
import { ReportService } from "../WebApiServices/Report.service";
import AddMiscellenousOther from "../Components/AddMiscellenousOther";
import { GlobalEstimationService } from "../WebApiServices/GlobalEstimation.service";

const EditActionItem = () => {
    const [isTitleEditable, setIsTitleEditable] = useState(false);
    const { ShowToastMessage, ToggleLoader, markDirty, clearDirty, appState } = useContext(AppContext);
    const { search } = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const { LogOut } = useUIContext();
    // const { actionItemId } = useParams();
    var { actionItemIds } = useParams();
    const { projectId: projectIdURL } = useParams();
    const [projectId, setProjectId] = useState(0);
    const [actionItemId, setActionItemId] = useState(0);
    const [isPageDisabled, setIsPageDisabled] = useState(Boolean);
    const [isProcessing, setProcessing] = useState(false); //
    const [clearAttachments, setClearAttachments] = useState(false);
    const [atDesc, setatDesc] = useState("");
    const [res, setResponse] = useState(null);
    const misc = location.state && location.state.misc;
    const workItemSelected = location.state && location.state.workItem;
    const [sprintIdCompleted, setSprintIdCompleted] = useState(0);//MS:to select sprintId if actionItem is in completed sprint 
    const [isMisc, setIsMisc] = useState(false);
    const [isWorkItemFromMyTask, setIsWorkItemFromMyTask] = useState();
    const [isCommentsAdded, setIsCommentsAdded] = useState(false);
    const inputRef = useRef(null);
    const containerRef = useRef(null);
    const DescRef = useRef(null);
    const [descSaved, setDescSaved] = useState(false);
    const [editable, setEditable] = useState(false);
    // 06-08-24=>Mustakim:To show defintion of Root cause on i icon
    const [showDefinition, setShowDefinition] = useState(false);
    const closeShowDefinition = () => {
        setShowDefinition(false);
    }
    // 07-08-24=>Mustakim:To show confirm modal on Sprint change
    const [showModal, setShowModal] = useState(false);
    const [showDirtyPopup, setShowDirtyPopup] = useState(false);
    const [sprintDrpdwn, setSprintDrpdwn] = useState({
        previousSprint: "",
        newSprint: "",
        key: "",
        previousSprintValue: 0
    })
    const closeSprintCModal = () => {
        setShowModal(false);
    }
    // State to track when we need to call HandleEdit
    const [needToHandleEdit, setNeedToHandleEdit] = useState(false);
    const [estimatedItemOptions, setEstimatedItemOptions] = useState([]);
    const [selectedArea, setSelectedArea] = useState();
    const [estTime, setEstTime] = useState();
    const [linkedItemsArray, setLinkedItemsArray] = useState([]);//MS:to store data when creating new task.

    const role = GetLocalStorageData("roleID")
    let profileImg = GetLocalStorageData("imagePath");
    // const history = useHistory();
const [previousCommentData,setPreviousCommentData]=useState();
const[isCommentEdit,setIsCommentEdit]=useState(false);
const [commentEditData,setCommentEditData]=useState();
    const bgcolor = GetLocalStorageData("color");

    useEffect(() => {
        try {
            if (misc) {
                setIsMisc(misc);
            }
            if (workItemSelected) {
                setIsWorkItemFromMyTask(workItemSelected)
            }
        } catch (error) {
            console.log("error:", error);
        }
    }, [])

    const [showOnSaveModal, setShowOnSaveModal] = useState(false);
    const [ifYesOnSaveModal, setFfYesOnSaveModal] = useState(false);

    const backlogOption = {
        value: 0,
        label: "Backlog",
        id: 0,
        startDate: null,
        endDate: null,
    };

    let videoList = [];
    let uploadedFileList = [];
    let DeletedVideoList = [];

    useEffect(() => {

        if (actionItemIds != null && actionItemIds != undefined) {
            getActionItemParams();
        } else {
            setProjectId(Number(projectIdURL));
        }
    }, []);

    const getActionItemParams = async () => {
        const response = await ActionItemService.GetActionItemParams(actionItemIds);

        if (response.status === HTTPResponse.OK) {
            setProjectId(response.data.projectId);
            setActionItemId(response.data.actionItemId);
            setIsPageDisabled(response.data.isDisabled);
            // changePageTitle(actionItemIds != undefined ? response?.data?.actionItemKey : "Add Work Item");
        } else if (response.status === HTTPResponse.Unauthorized) {


            LogOut();
        } else {
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    };
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };
    //const projectId = getQueryParams(search).get("ProjectId");

    //const isPageDisabled = getQueryParams(search).get("disabled");
    // Function to strip HTML tags using regular expressions
    // const stripHTMLTags = (html) => {
    //   return html.replace(/<[^>]*>?/gm, ' ');
    // };
    const stripHTMLTags = (html) => {
        // Replace HTML tags
        let text = html.replace(/<[^>]*>?/gm, ' ');

        // Replace common HTML entities
        text = text.replace(/&nbsp;/g, ' ')
            .replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'")
            .replace(/&apos;/g, "'")
            .replace(/&cent;/g, '�')
            .replace(/&pound;/g, '�')
            .replace(/&yen;/g, '�')
            .replace(/&euro;/g, '�')
            .replace(/&copy;/g, '�')
            .replace(/&reg;/g, '�');

        return text;
    };
    const [error, setError] = useState({
        title: false,
        priorityId: false,
        assigneeId: false,
        description: false,
        designEstimates: false,
        devEstimates: false,
        qaEstimates: false,
        typeId: false,
        rootCause: false,
        statusId: false,
        enchancement: false,
        commentEmpty: false,
        dueDateEmpty: false,
        dueDateInvalid: false,
        showInitialCommentAddError: false,
        isTypeMisc: false,  //when add task type is Miscellaneous
        commetsError: false,
        categoryId: false,
        // rootCauseDescription: false,
    });
    const [selectedType, setSelectedType] = useState(null)
    const [filteredDueDate, setFilteredDueDate] = useState(null);
    const [titleError, setTitleError] = useState(false);
    const [isPageLoad, setIsPageLoad] = useState(true);
    const [isFilteredDueDateSelected, setIsFilteredDueDateSelected] = useState(false);

    const [actionItemDetails, setActionItemDetails] = useState({
        userId: "",
        actionItemId: "",
        color: "",
        title: "",
        description: "",
        assigneeId: "",
        priorityId: "",
        designEstimates: 0,
        devEstimates: 0,
        qaEstimates: 0,

        designRework: 0,
        devRework: 0,
        qaRework: 0,

        designBuffer: 0,
        devBuffer: 0,
        qaBuffer: 0,

        editedBy: GetLocalStorageData("userId"),
        actionItemTypeId: "",
        statusId: "",
        reporterId: "",
        selectedLinkedAI: "",
        workLogDetails: [],
        createdOn: "",
        editedOn: "",
        actionItemStatusId: "",
        rootCauseTypeId: "",
        rootCauseDescription: "",
        newSprintId: 0,
        assignedOn: "",
        subTaskItems: [],
        imagesInDesc: [],
        statusActionList: [],
        statusDropDownValue: [],
        assignedOn: "",
        categoryDrpDwn: "",
        categoryDrpDwnTask: "",
        relationshipDrpDwn:"",
        linkTaskActionItem:[]
    });
    const ACTION_ITEM_INITIAL = {
        // userId: GetLoggedInUserID(),
        // title: "",
        // assigneeId: "",
        // priorityId: "",
        // sprintId: "",
        // designEstimates: "",
        // devEstimates: "",
        // qAEstimates: "",
        // actionItemTypeId: "",
        // actionItemStatusId: "",
        // description: EditorState.createEmpty(),
        // selectedLinkedAI: "",
        userId: "",
        actionItemId: "",
        color: "",
        title: "",
        description: "",
        assigneeId: "",
        priorityId: "",
        designEstimates: "",
        devEstimates: "",
        qaEstimates: "",

        designRework: "",
        devRework: "",
        qaRework: "",

        designBuffer: "",
        devBuffer: "",
        qaBuffer: "",

        editedBy: GetLocalStorageData("userId"),
        actionItemTypeId: "",
        statusId: "",
        reporterId: "",
        selectedLinkedAI: "",
        workLogDetails: [],
        createdOn: "",
        editedOn: "",
        actionItemStatusId: "",
        rootCauseTypeId: "",
        rootCauseDescription: "",
        newSprintId: 0,
        subTaskItems: [],
        imagesInDesc: [],
    };

    const [historyData, setHistoryData] = useState([]);

    const [actionItemStatusData, setActionItemStatusData] = useState([]);
    const [isActionItemStatusDataLaoding, setisActionItemStatusDataLoading] =
        useState(false);


    const { sprintNames, sprint_Ids } = location.state || {};
    const INITIAL_FORM_BLUR = {
        title: false,
        actionItemStatusId: false,
        actionItemTypeId: false,
        priorityId: false,
        assigneeId: false,
        description: false,
    };
    const [assigneeList, setAssigneeList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [relationshipList,setRelationshipList]=useState([]);
    const [projectName, setProjectName] = useState("");
    const [bKey, setBKey] = useState("");
    const [actionItemList, setActionItemList] = useState([]);
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [workLogDetail, setworkLogDetail] = useState({});
    const [workItemType, setWorkItemType] = useState(0);
    const [documentFile, setdocumentFile] = useState([]);
    const [enableTitleEdit, setEnableTitleEdit] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [workLogDetailIdToDelete, setWorkLogDetailIdToDelete] = useState(null);
    const [miscValueSelected, setMiscValueSelected] = useState("");
    const [descriptionEditorState, setDescriptionEditorState] = useState(
        EditorState.createEmpty()
    );
    const [rootCauseEditorState, setRootCauseEditorState] = useState(
        EditorState.createEmpty()
    );
    const [files, setFiles] = useState([]);
    const [maxFileSizeLimitMsg, SetMaxFileSizeLimitMsg] = useState(false);
    const [info, setInfo] = useState(false);
    const [commentsData, setCommentsData] = useState(null);
    const [showAddComments, setAddComments] = useState(false);
    const [isSprintSelected, setIsSprintSelected] = useState(false);

    let selectedDueDate = new Date();

    /* <summary>
  date: 11-03-2024
  Name: Mustakim Shaikh
  description: to allow only specific files on drag and drop
  <summary>*/
    const [descriptionDragged, setDescriptionDragged] = useState(false);
    const isValidFileType = (fileType) => {
        return AllowedFileFormats.includes(fileType.toLowerCase());
    };
    const handleDroppedFiles = (files) => {
        setEditable(false);
        setIsTitleEditable(false);
        // Your implementation of handleDroppedFiles in EditActionItem
        const allFilesStored = [...files];
        //const v = [...actionItemDetails.fileLinkList];
        const allowedFiles = Array.from(files).filter((file) =>
            isValidFileType(file.type)
        );

        if (allowedFiles.length === 0) {
            ShowToastMessage(
                CreateActionItemErrorMsg.AllowedFileFormatTypes,
                ToastMessageType.Fail
            );
            return;
        }
        setFiles(allowedFiles);

        const fileContentArray = [];

        // Handle dropped files here
        for (const file of allowedFiles) {
            if (
                file.type.startsWith("image/") ||
                file.type.startsWith("text/plain") ||
                file.type.startsWith("application/pdf")
            ) {
                // Handle image file
                fileContentArray.push(
                    // <img src="
                    `${file.name}`
                    // " alt="Dropped Image" />
                );
            } else {
                // Handle other file types or URLs
                // fileContentArray.push(`<a href="${URL.createObjectURL(file)}" target="_blank">${file.name}</a>`);
                fileContentArray.push(`${file.name}`);
            }
        }

        const currentContent = actionItemDetails.description;
        // const rawContentState = convertToRaw(
        //   descriptionEditorState.getCurrentContent()
        // );

        // const contentStateWithFiles = Modifier.insertText(
        //   currentContent,
        //   currentContent.getSelectionAfter(),
        //   " " + fileContentArray.join("\n"),
        //   undefined,
        //   undefined
        // );

        // // Update the editor state with the new content
        // const newEditorState = EditorState.push(
        //   descriptionEditorState,
        //   contentStateWithFiles,
        //   "insert-characters"
        // );

        // // Update the editor state
        const updatedData = currentContent + fileContentArray;
    };

    useEffect(() => {
        changePageTitle(actionItemIds !== undefined ? bKey : "Add Work Item ");
        async function fetchData() {
            try {
                setActionItemDetails((prev) => ({
                    ...prev,
                    userId: GetLoggedInUserID(),
                }));
                setisActionItemStatusDataLoading(true);
                await GetActionItemDetails();
                // RemoveLocalStorageData("isDirty");
                clearDirty();
            } catch (error) {
                ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
            }
        }
        //  getActionItemCount();
        if (projectId > 0) {
            fetchData();
            GetSprintList();
        }
        clearDirty();
        // RemoveLocalStorageData("isDirty");

    }, [projectId]);

    const customStyles = {
        menu: (base) => ({
            ...base,
            maxHeight: 120, // Set the maximum height for the dropdown
            overflowX: "auto", // Enable vertical scrolling
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: 120, // Set the maximum height for the menu list
            overflowY: "auto", // Ensure vertical scrolling is enabled
            overflowX: "hidden", // Ensure horizontal scrolling is hidden
        }),
    };



    /* <summary>
        date: 21-02-2024
        Name: Mustakim
        description: Sprint dropdown options.
        <summary>*/

    const [sprintList, setSprintList] = useState([]);
    const [selectedSprint, setSelectedSprint] = useState({});
    const [sprintID, setSprintID] = useState(0);
    const [isSprintFieldFilled, setIsSprintFieldFilled] = useState(false);
    const [fileUploadComponentKey, setFileUploadComponentKey] = useState(Date.now());
    const [originalTitle, setOriginalTitle] = useState("")
    const [createdBy, setCreatedBy] = useState("");
    const [color, setColor] = useState("");
    const [profilePath, setProfilePath] = useState("");
    const [createdOn, setCreatedOn] = useState("");
    useEffect(() => {
        const assigneeDrpDwn = GetLocalStorageData("assigneeDrpDwn");
        const typeDrpDwn = GetLocalStorageData("typeDrpDwn");
        const priorityDrpDwn = GetLocalStorageData("priorityDrpDwn");
        const statusDrpDwn = GetLocalStorageData("statusDrpDwn");
        const selectedLinkedAI = GetLocalStorageData("selectedLinkedAI");


        if (assigneeDrpDwn) {
            setActionItemDetails((prevDetails) => ({
                ...prevDetails,
                assigneeDrpDwn: (assigneeDrpDwn),
            }));
        }
        if (typeDrpDwn) {
            setActionItemDetails((prevDetails) => ({
                ...prevDetails,
                typeDrpDwn: (typeDrpDwn),
            }));
        }
        if (priorityDrpDwn) {
            setActionItemDetails((prevDetails) => ({
                ...prevDetails,
                priorityDrpDwn: (priorityDrpDwn),
            }));
        }
        if (statusDrpDwn) {
            setActionItemDetails((prevDetails) => ({
                ...prevDetails,
                statusDrpDwn: (statusDrpDwn),
            }));
        }
        if (selectedLinkedAI) {
            setActionItemDetails((prevDetails) => ({
                ...prevDetails,
                selectedLinkedAI: (selectedLinkedAI),
            }));
        }

        clearDirty();
        // RemoveLocalStorageData("isDirty");
    }, [])
    //const [sprintID, setSprintID] = useState(null); // State to store sprintID
    const [sprintStartDate, setSprintStartDate] = useState(null); // State to store sprint start date
    const [sprintEndDate, setSprintEndDate] = useState(null);

    const [designHrs, setDesignHrs] = useState(0);
    const [devHrs, setDevHrs] = useState(0);
    const [qaHrs, setQaHrs] = useState(0);
    const calculateTotalHours = (filteredDataForTime) => {
        const minutesArray = filteredDataForTime.map(curr => {
          const [hours, decimalPart] = curr.loggedHours.toString().split('.');
          const minutes = decimalPart ? decimalPart.padEnd(2, '0') : '00';
          const hrsInMin = parseInt(hours) * 60;
          const value = hrsInMin + parseInt(minutes);
          return value;
        });
      
        // Sum all the minutes
        const totalMinutes = minutesArray.reduce((acc, curr) => acc + curr, 0);
      
        // Convert total minutes back to hours and minutes
        const totalHours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;
      
        // Combine hours and minutes as a decimal
        const roundedTotalHrsMin = parseFloat(`${totalHours}.${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`);
        
        return roundedTotalHrsMin;
      };
    async function GetSprintList() {

        // if (actionItemIds !== "") {
        //     let response = await SprintService.GetSprintListByProjectId(projectId);

        //     let actionItemData = await BacklogService.GetActionItems(projectId, true);

        //     if (actionItemData && actionItemData.data.length > 0) {
        //         const data = actionItemData.data;

        //         //Auto Fill Due Date on Sprint selected
        //         const foundActionItem = data.find(
        //             (item) => item.actionItemId === Number(actionItemId)
        //         );

        //         const sprintId = foundActionItem ? foundActionItem.sprintId : 0;
        //         setSprintID(sprintId);
        //         setIsSprintFieldFilled(sprintId !== 0); // Update state based on sprintID

        //         if (foundActionItem) {
        //             setSprintStartDate(new Date(foundActionItem.startDate));
        //             setSprintEndDate(new Date(foundActionItem.endDate));
        //         }

        //         setActionItemDetails((prev) => ({
        //             ...prev,
        //             newSprintId: sprintId,
        //             sprintID: sprintId,
        //         }));

        //         if (response.status === HTTPResponse.OK) {
        //             const sprint = response?.data?.map((t) => {
        //                 const startDate = new Date(t.startDate).toLocaleDateString("en-GB", {
        //                     day: "2-digit",
        //                     month: "short",
        //                     year: "numeric",
        //                 }).replace(/ /g, '/');
        //                 const endDate = new Date(t.endDate).toLocaleDateString("en-GB", {
        //                     day: "2-digit",
        //                     month: "short",
        //                     year: "numeric",
        //                 }).replace(/ /g, '/');

        //                 return {
        //                     value: t.sprintId,
        //                     label: `${t.name} (${startDate} - ${endDate})`,
        //                     id: t.sprintId,
        //                     startDate: t.startDate,
        //                     endDate: t.endDate,
        //                 };
        //             });

        //             // Append Backlog option
        //             sprint.push(backlogOption);

        //             // Sort alphabetically by label
        //             const sortedSprint = sprint.sort((a, b) => a.label.localeCompare(b.label));

        //             setSprintList(sortedSprint);
        //         }
        //     }

        //     if (response.status === HTTPResponse.Unauthorized) {
        //         LogOut();
        //     }
        // }

        // if (actionItemIds !== "") {
        //     let response = await SprintService.GetSprintListByProjectId(projectId);

        //     let actionItemData = await BacklogService.GetActionItems(projectId, true);

        //     if (actionItemData && actionItemData.data.length > 0) {
        //         const data = actionItemData.data;

        //         // Auto Fill Due Date on Sprint selected
        //         const foundActionItem = data.find(
        //             (item) => item.actionItemId === Number(actionItemId)
        //         );

        //         const sprintId = foundActionItem ? foundActionItem.sprintId : 0;
        //         setSprintID(sprintId);
        //         setIsSprintFieldFilled(sprintId !== 0); // Update state based on sprintID

        //         if (foundActionItem) {
        //             setSprintStartDate(new Date(foundActionItem.startDate));
        //             setSprintEndDate(new Date(foundActionItem.endDate));
        //         }

        //         setActionItemDetails((prev) => ({
        //             ...prev,
        //             newSprintId: sprintId,
        //             sprintID: sprintId,
        //         }));

        //         if (response.status === HTTPResponse.OK) {
        //             const today = new Date();
        //             today.setHours(0, 0, 0, 0);  // Set time to midnight to compare only the date

        //             const sprint = response?.data?.filter((t) => {
        //                 const sprintStartDate = new Date(t.startDate);
        //                 const sprintEndDate = new Date(t.endDate);

        //                 // Only include sprints where startDate or endDate is today or in the future
        //                 return (
        //                     sprintStartDate >= today || sprintEndDate >= today
        //                 );
        //             }).map((t) => {
        //                 const startDate = new Date(t.startDate).toLocaleDateString("en-GB", {
        //                     day: "2-digit",
        //                     month: "short",
        //                     year: "numeric",
        //                 }).replace(/ /g, '/');
        //                 const endDate = new Date(t.endDate).toLocaleDateString("en-GB", {
        //                     day: "2-digit",
        //                     month: "short",
        //                     year: "numeric",
        //                 }).replace(/ /g, '/');

        //                 return {
        //                     value: t.sprintId,
        //                     label: `${t.name} (${startDate} - ${endDate})`,
        //                     id: t.sprintId,
        //                     startDate: t.startDate,
        //                     endDate: t.endDate,
        //                 };
        //             });

        //             // Append Backlog option
        //             sprint.push(backlogOption);

        //             // Sort alphabetically by label
        //             const sortedSprint = sprint.sort((a, b) => a.label.localeCompare(b.label));

        //             setSprintList(sortedSprint);
        //         }
        //     }

        //     if (response.status === HTTPResponse.Unauthorized) {
        //         LogOut();
        //     }
        // }

        //SYJ - 133/09/2024 - https://edotpulse.com/EditActionItem/PULSE-643

        // if (actionItemIds !== "") {
        //     let response = await SprintService.GetSprintListByProjectId(projectId, isPageDisabled);

        //     let actionItemData = await BacklogService.GetActionItems(projectId, true);

        //     let responseAItm = await ActionItemService.GetActionDetails(
        //         actionItemId,
        //         projectId
        //     );

        //     if (actionItemData && actionItemData.data.length > 0) {
        //         const data = actionItemData.data;

        //         // Auto Fill Due Date on Sprint selected
        //         const foundActionItem = data.find(
        //             (item) => item.actionItemId === Number(actionItemId)
        //         );

        //         const sprintId = foundActionItem ? foundActionItem.sprintId : 0;
        //         setSprintID(sprintId);
        //         setIsSprintFieldFilled(sprintId !== 0); // Update state based on sprintID

        //         if (foundActionItem) {
        //             setSprintStartDate(new Date(foundActionItem.startDate));
        //             setSprintEndDate(new Date(foundActionItem.endDate));
        //         }

        //         setActionItemDetails((prev) => ({
        //             ...prev,
        //             newSprintId: sprintId,
        //             sprintID: sprintId,
        //         }));

        //         if (response.status === HTTPResponse.OK) {
        //             const today = new Date();
        //             today.setHours(0, 0, 0, 0);  // Set time to midnight to compare only the date

        //             const sprint = response?.data?.filter((t) => {
        //                 const sprintStartDate = new Date(t.startDate);
        //                 const sprintEndDate = new Date(t.endDate);

        //                 // Include sprints where startDate or endDate is today or in the future sprintStartDate >= today || 
        //                 return (
        //                     sprintEndDate >= today
        //                 );
        //             }).map((t) => {
        //                 const startDate = new Date(t.startDate).toLocaleDateString("en-GB", {
        //                     day: "2-digit",
        //                     month: "short",
        //                     year: "numeric",
        //                 }).replace(/ /g, '/');
        //                 const endDate = new Date(t.endDate).toLocaleDateString("en-GB", {
        //                     day: "2-digit",
        //                     month: "short",
        //                     year: "numeric",
        //                 }).replace(/ /g, '/');

        //                 return {
        //                     value: t.sprintId,
        //                     label: `${t.name} (${startDate} - ${endDate})`,
        //                     id: t.sprintId,
        //                     startDate: t.startDate,
        //                     endDate: t.endDate,
        //                 };
        //             });

        //             // Check if storedSprint exists and add it to the sprint list if not already present
        //             if (responseAItm && responseAItm.status === HTTPResponse.OK) {
        //                 const actionItemSprintId = responseAItm.data.sprintId;

        //                 // Find the sprint in response data where sprintId matches the sprintId from responseAItm
        //                 const matchingSprint = response.data.find(
        //                     (sprint) => sprint.sprintId === actionItemSprintId
        //                 );
        //                 if (matchingSprint) {
        //                     // Format start and end date for display
        //                     const startDate = new Date(matchingSprint.startDate).toLocaleDateString("en-GB", {
        //                         day: "2-digit",
        //                         month: "short",
        //                         year: "numeric",
        //                     }).replace(/ /g, '/');
        //                     const endDate = new Date(matchingSprint.endDate).toLocaleDateString("en-GB", {
        //                         day: "2-digit",
        //                         month: "short",
        //                         year: "numeric",
        //                     }).replace(/ /g, '/');

        //                     // Create sprint details to bind
        //                     const sprintDetails = {
        //                         value: matchingSprint.sprintId,
        //                         label: `${matchingSprint.name} (${startDate} - ${endDate})`,
        //                         id: matchingSprint.sprintId,
        //                         startDate: matchingSprint.startDate,
        //                         endDate: matchingSprint.endDate,
        //                     };

        //                     // Set the matched sprint to state or perform further operations
        //                     //setSprintList([sprintDetails]); // Bind only the matched sprint
        //                     sprint.push(sprintDetails);
        //                     // setSelectedSprint(sprintDetails)
        //                 }

        //             }
        //             // Append Backlog option
        //             sprint.push(backlogOption);

        //             // Sort alphabetically by label
        //             const sortedSprint = sprint.sort((a, b) => a.label.localeCompare(b.label));

        //             setSprintList(sortedSprint);
        //         }
        //     }

        //     if (response.status === HTTPResponse.Unauthorized) {
        //         LogOut();
        //     }
        // }
        if (actionItemIds !== "") {
            let response = await SprintService.GetSprintListByProjectId(projectId, isPageDisabled);

            let actionItemData = await BacklogService.GetActionItems(projectId, true);

            let responseAItm = await ActionItemService.GetActionDetails(
                actionItemId,
                projectId
            );

            if (actionItemData && actionItemData.data.length > 0) {

                // const designData =responseAItm?.workLogDetails && responseAItm.workLogDetails.filter(item => item.teamID === 1);
                // const devData =responseAItm?.workLogDetails && responseAItm.workLogDetails.filter(item => item.teamID === 2);
                // const qaData =responseAItm?.workLogDetails && responseAItm.workLogDetails.filter(item => item.teamID === 3);
                
                // // Calculate hours for each team
                // const totalDesignHrs = calculateTotalHours(designData);
                // const totalDevHrs = calculateTotalHours(devData);
                // const totalQaHrs = calculateTotalHours(qaData);
                
                // // Update the state with the calculated values
                // setDesignHrs(totalDesignHrs);
                // setDevHrs(totalDevHrs);
                // setQaHrs(totalQaHrs);


                const data = actionItemData.data;

                // Auto Fill Due Date on Sprint selected
                const foundActionItem = data.find(
                    (item) => item.actionItemId === Number(actionItemId)
                );

                const sprintId = foundActionItem ? foundActionItem.sprintId : 0;
                setSprintID(sprintId);
                setIsSprintFieldFilled(sprintId !== 0); // Update state based on sprintID

                if (foundActionItem) {
                    setSprintStartDate(new Date(foundActionItem.startDate));
                    setSprintEndDate(new Date(foundActionItem.endDate));
                }

                setActionItemDetails((prev) => ({
                    ...prev,
                    newSprintId: sprintId,
                    // sprintID: sprintId,
                }));
                if (response.status === HTTPResponse.OK) {
                    const today = new Date();
                    today.setHours(0, 0, 0, 0); // Set time to midnight to compare only the date

                    const sprint = response?.data?.filter((t) => {
                        const sprintEndDate = new Date(t.endDate);

                        // Include sprints where endDate is today or in the future
                        return (
                            sprintEndDate >= today
                        );
                    }).map((t) => {
                        const startDate = new Date(t.startDate).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                        }).replace(/ /g, '/');
                        const endDate = new Date(t.endDate).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                        }).replace(/ /g, '/');

                        // Determine if the sprint is disabled based on the endDate
                        const isDisabled = new Date(t.endDate) < today;

                        return {
                            value: t.sprintId,
                            label: `${t.name} (${startDate} - ${endDate})`,
                            id: t.sprintId,
                            startDate: t.startDate,
                            endDate: t.endDate,
                            isDisabled: isDisabled, // Add isDisabled field
                        };
                    });

                    // Check if storedSprint exists and add it to the sprint list if not already present
                    if (responseAItm && responseAItm.status === HTTPResponse.OK) {

                       
                        const actionItemSprintId = responseAItm.data.sprintId;

                        // Find the sprint in response data where sprintId matches the sprintId from responseAItm
                        const matchingSprint = response.data.find(
                            (sprint) => sprint.sprintId === actionItemSprintId
                        );
                        if (matchingSprint) {
                            // Format start and end date for display
                            const startDate = new Date(matchingSprint.startDate).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            }).replace(/ /g, '/');
                            const endDate = new Date(matchingSprint.endDate).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            }).replace(/ /g, '/');

                            // Create sprint details to bind
                            const sprintDetails = {
                                value: matchingSprint.sprintId,
                                label: `${matchingSprint.name} (${startDate} - ${endDate})`,
                                id: matchingSprint.sprintId,
                                startDate: matchingSprint.startDate,
                                endDate: matchingSprint.endDate,
                                isDisabled: new Date(matchingSprint.endDate) < today, // Add isDisabled field
                            };

                            // Set the matched sprint to state or perform further operations
                            // setSprintList([sprintDetails]); // Bind only the matched sprint
                            sprint.push(sprintDetails);
                            // setSelectedSprint(sprintDetails)
                        }
                        
                //          const designData =responseAItm?.workLogDetails && responseAItm.workLogDetails.filter(item => item.teamID === 1);
                // const devData =responseAItm?.workLogDetails && responseAItm.workLogDetails.filter(item => item.teamID === 2);
                // const qaData =responseAItm?.workLogDetails && responseAItm.workLogDetails.filter(item => item.teamID === 3);

// Use optional chaining and ensure the teamID exists before filtering
const designData = responseAItm.data.workLogDetails?.filter(item => item.teamID === 1) || [];
const devData = responseAItm?.data.workLogDetails?.filter(item => item.teamID === 2) || [];
const qaData = responseAItm?.data.workLogDetails?.filter(item => item.teamID === 3) || [];

                // Calculate hours for each team
                const totalDesignHrs = calculateTotalHours(designData);
                const totalDevHrs = calculateTotalHours(devData);
                const totalQaHrs = calculateTotalHours(qaData);
                
                // Update the state with the calculated values
                setDesignHrs(totalDesignHrs);
                setDevHrs(totalDevHrs);
                setQaHrs(totalQaHrs);
                    }

                    // Append Backlog option
                    sprint.push(backlogOption);

                    // Sort alphabetically by label
                    //const sortedSprint = sprint.sort((a, b) => a.label.localeCompare(b.label));

                    const sortedSprints = sprint
                        .sort((a, b) => a.label.localeCompare(b.label))  // Sort by label
                        .filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.label === value.label
                            ))
                        );

                    setSprintList(sortedSprints);
                }
            }
        }

    }

    useEffect(() => {
        let storedSprint = GetLocalStorageData('selectedSprint');
        // if (selectedSprint !== undefined && selectedSprint.label === "Backlog") {            
        //     setSprintStartDate(null);
        //     setSprintEndDate(null);

        //     setFilteredDueDate(null);
        //     setSelectedSprint(selectedSprint);
        // }
        // else 
        if (selectedSprint !== undefined && actionItemDetails?.isDisabled === false) {
            const foundInSprintList = sprintList.find(
                (item) => item.id === Number(selectedSprint.id)
            );

            if (actionItemIds === undefined && foundInSprintList !== undefined) {
                setSprintStartDate(new Date(foundInSprintList.startDate));
                setSprintEndDate(new Date(foundInSprintList.endDate));
            }

            if (selectedSprint !== undefined && selectedSprint !== null && selectedSprint.value === 0) {
                setFilteredDueDate(null);
            }
            setSelectedSprint(selectedSprint);
        }
        else if (storedSprint !== undefined && actionItemIds === undefined && storedSprint !== "Uwxt7s/r5zp6v/kfF4wOgw==") {
            storedSprint = JSON.parse(storedSprint);
            const foundInSprintList = sprintList.find(
                (item) => item.id === Number(storedSprint.id)
            );

            if (actionItemIds === undefined && foundInSprintList !== undefined) {
                setSprintStartDate(new Date(foundInSprintList.startDate));
                setSprintEndDate(new Date(foundInSprintList.endDate));
            }

            if (storedSprint !== undefined && storedSprint !== null && storedSprint.value === 0) {
                setFilteredDueDate(null);
            }
            setSelectedSprint(storedSprint);
        }
    }, [selectedSprint]);

    const handleDueDateChange = async (date) => {
        // date.setHours(0, 0, 0, 0);

        // const today = new Date();
        // today.setHours(0, 0, 0, 0);

        // const incomingDate = new Date(date);
        // const utcDate = new Date(
        //     incomingDate.getTime() - incomingDate.getTimezoneOffset() * 60000
        // );
        // const formattedDate = utcDate.toISOString();

        // const incomingDate = new Date(date);
        // const utcDate = new Date(
        //   incomingDate.getTime() - incomingDate.getTimezoneOffset() * 60000
        // );
        // const formattedDate = utcDate.toISOString();

        selectedDueDate = date;
        const isDueDateInvalid = date < sprintStartDate && date > sprintEndDate;
        setFilteredDueDate(date);
        setActionItemDetails((prev) => ({
            ...prev,
            assignedOn: date,
        }));
        setError((prev) => ({
            ...prev,
            dueDateEmpty: false,
        }));

        setActionItemDetails((prev) => ({
            ...prev,
            assignedOn: date,
        }));
        // Only set errors if the sprint field is filled
        if (isSprintFieldFilled) {
            setError((prev) => ({
                ...prev,
                dueDateInvalid: isDueDateInvalid,
            }));
        }
        if (sprintList !== undefined && sprintList.length > 0 && !isSprintFieldFilled) {
            // auto fill sprint if Due Date selected
            const autofillSprintDate = sprintList.find((item) => {
                const startDate = new Date(item.startDate);
                const endDate = new Date(item.endDate);
                return date >= startDate && date <= endDate;
            });
            if (autofillSprintDate !== undefined && autofillSprintDate !== null) {
                setSelectedSprint(autofillSprintDate);
                setActionItemDetails((prevDetails) => ({
                    ...prevDetails,
                    sprintId: autofillSprintDate.value,
                }));
            }
        }

        // Call HandleEdit after updating the state
        if (actionItemIds !== undefined) {
            setIsFilteredDueDateSelected(true)
            setIsPageLoad(false);
        }

        markDirty();
        // AddLocalStorageData("isDirty", true);
    };


    // useEffect(() => {
    //   // 
    //   // if (sprintList !== undefined && sprintList.length > 0) {
    //   //   // auto fill sprint if Due Date selected
    //   //   const autofillSprintDate = sprintList.find((item) => {
    //   //     const startDate = new Date(item.startDate);
    //   //     const endDate = new Date(item.endDate);
    //   //     return filteredDueDate >= startDate && filteredDueDate <= endDate;
    //   //   });
    //   //   if (autofillSprintDate !== undefined && autofillSprintDate !== null) {
    //   //     setSelectedSprint(autofillSprintDate);
    //   //     setActionItemDetails((prevDetails) => ({
    //   //       ...prevDetails,
    //   //       sprintId: autofillSprintDate.value,
    //   //     }));
    //   //   }
    //   // }
    // }, [filteredDueDate]);

    useEffect(() => {
        const fetchData = async () => {
            if (filteredDueDate !== undefined && filteredDueDate !== null && filteredDueDate !== "" && isFilteredDueDateSelected && !isPageLoad) {
                setFilteredDueDate(filteredDueDate);
                await HandleEdit();
            }
        }
        fetchData();
    }, [isFilteredDueDateSelected, filteredDueDate]);


    useEffect(() => {
        if (sprintList.length > 0) {
            const sId = sprintList.find((sprint) => sprint.id === sprintID);
            setSelectedSprint(sId);
        }
        if (sprintNames && sprint_Ids && actionItemDetails?.isDisabled === false) {
            const sId = sprintList.find((sprint) => sprint.id === sprint_Ids);
            setSelectedSprint(sId);
        }

        setActionItemDetails((prev) => ({
            ...prev,
            sprintId: sprint_Ids,
        }));

        const sprintId = GetLocalStorageData("sprintId");
        if (sprintId && sprintList.length > 0) {
            //   setActionItemDetails((prevDetails) => ({
            //     ...prevDetails,
            //     sprintId: (sprintId),
            //   }));
            //   setSelectedSprint(sprintId)
            // }
            const sId = sprintList.find((sprint) => sprint.id === sprintId);
            //setSelectedSprint(sId)

            const updatedActionItemDetails = { ...actionItemDetails };
            if (sId != undefined) {
                const { value, label } = sId;
                updatedActionItemDetails["sprintId"] = value
                setSelectedSprint({ value, label });
            }

            setActionItemDetails(updatedActionItemDetails);
        }
        clearDirty();
        // RemoveLocalStorageData("isDirty");
    }, [sprintList]);

    const getHistoryDetails = async () => {
        ToggleLoader(true)
        try {


            const historyResponse = await ActionItemService.GetActionItemHistory(
                actionItemId
            );

            if (historyResponse.data.length > 0) {

                setCreatedBy(historyResponse.data[0].createdBy);
                setColor(historyResponse.data[0].createdByColor)
                setProfilePath(historyResponse.data[0].createdByImagePath)
                setCreatedOn(historyResponse.data[0].createdOn);
                const excludedFields = [
                    "ActionItemFiles",
                    "InverseLinkedActionItem",
                    "SprintActionItemBridges",
                    "WorkLogDetails",
                    "WorkDone",
                    "WorkLogFiles",
                    "EditedOn",
                    "IsDeleted",
                    "HDate",
                    "RootCauseDescription",

                    "IsDefault",
                    "Key", "OrderIndex", "IsDone", "SprintActionItemBridgeId"
                    //, "Title""AssignedOn"	
                    , "ActionItem", "IsMisc", "EditedBy", "EditedByValue", "CreatedOn", "CreatedBy", "TestCount","IsDisabled"
                    ,"LinkedWorkItemBridgeActionItems","LinkedWorkItemBridgeLinkedActionItems"
                ];

                const modifiedHistoryData =
                    historyResponse.data.length > 0 &&
                    historyResponse.data.map((historyRecord) => {
                        const filteredFields = historyRecord.fields
                            .filter((field) => !excludedFields.includes(field))
                            .map((field) => {
                                const fieldMappings = {
                                    AssigneeId: "Assigned To",
                                    EditedBy: "Edited By",
                                    ActionItemTypeId: "Work Item Type",
                                    DevRework: "Dev Rework",
                                    Qarework: "QA Rework",
                                    DesignRework: "Design Rework",
                                    DesignBuffer: "Design Buffer",
                                    Qabuffer: "QA Buffer",
                                    DevBuffer: "Dev Buffer",
                                    ActionItemStatusId: "Status",
                                    PriorityId: "Priority",
                                    Qaestimates: "QA Estimates",
                                    DesignEstimates: "Design Estimates",
                                    DevEstimates: "Dev Estimates",
                                    SprintId: "Sprint",
                                    RootCauseTypeId: "Root Cause",
                                    ProjectCategoryId: "Category",
                                    SharepointVideoLink: "Sharepoint",
                                    AssignedOn: "Due Date",
                                    LinkedActionItemId: "Linked Work Item",
                                    EstimationDataId: "Estimated Work Item",
                                    EstimationAreaId: "Estimation Area",
                                    NoOfControl: "Number of Controls ",
                                    NoOfPages: "Number of Pages"

                                };
                                return fieldMappings[field] || field;
                            });

                        const filteredOriginalValues = historyRecord.originalValues.filter(
                            (_, index) => {
                                const fieldName = historyRecord.fields[index];
                                return !excludedFields.includes(fieldName);
                            }
                        );

                        const filteredNewValues = historyRecord.newValues.filter(
                            (_, index) => {
                                const fieldName = historyRecord.fields[index];
                                return !excludedFields.includes(fieldName);
                            }
                        );

                        // return {
                        //   ...historyRecord,
                        //   fields: filteredFields,
                        //   originalValues: filteredOriginalValues,
                        //   newValues: filteredNewValues,
                        // };
                        const processedOriginalValues = filteredOriginalValues.map((value, index) => {
                            const fieldName = filteredFields[index];
                            return fieldName === "Description" ? stripHTMLTags(value) : value;
                        });

                        const processedNewValues = filteredNewValues.map((value, index) => {
                            const fieldName = filteredFields[index];
                            return fieldName === "Description" ? stripHTMLTags(value) : value;
                        });

                        return {
                            ...historyRecord,
                            fields: filteredFields,
                            originalValues: processedOriginalValues,
                            newValues: processedNewValues,
                        };
                    });

                const sortedHistoryData = modifiedHistoryData.sort((a, b) => {
                    const dateA = new Date(a.editedDates[0]);
                    const dateB = new Date(b.editedDates[0]);
                    return dateB - dateA;
                });
                setHistoryData(sortedHistoryData);

                ToggleLoader(false)
            }
            if (historyResponse.status !== HTTPResponse.OK) {
                ToggleLoader(false);
                ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
            }
        }
        catch (error) {
            ToggleLoader(false);
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    }

    const GetActionItemDetails = async () => {
        try {
            clearDirty();
            // RemoveLocalStorageData("isDirty");
            ToggleLoader(true);
            let response = await ActionItemService.GetActionDetails(
                actionItemId,
                projectId
            );

            let assignedRes = await ProjectService.GetAssignedEmployees(projectId, false);
            let categoryListData = await ProjectService.GetReleases(projectId);
            await GetMiscellaneousTimeLogOptions();
            if (
                actionItemIds === undefined &&
                response.data.actionItemStatusList != null
            ) {

                const employees = assignedRes?.data?.map((t) => ({
                    value: t.employeeId,
                    roleId: t.roleId,
                    label: t.fullName,
                    imagePath: t.imagePath,
                }));
                setatDesc(response.data.description);
                setAssigneeList(employees);
                const categList = categoryListData?.data?.projectCategories.map((t) => ({
                    value: t.projectCategoryId,
                    label: t.categoryName,
                    //imagePath: t.imagePath,
                }));
                const relationshipList=  categoryListData?.data?.workItemRelationShip.map((t) => ({
                    value: t.workItemRelationShipId,
                    label: t.relationShipName,
                    //imagePath: t.imagePath,
                }));
                const projectName = categoryListData?.data?.projectName;
                //const category=categoryListData?.data.projectCategories;
                setCategoryList(categList);
                setProjectName(projectName);
                setRelationshipList(relationshipList);


                //SYJ 11/06/2024 - Bind Default Dropdown Values if Add Work Item
                const updatedTypeDrpDwn = actionItemIds == undefined && ActionItemTypes.filter((a) => a.value === 2)[0];
                const selectTypeDrpDwn = actionItemIds == undefined && ActionItemTypes.filter((a) => a.value === isWorkItemFromMyTask)[0];

                const updatedAssigneeDropDown = employees.filter((a) => a.roleId === 3);
                const updatedPriorityDrpDwn = actionItemIds == undefined && PriorityList.filter((a) => a.value === 2)[0];



                setActionItemDetails((prev) => ({
                    ...prev,
                    typeDrpDwn: selectTypeDrpDwn ? selectTypeDrpDwn : updatedTypeDrpDwn,
                    assigneeDrpDwn: updatedAssigneeDropDown[0],
                    priorityDrpDwn: updatedPriorityDrpDwn,
                }))

            }
            let actionItemData = await BacklogService.GetActionItems(projectId, true);
            if (actionItemData.status === HTTPResponse.OK && actionItemData.data) {

                const listFiltered = actionItemData.data.filter((item) => {
                    return item.actionItemId !== parseInt(actionItemId) && item.key !== null;
                });

                setActionItemList(listFiltered);

                clearDirty();
                // RemoveLocalStorageData("isDirty");
            }



            // if (actionItemIds != null) {
            //   getHistoryDetails();

            // }

            if (
                actionItemIds != null &&
                response.status === HTTPResponse.OK &&
                response.data.actionItemId > 0
            ) {
                const editorState = ConvertFromHTML(response.data.description);
                const rootCauseEditorState = ConvertFromHTML(
                    response.data.rootCauseDescription
                );
                // setDescriptionEditorState(editorState);
                setRootCauseEditorState(rootCauseEditorState);
                setActionItemDetails(response.data);

                const filteredActionItems = actionItemData.data.filter(
                    (a) => a.actionItemId === response.data?.linkedActionItemId
                );
                //setProjectName(response?.data?.projectName);
                AddLocalStorageData("projectName", response.data?.projectName);
                AddLocalStorageData("key", projectId);
                AddLocalStorageData("newProjectId", projectId);
                setBKey(response?.data?.key.toString());
                AddLocalStorageData("actionItemTitle", response?.data?.key.toString());
                setatDesc(response.data?.description);
                setOriginalTitle(response.data?.title);
                AddLocalStorageData("originalTitle", response.data?.title)
                setSprintDrpdwn((prev) => ({
                    ...prev, key: response.data?.key,
                    //previousSprint:response.data?.sprintName,
                    previousSprintValue: response.data?.sprintId

                }))
                setActionItemDetails((prev) => ({
                    ...prev,
                    imagesInDesc: response.data?.fileLinkList,
                    editedBy: GetLocalStorageData("userId"),
                    description: response.data?.description,
                    rootCauseTypeId: response.data?.rootCauseTypeId,
                    rootCauseDescription: response.data?.rootCauseDescription,
                    subTaskItems: response.data?.subTaskItems,
                    assignedOn: response.data?.assignedOn,
                    categoryDrpDwnTask: response.data?.categoryDrpDwn,
                    typeDrpDwn: {
                        ...prev?.typeDrpDwn,
                        imagePath: ActionItemTypes.filter(
                            (a) => a.value === prev?.typeDrpDwn?.value
                        )[0]?.imagePath,
                    },
                    // typeDrpDwn: updatedTypeDrpDwn,
                    priorityDrpDwn: {
                        ...prev?.priorityDrpDwn,
                        imagePath: PriorityList.filter(
                            (a) => a.value === prev?.priorityDrpDwn?.value
                        )[0]?.imagePath,
                    },
                    // statusDrpDwn: {
                    //   ...prev?.statusDrpDwn,
                    //   imagePath: StatusList.filter(
                    //     (a) => a.label === prev?.statusDrpDwn?.label
                    //   )[0]?.imagePath,
                    // },
                    selectedLinkedAI: {
                        value: `${filteredActionItems.length > 0
                            ? filteredActionItems[0].actionItemId
                            : ""
                            }`,
                        label:
                            filteredActionItems.length > 0
                                ? `${filteredActionItems[0].key}:${filteredActionItems[0].title}`
                                : "",
                    },
                    totalEst:
                        parseFloat(prev?.devEstimates ?? 0) +
                        parseFloat(prev?.designEstimates ?? 0) +
                        parseFloat(prev?.qaEstimates ?? 0),
                }));

                const employees = assignedRes?.data?.map((t) => ({
                    value: t.employeeId,
                    label: t.fullName,
                    imagePath: t.imagePath,
                }));
                setAssigneeList(employees);


                const categList = categoryListData?.data?.projectCategories.map((t) => ({
                    value: t.projectCategoryId,
                    label: t.categoryName,
                    //imagePath: t.imagePath,
                }));
                
                const relationshipList=  categoryListData?.data?.workItemRelationShip.map((t) => ({
                    value: t.workItemRelationShipId,
                    label: t.relationShipName,
                    //imagePath: t.imagePath,
                }));
                //const category=categoryListData?.data.projectCategories;
                const projectName = categoryListData?.data?.projectName;
                //const category=categoryListData?.data.projectCategories;
                setCategoryList(categList);
                setProjectName(projectName);
                setRelationshipList(relationshipList);
                 
                //setCategoryList(categList)
                // if(response.data?.isDisabled){
                //   const completeSprint=[
                //     ...sprintList,{
                //       value:response.data?.sprintId,
                //       label:response.data?.sprintName,
                //     }
                //    ]
                //    setSprintList(completeSprint);
                // }


                ToggleLoader(false);
            }
            if (
                response &&
                response.data &&
                response.data.actionItemStatusList &&
                response.data.actionItemStatusList.length > 0
            ) {
                 
                const updatedStatusList = response.data.actionItemStatusList.map(
                    (item) => {
                        const matchedItem = StatusList.find(
                            (status) => status.label === item.label
                        );
                        return matchedItem
                            ? {
                                ...matchedItem,
                                value: item.value,
                                orderIndex: item.orderIndex,
                            }
                            : item;
                    }
                );


                const matchedItem = StatusList.find(
                    (status) => status.label.toLowerCase().trim() === "To Do".toLowerCase().trim()
                );

                const updatedStatusListforAdd = response.data.actionItemStatusList.find((item) => item.label.toLowerCase().trim() === matchedItem.label.toLowerCase().trim());

                updatedStatusListforAdd.imagePath = matchedItem.imagePath;


                setFilteredDueDate(response?.data?.assignedOn ? new Date(response.data.assignedOn) : null);

                setActionItemDetails((prev) => ({
                    ...prev,
                    statusActionList: updatedStatusList,
                    statusDrpDwn: actionItemIds == undefined && updatedStatusListforAdd !== undefined && updatedStatusListforAdd !== null
                        ? updatedStatusListforAdd : {
                            ...prev?.statusDrpDwn,
                            imagePath: prev?.statusDrpDwn !== undefined && StatusList.filter(
                                (a) => a.label === prev?.statusDrpDwn?.label
                            )[0]?.imagePath,
                        },
                }));

                AddLocalStorageData("statusDrpDwn", response?.data?.statusDrpDwn);
                // if (response.data?.isDisabled && sprintList.length>0) {

                //   const completeSprint = [
                //       ...sprintList,
                //       {
                //           value: response.data?.sprintId,
                //           label: response.data?.sprintName,
                //       }
                //   ];
                //   setSprintList(completeSprint);
                // }
                //await GetSprintList();

                //  if (actionItemIds && (response.data?.isDisabled || response.data?.assignedOn===null && (response.data?.sprintID !=null || response.data?.sprintID !=0))) {
                if (actionItemIds && (response.data?.isDisabled === false && response.data?.assignedOn === null && response.data?.sprintId > 0)) {
                    // || response.data?.assignedOn===null && (response.data?.sprintID !=null || response.data?.sprintID !=0))) {

                    //if (response.data?.isDisabled) {
                    setSprintList((prevSprintList) => [
                        ...prevSprintList,
                        {
                            value: response.data?.sprintId,
                            label: response.data?.sprintName,
                        }
                    ]);
                    setSprintIdCompleted(response.data?.sprintId)
                    // setSelectedSprint({ value:response.data?.sprintId, label:response.data?.sprintName } )
                }
            } else if (response.status === HTTPResponse.Unauthorized) {


                LogOut();
            } else {
                ToggleLoader(false);
            }
            ToggleLoader(false);
            //SYJ - 27/05/2024 - Changes to Bind Default actionItemStatus with Icons


            clearDirty();
            // RemoveLocalStorageData("isDirty");
            //}
        } catch (error) {
            ToggleLoader(false);
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    };


    const updateWorkLogDetailsForComments = async () => {

        let response = await ActionItemService.GetActionDetails(
            actionItemId,
            projectId
        );

        if (response && response.data && response.data.workLogDetails) {
            const updatedActionItemDetails = {
                ...actionItemDetails,
                workLogDetails: response.data.workLogDetails,
                issuesFixed: response.data.issuesFixed,
                //fileLinkList: response.data.fileLinkList,
                //videoLinkList: response.data.videoLinkList,
                devLoggedHours: response.data.devLoggedHours,
                qaLoggedHours: response.data.qaLoggedHours,
                designLoggedHours: response.data.designLoggedHours
            };

            AddLocalStorageData("WorkLogDetails", response.data.workLogDetails);

            setActionItemDetails(updatedActionItemDetails);
        }
        if (response.status === HTTPResponse.Unauthorized) {

            LogOut();
        }
        // setShowCommentModal(false);
    };

    const updateWorkLogDetails = async () => {

        let response = await ActionItemService.GetActionDetails(
            actionItemId,
            projectId
        );

        if (response && response.data && response.data.workLogDetails) {
            //AddLocalStorageData("DescValue",response.data?.description);
            setatDesc(response.data.description);
            setOriginalTitle(response.data?.title);
            AddLocalStorageData("originalTitle", response.data?.title)
            const updatedActionItemDetails = {
                ...actionItemDetails,
                workLogDetails: response.data.workLogDetails,
                issuesFixed: response.data.issuesFixed,
                fileLinkList: response.data.fileLinkList,
                videoLinkList: response.data.videoLinkList,
                devLoggedHours: response.data.devLoggedHours,
                qaLoggedHours: response.data.qaLoggedHours,
                designLoggedHours: response.data.designLoggedHours,
                createdOn: response.data.createdOn,
                editedOn: response.data.editedOn,
                testCount: response.data.testCount,
                linkTaskActionItem:response.data.linkTaskActionItem
            };

            setFilteredDueDate(response?.data?.assignedOn ? new Date(response.data.assignedOn) : null);
            setFiles([]);

            AddLocalStorageData("WorkLogDetails", response.data.workLogDetails);

            setActionItemDetails(updatedActionItemDetails);
            const designData = response?.data.workLogDetails?.filter(item => item.teamID === 1) || [];
            const devData = response?.data.workLogDetails?.filter(item => item.teamID === 2) || [];
            const qaData = response?.data.workLogDetails?.filter(item => item.teamID === 3) || [];

                // Calculate hours for each team
                const totalDesignHrs = calculateTotalHours(designData);
                const totalDevHrs = calculateTotalHours(devData);
                const totalQaHrs = calculateTotalHours(qaData);
                
                // Update the state with the calculated values
                setDesignHrs(totalDesignHrs);
                setDevHrs(totalDevHrs);
                setQaHrs(totalQaHrs);
        }
        if (response.status === HTTPResponse.Unauthorized) {

            LogOut();
        }
        // setShowCommentModal(false);
    };

    const HandleDropdownChange = (e, id) => {
         
        if (actionItemIds === undefined) {
            if (id === "selectedLinkedAI") {
                if (e !== null && e !== undefined) {
                    const newItem = {
                        relationshipValue: actionItemDetails?.relationshipDrpDwn?.value, 
                        linkedAI: e.value,  
                        label:e.label,
                        key: e.label.split(':')[0]
                      };
                      setLinkedItemsArray((prevArray) => [...prevArray, newItem]);
                    //   setActionItemDetails((prev) => ({
                    //     ...prev,
                    //     linkTaskActionItem: [...prev.linkTaskActionItem, newItem],  
                    //   }));
                    setActionItemDetails((prev) => ({
                        ...prev,
                        selectedLinkedAI:e,
                        relationshipDrpDwn:""
                        //typeDrpDwn: e,
                    }));
                  
                    // setError((prev) => ({
                    //     ...prev,
                    //     categoryId: false,
                    // }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        
                        selectedLinkedAI:""
                    }));
                }
            }
            else if (id === "relationshipDrpDwn") {
                if (e !== null && e !== undefined) {

                    

                    setActionItemDetails((prev) => ({
                        ...prev,
                        relationshipDrpDwn:e
                        //typeDrpDwn: e,
                    }));
                  
                    // setError((prev) => ({
                    //     ...prev,
                    //     categoryId: false,
                    // }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        
                        relationshipDrpDwn:""
                    }));
                }
            }
          else  if (id === "areadrpdwn") {
                if (e !== null && e !== undefined) {

                    const prevValue = actionItemDetails.areadrpdwn?.value;

                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));
                    if (prevValue != undefined && prevValue != e.value) {
                        setActionItemDetails((prev) => ({
                            ...prev,
                            ["workItemdrpdwn"]: "",
                        }));
                    }
                    // setError((prev) => ({
                    //     ...prev,
                    //     categoryId: false,
                    // }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                        ["workItemdrpdwn"]: ""
                    }));
                }
            }
            else if (id === "workItemdrpdwn") {
                if (e !== null && e !== undefined) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));

                    // setError((prev) => ({
                    //     ...prev,
                    //     categoryId: false,
                    // }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                    }));
                }
            }



            if (id === "categoryDrpDwnTask") {
                if (e !== null && e !== undefined) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));

                    // setError((prev) => ({
                    //     ...prev,
                    //     categoryId: false,
                    // }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                    }));
                }
            }
            else if (id === "categoryDrpDwn") {
                if (e !== null && e !== undefined) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));

                    setError((prev) => ({
                        ...prev,
                        categoryId: false,
                    }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                    }));
                }
            }
            else if (id === "assigneeDrpDwn") {
                if (e !== null && e !== undefined) {
                    const titleValue =
                        actionItemDetails.title
                            .slice(0, ActionItem.TitleMaxLength)
                            .replace(/\{.*?\}/g, "");

                    if (titleValue.length > ActionItem.TitleMaxLength) {
                        setTitleError(true);
                    } else {
                        setTitleError(false);
                    }

                    setActionItemDetails((prev) => ({
                        ...prev,
                        title: titleValue,
                        assigneeDrpDwn: e,
                    }));
                    setError((prev) => ({
                        ...prev,
                        title: false,
                        assigneeId: false,
                    }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        title: "",
                        assigneeDrpDwn: "",
                    }));
                }
            } else if (id === "typeDrpDwn") {
                if (e !== null && e !== undefined) {
                    const isTypeChanged =
                        e.value !== actionItemDetails?.typeDrpDwn?.value &&
                        e.label !== actionItemDetails?.typeDrpDwn?.label;

                    setActionItemDetails((prev) => ({
                        ...prev,
                        typeDrpDwn: e,
                        rootCauseDescription: isTypeChanged ? "" : prev.rootCauseDescription,
                        rootCauseTypeId: isTypeChanged ? "" : prev.rootCauseTypeId,
                    }));
                    setError((prev) => ({
                        ...prev,
                        typeId: false,
                    }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        typeDrpDwn: "",
                    }));
                }
            } else if (id === "priorityDrpDwn") {
                if (e !== null && e !== undefined) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));
                    setError((prev) => ({
                        ...prev,
                        priorityId: false,
                    }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        priorityDrpDwn: "",
                    }));
                }
            } else if (id === "statusDrpDwn") {
                 
                if (e !== null && e !== undefined) {
                    const incomingDate = new Date();
                    // const utcDate = new Date(incomingDate.getTime() - incomingDate.getTimezoneOffset() * 60000);
                    // const formattedDate = utcDate.toISOString().split('T')[0];

                    // const currentDate = new Date();
                    // const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
                    // // Convert formattedDate back to Date object
                    // const formattedDateObject = new Date(formattedDate);

                    // // Add 1 day to fix DatePicker taking 1 day less when selected
                    // formattedDateObject.setDate(formattedDateObject.getDate());
                    // setFilteredDueDate(formattedDateObject);

                    // setActionItemDetails((prev) => ({
                    //     ...prev,
                    //     [id]: e,
                    //     assignedOn: currentDate
                    // }));
                    setError((prev) => ({
                        ...prev,
                        statusId: false,
                    }));
                    setIsCommentsAdded(false);
                } else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        statusDrpDwn: "",
                    }));
                }
            }
            else {
                if (e !== null && e !== undefined) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                    }));
                }
            }



            markDirty();
            // AddLocalStorageData("isDirty", true);
        } else {
             
            if (id === "relationshipDrpDwn") {
                if (e !== null && e !== undefined) {

                    

                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));
                  
                    // setError((prev) => ({
                    //     ...prev,
                    //     categoryId: false,
                    // }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                       
                    }));
                }
            }
           else if (id === "areadrpdwn") {
                if (e !== null && e !== undefined) {

                    const prevValue = actionItemDetails.areadrpdwn?.value;
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));
                    if (prevValue != undefined && prevValue != e.value) {
                        setActionItemDetails((prev) => ({
                            ...prev,
                            ["workItemdrpdwn"]: "",
                        }));
                    }
                    // setError((prev) => ({
                    //     ...prev,
                    //     categoryId: false,
                    // }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                        ["workItemdrpdwn"]: ""
                    }));
                }
            }
            else if (id === "workItemdrpdwn") {
                if (e !== null && e !== undefined) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));

                    // setError((prev) => ({
                    //     ...prev,
                    //     categoryId: false,
                    // }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                    }));
                }
            }



            else if (id === "categoryDrpDwnTask") {
                if (e !== null && e !== undefined) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));

                    // setError((prev) => ({
                    //     ...prev,
                    //     categoryId: false,
                    // }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                    }));
                }
            }
            else if (id === "categoryDrpDwn") {
                if (e !== null && e !== undefined) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));
                    // setError((prev) => ({
                    //   ...prev,
                    //   categoryId: false,
                    // }));
                    setError((prev) => ({
                        ...prev,
                        //title: false,
                        categoryId: false,
                    }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                    }));
                }
            }
            else if (id === "statusDrpDwn") {
                if (e !== null && e !== undefined) {
                    
                    setError((prev) => ({
                        ...prev,
                        statusId: false,
                    }));
                }
                else {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                    }));
                }
            }
            else {

                if (e !== null && e !== undefined) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: e,
                    }));
                }
                else {

                    setActionItemDetails((prev) => ({
                        ...prev,
                        [id]: "",
                    }));

                }
            }
            // if (e !== null && e !== undefined)
            if (id != "categoryDrpDwnTask" &&id != "relationshipDrpDwn" ) {
                HandleDropdownChangeAsync(e, id);
            }

            markDirty();
            // AddLocalStorageData("isDirty", true);

        }
    };

    const HandleDropdownChangeAsync = async (selectedOption, id) => {
        const newState = {
            [id]: selectedOption ? selectedOption : "",
        };

        if (id === 'typeDrpDwn') {
            if (selectedOption !== null && selectedOption !== undefined) {
                const isTypeChanged =
                    selectedOption.value !== actionItemDetails?.typeDrpDwn?.value &&
                    selectedOption.label !== actionItemDetails?.typeDrpDwn?.label;
                newState.rootCauseDescription = isTypeChanged ? '' : actionItemDetails.rootCauseDescription;
                newState.rootCauseTypeId = isTypeChanged ? '' : actionItemDetails.rootCauseTypeId;
            }
            else {
                setActionItemDetails((prev) => ({
                    ...prev,
                    typeId: 0,
                    typeDrpDwn: "",
                }));
            }
        }

        if (selectedOption !== null && selectedOption !== undefined) {
            setActionItemDetails((prev) => ({
                ...prev,
                title: actionItemDetails.title
            }));
            // Update dropdown selected value
            setActionItemDetails((prev) => ({
                ...prev,
                ...newState,
            }));

            setError((prev) => ({
                ...prev,
                [`${id}Id`]: false,
            }));

        }
        else {
            setActionItemDetails((prev) => ({
                ...prev,
                ...newState,
            }));
        }
        // Use a flag to indicate that we need to call HandleEdit
        if (actionItemIds !== undefined)
            setNeedToHandleEdit(true);
    };

    // useEffect to call HandleEdit after state change
    useEffect(() => {
        const handleEditIfNeeded = async () => {
            if (needToHandleEdit) {
                //ToggleLoader(true); // Show the loader

                try {
                    await HandleEdit(); // Call the save function after dropdown value is updated
                } catch (error) {
                    console.error("Error in HandleEdit:", error);
                } finally {
                    ToggleLoader(false); // Hide the loader
                    setNeedToHandleEdit(false); // Reset the flag
                }
            }
        };

        handleEditIfNeeded();
    }, [needToHandleEdit]);

    /* <summary>
        date: 19-10-2023
        Name: NGG
        description: gets videolink list from multiple file uploads component
        <summary>*/
    const GetvideoLinkList = (videoLink, deleteVideoLinkList) => {
        setActionItemDetails((prev) => ({
            ...prev,
            videoLinkList: videoLink,
            deleteVideoLinkList: deleteVideoLinkList,
        }));
        videoList = videoLink
        DeletedVideoList = deleteVideoLinkList;
    };

    /* <summary>
        date: 19-10-2023
        Name: NGG
        description: show worklog detail modal
        <summary>*/
    const ShowComment = async (data, isEdit) => {
       
        if (actionItemIds) {
            setError((prev) => ({
                ...prev,

                // rootCauseDescription:
                //   rootCauseTypeId && isStringEmpty(rootCauseDescription),
                // rootCause:rootCauseTypeId===0 ||rootCauseTypeId===null ,
                designEstimates:
                    (roleId === 1 && (actionItemDetails.designEstimates === 0 || actionItemDetails.designEstimates === "0")) || actionItemDetails.designEstimates.length === 0
                , devEstimates:
                    (roleId === 2 && (actionItemDetails.devEstimates === 0 || actionItemDetails.devEstimates === "0")) || actionItemDetails.devEstimates.length == 0,
                qaEstimates:
                    (roleId === 3 && (actionItemDetails.qaEstimates === 0 || actionItemDetails.qaEstimates === "0")) || actionItemDetails.qaEstimates.length == 0
            }));
            if ((roleId === TEAM_TYPE.DESIGN && (actionItemDetails.designEstimates === 0 || actionItemDetails.designEstimates === "0" || actionItemDetails.designEstimates.length === 0))
                ||
                (roleId === TEAM_TYPE.DEV && (actionItemDetails.devEstimates === 0 || actionItemDetails.devEstimates === "0" || actionItemDetails.devEstimates.length === 0))
                ||
                (roleId === TEAM_TYPE.QA && (actionItemDetails.qaEstimates === 0 || actionItemDetails.qaEstimates === "0" || actionItemDetails.qaEstimates.length === 0))
            ) {
                ShowToastMessage(AddEditActionItemErrorMsg.ValidateAllFields,ToastMessageType.Fail)
                return;
            }
        }
 //clearDirty();
        // let isDirty = GetLocalStorageData("isDirty");
        // if (appState.isDirty === true && actionItemIds !== undefined && actionItemIds !== null) {
        //     setShowDirtyPopup(true);
        //     AddLocalStorageData("pathvalue", "ShowComment");
        // } 
        // else {
            if (data == "save") {
                //rebind the page
                // await GetActionItemDetails();
                var localStoreWLD = GetLocalStorageData("WorkLogDetails");
                // setError((prev) => ({
                //     ...prev,
                //     showInitialCommentAddError: false,
                //     commetsError: false
                // }))
                // if (actionItemDetails.workLogDetails.length === localStoreWLD.length) {
                setIsCommentsAdded(true)
                // }
                // else {
                //     setIsCommentsAdded(false)
                // }
if(!actionItemIds){
   // HandleAddActionItem();
}else{
     await updateWorkLogDetailsForComments();
}
               
                
            } 
            else 
            {
                let loggedInUserID = GetLoggedInUserID();
                let workLogIndexOneData = actionItemDetails.workLogDetails[0];
                if (workLogIndexOneData !== undefined && loggedInUserID === workLogIndexOneData.loggedBy && actionItemIds !== undefined && actionItemIds !== null && !isEdit) {
                   // setworkLogDetail(workLogIndexOneData);
                   setPreviousCommentData(workLogIndexOneData)
                   // setWorkItemType(actionItemDetails.typeDrpDwn !== undefined && actionItemDetails.typeDrpDwn.value);
                   // setIsCommentsAdded(true)
                }
                // else {
                //     if (data !== undefined && isEdit) {
                //         setworkLogDetail(data);
                //         setWorkItemType(actionItemDetails.typeDrpDwn !== undefined && actionItemDetails.typeDrpDwn.value);
                //         setIsCommentsAdded(true)
                //     }
                //     else {
                //         setworkLogDetail(undefined);
                //         setWorkItemType(actionItemDetails.typeDrpDwn !== undefined && actionItemDetails.typeDrpDwn.value);
                //         setIsCommentsAdded(true)
                //     }
                // }

                    if (data !== undefined && isEdit) {
                         setIsCommentEdit(true);
                        setworkLogDetail(data);
                        setWorkItemType(actionItemDetails.typeDrpDwn !== undefined && actionItemDetails.typeDrpDwn.value);
                        setIsCommentsAdded(true)
                    }
                      else{
                      setworkLogDetail(undefined);
                        setWorkItemType(actionItemDetails.typeDrpDwn !== undefined && actionItemDetails.typeDrpDwn.value);
                        setIsCommentsAdded(true)
                }
                       
            }

            if (data == "close") {
                setIsCommentEdit(false);
                setIsCommentsAdded(false);
                setShowCommentModal(false);
            }
            else if (actionItemIds === undefined && actionItemDetails.workLogDetails[0] !== undefined && actionItemDetails.workLogDetails[0].workDone === 99) {
                setError((prev) => ({
                    ...prev,
                    showInitialCommentAddError: true
                }))
                HandleAddActionItem()
            }

            else 
            {
                setShowCommentModal((prev) => !prev);
            }
            AddLocalStorageData("statusDrpDwn", actionItemDetails.statusDrpDwn);
        // }
    };

    /* <summary>
        date: 19-10-2023
        Name: NGG
        description: gets uploaded file list from multiple file uploads
        <summary>*/
    const getUploadedFiles = (files, selectedFileList) => {
        setActionItemDetails((prev) => ({
            ...prev,
            fileLinkList: selectedFileList,
        }));

        setdocumentFile(files);
        uploadedFileList = files;

        if (files.length > 0 || selectedFileList.length > 0)

            markDirty();
        // AddLocalStorageData("isDirty", true);
    };

    const SetMaxError = (value) => {
        SetMaxFileSizeLimitMsg(value);
    };

    const [reload, setReload] = useState(false);

    const OnCancel = (e) => {
        let isDirty = GetLocalStorageData("isDirty");
        if (appState.isDirty === true) {
            setShowDirtyPopup(true);
            if (actionItemIds !== null && actionItemIds !== undefined) {
                // setPath(`/Overview/${projectId}`);
                AddLocalStorageData("pathvalue", `/Overview/${projectId}`);
            }
            else {
                let prevLoc = GetLocalStorageData("locationHistory");
                AddLocalStorageData("pathvalue", prevLoc);
            }
        } 
        else {
            // if (actionItemIds !== null && actionItemIds !== undefined) {
            //     navigate(`/Overview/${projectId}`);
            // }
            // else {
                let prevLoc = GetLocalStorageData("locationHistory");
                if(prevLoc){
                     navigate(prevLoc);
                }
               else{
                navigate(`/Overview/${projectId}`);
               }
            // }

            clearDirty();
            // RemoveLocalStorageData("isDirty");
        }
        // window.location.href;
    }

    const OnSave = (e) => {
        const { id } = e.currentTarget;
        // let localstatusDrpDwn = GetLocalStorageData("statusDrpDwn");
        // if (actionItemIds !== undefined && actionItemIds !== null && localstatusDrpDwn !== null && localstatusDrpDwn !== undefined && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.value === localstatusDrpDwn.value) {
        //     HandleEdit();
        // }
        if (actionItemIds !== undefined && actionItemIds !== null && !isCommentsAdded) {
            setShowOnSaveModal(false)
            setShowDeleteModal(false);
            HandleEdit();
        }
        else if (actionItemIds !== undefined && actionItemIds !== null && isCommentsAdded) {
            // AddLocalStorageData("statusDrpDwn", actionItemDetails.statusDrpDwn);
            setShowOnSaveModal(true)
            setShowDeleteModal(true);
        }
        else {
            HandleAddActionItem(id);
        }

    };

    const SetLocalStorageData = (key, value) => {
        localStorage.setItem(key, value);
    };

    const HandleEdit = async () => {
        try {
            setShowDeleteModal(false);
            setActionItemDetails((prevDetails) => ({
                ...prevDetails,
                videoLinkList: videoList,
                fileLinkList: uploadedFileList,
                deleteVideoLinkList: DeletedVideoList,
                //  projectCategoryId:actionItemDetails.category.value
            }));

            if (!ValidateForm()) {
                const incomingDate = new Date(filteredDueDate);
                const utcDate = new Date(
                    incomingDate.getTime() - incomingDate.getTimezoneOffset() * 60000
                );
                  
                const formattedDate = utcDate.toISOString();
                ToggleLoader(true);
                let formData = new FormData();
                const data = actionItemDetails;

                if (data.fileLinkList && Array.isArray(data.fileLinkList)) {
                    data.fileLinkList = data.fileLinkList.map(link => {
                        if (link.createdOn) {
                            // Convert createdOn to ISO-8601 format
                            link.createdOn = new Date(link.createdOn).toISOString();
                        }
                        return link;
                    });
                }
                //SYJ Changes Done For autoSaving Sharepoint Links
                data.videoLinkList = videoList.length > 0 ? videoList : data.videoLinkList;
                data.deleteVideoLinkList = DeletedVideoList.length > 0 ? DeletedVideoList : data.deleteVideoLinkList;
                data.assignedOn = selectedSprint !== undefined && selectedSprint !== null && selectedSprint.value === 0 ? null : formattedDate;
                data.sprintId = selectedSprint !== undefined && selectedSprint !== null && selectedSprint.value > 0 ? selectedSprint.value : 0;
                data.projectId = projectId;
                //data.fileList = actionItemDetails.imagesInDesc !== undefined && actionItemDetails.imagesInDesc.length > 0 ? actionItemDetails.imagesInDesc : uploadedFileList.length > 0 ? uploadedFileList : data.fileList;

                formData.append("actionItemModel", JSON.stringify(data));

                let files = documentFile.length > 0 ? documentFile : uploadedFileList;
                // let files = documentFile;

                let ins = files.length;

                let fileNames = {}; // Object to store occurrences of file names

                for (let x = 0; x < ins; x++) {
                    let file = files[x];
                    let fileName = file.name;
                    if (fileNames[fileName]) {
                        // If file name already exists, append a number
                        let fileExtIndex = fileName.lastIndexOf(".");
                        let baseName = fileName.substring(0, fileExtIndex);
                        let extName = fileName.substring(fileExtIndex);
                        let newName = baseName + "_" + fileNames[fileName] + extName;
                        formData.append("fileList", file, newName);
                        fileNames[fileName]++; // Increment occurrence count
                    } else {
                        formData.append("fileList", file);
                        fileNames[fileName] = 1; // Initialize occurrence count
                    }
                }


                let response = await ActionItemService.EditActionItem(formData);
                //  let response
                if (response.status == HTTPResponse.Unauthorized) {


                    LogOut();
                } else if (response.status == HTTPResponse.OK) {
                    setActionItemDetails((prev) => ({
                        ...prev,
                        //selectedLinkedAI:e,
                        relationshipDrpDwn:""
                        //typeDrpDwn: e,
                    }));
                    setReload(!reload);
                    //await GetActionItemDetails();
                    //  getUploadedFiles(files, actionItemDetails.fileLinkList);
                    let toastMsg = ToastMessages.ItemUpdated.replace(
                        "Item [itemNo]",
                        ""
                    ).replace("[itemDesc]", "");
                    // setTimeout(() => {
                    //   // window.location.reload();           
                    // }, 1500);
                    await updateWorkLogDetails();
                    ShowToastMessage(
                        <span>
                            <a href={"/EditActionItem/" + response.data.key} className="toast-link">
                                {response.data.key}
                            </a>{" "}
                            {toastMsg.replace(':', '')} {/* Remove the colon */}
                        </span>,
                        ToastMessageType.Success
                    );

                    // Re-render the MultipleFileUpload component by updating the state
                    setFileUploadComponentKey(Date.now());
                    setIsFilteredDueDateSelected(false);
                    if (!isCommentsAdded)
                        setIsCommentsAdded(false);
                    ToggleLoader(false);

                    clearDirty();
                    // RemoveLocalStorageData("isDirty");
                } else {
                    ToggleLoader(false);
                    throw response.error;
                }
            }
        } catch (error) {
            console.log("error", error);
            ShowToastMessage(ToastMessages.IssueOccured, ToastMessageType.Fail);
        }
    };
    const userId = GetLocalStorageData("userId");

    const HandleAddActionItem = async (type) => {
        try {
            
            if (!ValidateForm()) {
                ToggleLoader(true);
                setProcessing(true); // Set the button to processing state
                let count = GetLocalStorageData("ActionItemCount");

                let formData = new FormData();

                const incomingDate = new Date(filteredDueDate);
                const utcDate = new Date(
                    incomingDate.getTime() - incomingDate.getTimezoneOffset() * 60000
                );
                const formattedDate = utcDate.toISOString();


                const actionItemModel = {
                    projectId: projectId,
                    title: actionItemDetails.title,
                    description: actionItemDetails.description,
                    assigneeId: actionItemDetails.assigneeDrpDwn.value,
                    priorityId: actionItemDetails.priorityDrpDwn.value,
                    sprintId: selectedSprint !== undefined && selectedSprint !== null && selectedSprint.value > 0 ? selectedSprint.value : 0,
                    designEstimates: actionItemDetails.designEstimates,
                    devEstimates: actionItemDetails.devEstimates,
                    qAEstimates: actionItemDetails.qaEstimates,
                    actionItemTypeId: actionItemDetails.typeDrpDwn.value,
                    actionItemStatusId: actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.value || 0, //SYJ here dynamic id will added from service side.
                    reporterId: userId,
                    createdBy: GetLoggedInUserID(),
                    editedBy: GetLoggedInUserID(),
                    videoLinkList: actionItemDetails.videoLinkList !== undefined && actionItemDetails.videoLinkList !== null && actionItemDetails.videoLinkList,
                    //enchancement: actionItemDetails.enchancement,
                    assignedOn: selectedSprint !== undefined && selectedSprint !== null && selectedSprint.value === 0 ? null : formattedDate,
                    selectedLinkedAI: actionItemDetails.selectedLinkedAI,
                    actionItemCount: newCount,
                    rootCauseTypeId: actionItemDetails.rootCauseTypeId,
                    isMisc: miscValue.value > 0 ? true : false,
                    miscId: miscValue.value,
                    projectCategoryId: actionItemDetails?.categoryDrpDwn?.value,
                    estimationAreaId: actionItemDetails?.areadrpdwn?.value,
                    estimationDataId: actionItemDetails?.workItemdrpdwn?.value,
                    noOfControls: actionItemDetails.noOfControls,
                    noOfPages: actionItemDetails.noOfPages,
                    relationshipDrpDwn:actionItemDetails.relationshipDrpDwn,
                    linkItem:linkedItemsArray,
                    //?actionItemDetails.relationshipDrpDwn:{value:0,label:null},

                };



                formData.append("actionItemModel", JSON.stringify(actionItemModel));

                let files = documentFile;
                let ins = files.length;
                for (let x = 0; x < ins; x++) {
                    if (files[x].size !== null && files[x].size !== undefined) {
                        formData.append("fileList", files[x]);
                    }
                }
                let response = await ActionItemService.AddEditActionItem(formData);

                // Extract ActionItemId from the response
                const actionItemId = response.data.actionItemId;

                // Update commentsData with the extracted ActionItemId
                const updatedCommentsData = { ...commentsData, actionItemId: actionItemId };

                await ActionItemService.SaveComments(updatedCommentsData);

                if (response.status === HTTPResponse.Unauthorized) {


                    LogOut();
                }
                if (response.status === HTTPResponse.OK) {

                    // setEditorKey((prevKey) => prevKey + 1); // Increment key to force remounting of the Editor component
                    let toastMsg = ToastMessages.ItemAdded.replace(
                        "Item [itemNo]",
                        ""
                        //count
                    ).replace("[itemDesc]", "");
                    //ShowToastMessage(toastMsg, ToastMessageType.Success);
                    //navigate(`/Overview/${projectId}`);
                    // count++;
                    // AddLocalStorageData("ActionItemCount", count);
                    if (type === "btnCreateAnother") {
                        setClearAttachments(true);
                        count++;
                        AddLocalStorageData("ActionItemCount", count);

                        AddLocalStorageData("assigneeDrpDwn", actionItemDetails.assigneeDrpDwn);
                        AddLocalStorageData("typeDrpDwn", actionItemDetails.typeDrpDwn);
                        //AddLocalStorageData("typeDrpDwn",actionItemDetails.typeDrpDwn);
                        //AddLocalStorageData("sprintId",actionItemDetails.typeDrpDwn);
                        AddLocalStorageData("priorityDrpDwn", actionItemDetails.priorityDrpDwn);

                        AddLocalStorageData("statusDrpDwn", actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn);
                        AddLocalStorageData("selectedLinkedAI", actionItemDetails.selectedLinkedAI);
                        AddLocalStorageData("sprintId", actionItemDetails.sprintId);

                        //AddLocalStorageData("actionItemTitle", "");
                        setBKey("")

                        //navigate(`/AddActionItem/${projectId}`);
                        setTimeout(() => {
                            window.location.reload();
                        });
                        ShowToastMessage(
                            <span>
                                <a href={"/EditActionItem/" + response.data.key} className="toast-link">
                                    {response.data.key}
                                </a>{" "}
                                {toastMsg}
                            </span>,
                            ToastMessageType.Success
                        );

                        //setActionItemDetails(ACTION_ITEM_INITIAL)   ;
                    } else {
                        RemoveLocalStorageData("assigneeDrpDwn");
                        RemoveLocalStorageData("typeDrpDwn");
                        RemoveLocalStorageData("priorityDrpDwn");
                        RemoveLocalStorageData("statusDrpDwn");
                        RemoveLocalStorageData("selectedLinkedAI");
                        RemoveLocalStorageData("sprintId");

                        //AddLocalStorageData("actionItemTitle", response?.data?.key.toString());
                        setBKey(response?.data?.key.toString());
                        ShowToastMessage(
                            <span>
                                <a href={"/EditActionItem/" + response.data.key} className="toast-link">
                                    {response.data.key}
                                </a>{" "}
                                {toastMsg}
                            </span>,
                            ToastMessageType.Success
                        );
                        ToggleLoader(false);
                        navigate(`/EditActionItem/${response.data.key}`);
                    }
                    setProcessing(false); //reset the button state  after processing
                    ToggleLoader(false);
                } else {
                    ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
                    throw response.error;
                }
            }
        } catch (error) {
            console.log("error", error);
            ToggleLoader(false);
        }
    };

    /* <summary>
        date: 07-02-2024
        Name: Mustakim
        description: Format date accordingly.
        <summary>*/
    const formatDate = (dateString) => {
        if (dateString != null) {
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, "0");
            const monthName = MonthNames[date.getMonth()]; // Assuming MonthNames array exists
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");
            return `${day}/${monthName}/${year} ${hours}:${minutes}:${seconds} IST`;
        }

    };

    // function formatDateToIST(dateString) {
    //   const date = new Date(dateString);
    //   const options = {
    //     day: '2-digit',
    //     month: 'short',
    //     year: 'numeric',
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     hour12: false, // Use 24-hour format to remove AM/PM
    //     timeZone: 'Asia/Kolkata'
    //   };

    //   const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    //   const [datePart, timePart] = formattedDate.split(', ');

    //   // Split date part and rearrange to include slashes
    //   const [day, month, year] = datePart.split(' ');
    //   const formattedDatePart = `${day}/${month}/${year}`;

    //   return `${formattedDatePart} ${timePart} IST`;
    // }

    function formatDateToIST(dateString) {
        // Use current system date and time if no dateString is provided
        const date = dateString ? new Date(dateString.replace(' ', 'T') + 'Z') : new Date();

        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // Use 24-hour format to remove AM/PM
            timeZone: 'Asia/Kolkata'
        };

        return new Intl.DateTimeFormat('en-GB', options).format(new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }))) + ' IST';
    }

    function formatDateToIST(dateString) {
        // Use current system date and time if no dateString is provided
        const date = dateString ? new Date(dateString.replace(' ', 'T') + 'Z') : new Date();

        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // Use 24-hour format to remove AM/PM
            timeZone: 'Asia/Kolkata'
        };

        return new Intl.DateTimeFormat('en-GB', options).format(new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })));
    }

    const Dateformat = (dateString) => {
        const [datePart, timePart] = dateString.split(" "); // Split date and time parts

        // Determine if the date is in MM/DD/YYYY or DD/MM/YYYY format by checking the separator
        let day, monthIndex, year;

        if (datePart.includes("/")) {
            // Date format is MM/DD/YYYY or DD/MM/YYYY
            const dateComponents = datePart.split("/");

            if (parseInt(dateComponents[0], 10) > 12) {
                // First component is day (DD/MM/YYYY)
                [day, monthIndex, year] = dateComponents;
            } else {
                // First component is month (MM/DD/YYYY)
                [monthIndex, day, year] = dateComponents;
            }
        } else if (datePart.includes("-")) {
            // Date format is DD-MM-YYYY
            [day, monthIndex, year] = datePart.split("-");
        } else {
            return dateString; // Return the original string if the format is unrecognized
        }

        // Check if day, month, and year are valid
        if (!day || !monthIndex || !year) {
            return dateString; // Return original string for invalid date
        }

        // Convert month index to month name abbreviation
        const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        const month = monthNames[parseInt(monthIndex, 10) - 1]; // Subtract 1 as month index starts from 0

        // Format the date with the desired pattern
        return `${day}/${month}/${year} ${timePart}`;
    };

    /* <summary>
        date: 19-10-2023
        Name: NGG
        description: validates form and sets error object to show error messages
        <summary>*/
    const ValidateForm = () => {
        const {
            title,
            description,
            assigneeDrpDwn,
            priorityDrpDwn,
            typeDrpDwn,
            enchancement,
            statusDrpDwn,
            rootCauseTypeId,
            //rootCauseDescription,
            designEstimates,
            devEstimates,
            qaEstimates,
            categoryDrpDwn
        } = actionItemDetails;

        let result = false;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const isDueDateInvalid = sprintStartDate != null && sprintEndDate != null ? filteredDueDate < sprintStartDate && filteredDueDate > sprintEndDate : false; //added validation to be done using sprint start and End date -> PULSE-431
        setIsSprintFieldFilled(selectedSprint !== undefined && selectedSprint.value > 0);


        let localWLOGData = GetLocalStorageData("WorkLogDetails");
        let localstatusDrpDwn = GetLocalStorageData("statusDrpDwn");

        setError((prev) => ({
            ...prev,
            title: isStringEmpty(title),
            // rootCauseDescription:
            //   rootCauseTypeId && isStringEmpty(rootCauseDescription),
            // rootCause:rootCauseTypeId===0 ||rootCauseTypeId===null ,
            rootCause: (roleId === 2 && typeDrpDwn !== undefined && typeDrpDwn.label === "Bug" && (rootCauseTypeId === null || rootCauseTypeId === 0) && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label !== "To Do" && actionItemDetails.statusDrpDwn.label !== "In Progress"),
            designEstimates:
                (roleId === 1 && (designEstimates === 0 || designEstimates === "0") && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label !== "To Do") || designEstimates.length === 0
            , devEstimates:
                (roleId === 2 && (devEstimates === 0 || devEstimates === "0") && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label != "To Do") || devEstimates.length == 0,
            qaEstimates:
                (roleId === 3 && (qaEstimates === 0 || qaEstimates === "0") && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label != "To Do") || qaEstimates.length == 0,
            statusId: (statusDrpDwn === undefined || statusDrpDwn === null || statusDrpDwn === "" || (statusDrpDwn !== undefined && Number(statusDrpDwn.value) == 0)),
            typeId: (typeDrpDwn === null || typeDrpDwn === "" || typeDrpDwn === undefined || (typeDrpDwn !== undefined && Number(typeDrpDwn.value) == 0)),
            priorityId: (priorityDrpDwn === "" || priorityDrpDwn === undefined || priorityDrpDwn == "" || (priorityDrpDwn !== undefined && Number(priorityDrpDwn.value) == 0)),
            categoryId: (categoryDrpDwn === "" || categoryDrpDwn === undefined || categoryDrpDwn === null || (categoryDrpDwn !== undefined && Number(categoryDrpDwn.value) == 0)),
            assigneeId: (assigneeDrpDwn === null || assigneeDrpDwn === "" || assigneeDrpDwn === undefined || (assigneeDrpDwn !== undefined && Number(assigneeDrpDwn.value) == 0)),
            description: description == "" || description == "<p></p>\n",
           // commentEmpty: ((commentsData === null || commentsData.length === 0) && actionItemDetails.workLogDetails.length == 0),
            dueDateEmpty: (selectedSprint !== undefined && selectedSprint !== "" && selectedSprint.value > 0 && (filteredDueDate === "" || filteredDueDate === null) && actionItemDetails?.statusDrpDwn.label !== "Done"),
            dueDateInvalid: isDueDateInvalid,
            isTypeMisc: isMisc && (miscValue === "" || actionItemDetails.miscId === 0),
            //commetsError: localstatusDrpDwn !== null && localstatusDrpDwn !== undefined && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.value !== localstatusDrpDwn.value && commentsData === null
            // localWLOGData !== undefined && actionItemDetails.workLogDetails !== undefined && localstatusDrpDwn !== undefined &&  localWLOGData.length === actionItemDetails.workLogDetails.length && 
            // setMiscValue(selectedOption);
            // setActionItemDetails({
            //   ...actionItemDetails,
            //   miscId: selectedOption ? selectedOption.value : null
        }));

        if (actionItemDetails.title > ActionItem.TitleMaxLength) {
            setTitleError(true);
        }

        if (
            isStringEmpty(title) ||
            ///  (rootCauseTypeId===0 && (actionItemDetails.statusDrpDwn.label !="To Do" ||actionItemDetails.statusDrpDwn.label !="In Progress"))||(rootCauseTypeId===null&& (actionItemDetails.statusDrpDwn.label !="To Do" ||actionItemDetails.statusDrpDwn.label !="In Progress"))||
            (!actionItemDetails.isMisc && (roleId === 2 && typeDrpDwn.label === "Bug" && (rootCauseTypeId === 0 || rootCauseTypeId === "") && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label !== "To Do" && actionItemDetails.statusDrpDwn.label !== "In Progress")) ||
            (!actionItemDetails.isMisc && (roleId === 2 && typeDrpDwn.label === "Bug" && rootCauseTypeId === null && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label !== "To Do" && actionItemDetails.statusDrpDwn.label !== "In Progress")) ||

            designEstimates.length == 0 ||
            //  (roleId === 2 && devEstimates === 0 ) ||
            devEstimates.length == 0 ||
            qaEstimates.length == 0 ||
            (roleId === 1 && (designEstimates === 0 || designEstimates === "0") && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label !== "To Do") || designEstimates.length === 0
            ||
            (roleId === 2 && (devEstimates === 0 || devEstimates === "0") && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label != "To Do") || devEstimates.length === 0 ||
            (roleId === 3 && (qaEstimates === 0 || qaEstimates === "0") && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label != "To Do") || qaEstimates.length === 0 ||
            // (rootCauseTypeId && isStringEmpty(rootCauseDescription)) ||
            Number(statusDrpDwn.value) == 0 || statusDrpDwn === "" ||
            (!actionItemDetails.isMisc && Number(typeDrpDwn.value) == 0) || typeDrpDwn === "" ||
            Number(priorityDrpDwn.value) == 0 || priorityDrpDwn === "" ||
            Number(categoryDrpDwn.value) == -0 || categoryDrpDwn === "" ||
            Number(assigneeDrpDwn.value) == 0 || assigneeDrpDwn === "" ||
            description == "" ||
            //description == "<p></p>\n" ||
            maxFileSizeLimitMsg ||
            titleError ||
           // ((commentsData === null || commentsData.length === 0) && actionItemDetails.workLogDetails.length === 0) || selectedSprint === "" ||
            ((selectedSprint !== undefined && selectedSprint.value > 0) && (filteredDueDate === "" || filteredDueDate === null)) || isDueDateInvalid ||
            (isMisc && (miscValue === "" || actionItemDetails.miscId === 0))
            //|| (localstatusDrpDwn !== null && localstatusDrpDwn !== undefined && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.value !== localstatusDrpDwn.value)
            //(localWLOGData !== undefined && actionItemDetails.workLogDetails !== undefined && localWLOGData.length === actionItemDetails.workLogDetails.length && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.value > 0)

            // ||
            //    (roleId === 1 && dataName === "designEstimates" && value === "0" && actionItemDetails.statusDrpDwn.label != "To Do") ||
            //   (roleId === 2 && dataName === "devEstimates" && value === "0" && actionItemDetails.statusDrpDwn.label != "To Do") ||
            //   (roleId === 3 && dataName === "qaEstimates" && value === "0" && actionItemDetails.statusDrpDwn.label != "To Do")
        ) {
            ShowToastMessage(AddEditActionItemErrorMsg.ValidateAllFields, ToastMessageType.Fail);
            result = true;
        }
        return result;
    };

    const HandleFieldChange = (e) => {
        //setTitle(e.target.value);

        const { id, value, checked } = e.target;

        // let name =
        //   assigneeList.length > 0 && actionItemDetails.assigneeDrpDwn !=undefined &&
        //   actionItemDetails.assigneeDrpDwn.value > 0 &&
        //   assigneeList.find((item) => {
        //     return item.value === actionItemDetails.assigneeDrpDwn.value;
        //   });
        // switch (id) {
        //   case "title":
        //     if (value.length > ActionItem.TitleMaxLength) {
        //       setTitleError(true);
        //       const testValue =
        //         "{" +
        //         (name.label != undefined ? getInitials(name.label) : "") +
        //         "}" +
        //         value.slice(0, ActionItem.TitleMaxLength).replace(/\{.*?\}/g, "");
        //       setActionItemDetails((prev) => ({
        //         ...prev,
        //         title: testValue,
        //       }));
        //     } else {
        //       const val = actionItemDetails.title.slice(0, 500);
        //       setTitleError(false);
        //       setActionItemDetails((prev) => ({
        //         ...prev,
        //         title:
        //           "{" + name.label != undefined &&
        //           getInitials(name.label) + "}" + value.replace(/\{.*?\}/g, ""),
        //       }));
        //     }
        let name =
            assigneeList.length > 0 &&
            actionItemDetails.assigneeDrpDwn != undefined &&
            actionItemDetails.assigneeDrpDwn.value > 0 &&
            assigneeList.find((item) => {
                return item.value === actionItemDetails.assigneeDrpDwn.value;
            });

        let nameUndefined =
            name === undefined &&
            actionItemDetails.assigneeDrpDwn.value > 0 &&
            actionItemDetails.assigneeDrpDwn.label;
        switch (id) {
            case "title":
                if (value.length > ActionItem.TitleMaxLength) {
                    setTitleError(true);
                    const testValue =
                        // "{" +
                        // (name.label != undefined ? getInitials(name.label) : "") +
                        // "}" +
                        value.slice(0, ActionItem.TitleMaxLength).replace(/\{.*?\}/g, "");
                    setActionItemDetails((prev) => ({
                        ...prev,
                        title: testValue,
                    }));
                } else {
                    const val = actionItemDetails.title.slice(0, 500);

                    setTitleError(false);
                    setActionItemDetails((prev) => ({
                        ...prev,
                        title:
                            // "{" + name.label != undefined &&
                            // getInitials(name.label) + "}" +
                            value.replace(/\{.*?\}/g, ""),
                    }));
                }
            case "description": {
                setActionItemDetails((prev) => ({
                    ...prev,
                    [id]: value,
                }));

                //ngg: sets error object to false for that field
                setError((prev) => ({
                    ...prev,
                    [id]: false,
                }));
                break;
            }
            //case:"controls"
            case "control": {
                const isInteger = /^(?:[1-9]|[1-9][0-9])$/;

                if (value === "" || isInteger.test(value)) {


                    setActionItemDetails((prev) => ({
                        ...prev,
                        ["noOfControls"]: value,
                    }));

                } break;
            }

            case "noOfPages": {
                const isInteger = /^(?:[1-9]|[1-9][0-9])$/;

                if (value === "" || isInteger.test(value)) {


                    setActionItemDetails((prev) => ({
                        ...prev,
                        ["noOfPages"]: value,
                    }));

                } break;
            }
            case "enchancement": {
                setActionItemDetails((prev) => ({
                    ...prev,
                    [id]: checked,
                }));
                setError((prev) => ({
                    ...prev,
                    [id]: false,
                }));
                break;
            }

            case "designEstimates":
            case "devEstimates":
            case "qaEstimates": {
                setActionItemDetails((prev) => ({
                    ...prev,
                    [id]: AllowPositiveNumbersWithTwoDecimal(value),
                }));
                setError((prev) => ({
                    ...prev,
                    [id]: false,
                }));
                break;
            }
            default:
                break;
        }

        markDirty();
        // AddLocalStorageData("isDirty", true);
    };

    const HandleDescriptionFieldChange = (e) => {
        const text = e.target.value;
        setActionItemDetails((prev) => ({
            ...prev,
            rootCauseDescription: text,
        }));
        setError((prev) => ({
            ...prev,
            rootCauseDescription: false,
        }));
    };
    const [newCount, setNewCount] = useState(0);
    const getActionItemCount = async () => {
        try {
            ToggleLoader(true);

            const [
                actionItemsResponse,
                // notStartedSprintsResponse,
                completedSprints,
            ] = await Promise.all([
                SprintOverviewService.GetActionItems(projectId),
                //SprintOverviewService.GetNotStartedSprints(projectId),
                SprintOverviewService.GetCompletedSprints(projectId),
            ]);

            if (
                actionItemsResponse.status === HTTPResponse.OK &&
                //notStartedSprintsResponse.status === HTTPResponse.OK &&
                completedSprints.status === HTTPResponse.OK
            ) {
                ToggleLoader(false);
                // let count =actionItemsResponse.data.length + completedSprints.data[0].actionItems.length;
                // // +completedSprints.actionItems.length();
                // setActionItemCount(count);
                let count = actionItemsResponse.data.length;

                if (completedSprints.data.length > 0) {
                    completedSprints.data.forEach((sprint) => {
                        if (sprint.actionItems && sprint.actionItems.length > 0) {
                            count += sprint.actionItems.length;
                        }
                    });
                }
                count = count + 1;
                setNewCount(count);
                AddLocalStorageData("ActionItemCount", count);
            }
        } catch (error) {
            ToggleLoader(false);
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    };

    //const HandleRootDropdownChange = (selectedOption, field) => {
    //  const updatedActionItemDetails = { ...actionItemDetails };
    //  const { value } = selectedOption;
    //  updatedActionItemDetails[field] = value;
    //  setError((prev) => ({
    //    ...prev,
    //    rootCause: false,

    //  }));
    //  setActionItemDetails(updatedActionItemDetails);
    //};

    //   const HandleRootDropdownChange = async (selectedOption, field) => {
    //     
    //     const updatedActionItemDetails = {
    //       ...actionItemDetails,
    //       [field]: selectedOption.value,
    //     };
    //     setActionItemDetails(updatedActionItemDetails);
    // setError((prev)=>({
    //   ...prev,rootCause:false
    // }))
    //     // Auto-save on change
    //     ToggleLoader(true); // Show the loader
    //     await HandleEdit(); // Call the save function
    //     ToggleLoader(false); // Hide the loader
    //   };

    const HandleRootDropdownChange = (selectedOption) => {
        const newState = {
            rootCauseTypeId: selectedOption !== null && selectedOption !== undefined ? selectedOption.value : 0,
        };

        setActionItemDetails((prev) => ({
            ...prev,
            ...newState,
        }));

        setError((prev) => ({
            ...prev,
            rootCauseTypeId: false,
        }));

        if (actionItemIds !== undefined)
            setNeedToHandleEdit(true); // Indicate that HandleEdit needs to be called
        markDirty();
        // AddLocalStorageData("isDirty", true);
    };


    //   const HandleSprintDropdownChange = (selectedOption, field) => {
    //     const updatedActionItemDetails = { ...actionItemDetails };
    //     const { value, label } = selectedOption;
    //     updatedActionItemDetails[field] = value;
    //     setSelectedSprint({ value, label });
    //     setActionItemDetails(updatedActionItemDetails);
    //   };

    const HandleSprintDropdownChange = (selectedOption, field) => {
        const updatedActionItemDetails = { ...actionItemDetails };
        if (selectedOption != null) {
            const { value, label, startDate, endDate } = selectedOption;

            updatedActionItemDetails[field] = value;


            setSelectedSprint({ value, label });


            setActionItemDetails(updatedActionItemDetails);

            setIsSprintFieldFilled(value !== 0);

            if (value) {
                setSprintStartDate(new Date(startDate));
                setSprintEndDate(new Date(endDate));
            }
            setFilteredDueDate(null);

            setError((prev) => ({
                ...prev,
                [field]: false,
            }));


            if (actionItemIds !== undefined)
                setNeedToHandleEdit(true);

            setActionItemDetails((prevDetails) => ({
                ...prevDetails,
                sprintId: value,
            }));
        }
        else {
            setError((prev) => ({
                ...prev,
                dueDateEmpty: false,
            }));
            setSelectedSprint(backlogOption);
            setSprintStartDate(null);
            setSprintEndDate(null);
            setFilteredDueDate(null);
            // updatedActionItemDetails[field] = 0;
            // setActionItemDetails(updatedActionItemDetails);
            RemoveLocalStorageData("sprintId");
            RemoveLocalStorageData('selectedSprint');
            if (actionItemIds !== undefined)
                setNeedToHandleEdit(true);
        }
        markDirty();
        // AddLocalStorageData("isDirty", true);

    };
    const [selectedEvent, setSelectedEvent] = useState();
    const HandleSprintDropdownChangeEdit = (selectedOption) => {//on Edit actionitem page
        //  HandleSprintDropdownChange(e, "sprintId"

        if (selectedOption) {
            setSprintDrpdwn((prev) => ({
                ...prev, newSprint: selectedOption.label
            }))
            setSelectedEvent(selectedOption);
            setShowModal(true);
        }
        else {
            HandleSprintDropdownChange(selectedOption, "sprintId")
        }

        markDirty();
        // AddLocalStorageData("isDirty", true);

    };


    const HandleRootCauseEditorChange = (editorState) => {
        setRootCauseEditorState(editorState);
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlText = draftToHtml(rawContentState);

        const hasText = htmlText.trim() != "<p></p>" && htmlText.trim().length > 0;

        setActionItemDetails((prevDetails) => ({
            ...prevDetails,
            rootCauseDescription: htmlText,
        }));

        setError((prev) => ({
            ...prev,
            rootCauseDescription: hasText ? false : true,
        }));
    };

    const [causeTypes, setCauseTypes] = useState("");

    useEffect(() => {
        getGeneralData();

        getRootCauseTypes();
    }, []);

    const getRootCauseTypes = async () => {
        const resp = await ActionItemService.GetRootCauseTypes();
        var data;
        if (resp.status === HTTPResponse.OK) {
            // data = resp.data?.map((d) => ({
            //   value: d.rootCauseTypeId,
            //   label: d.type,
            // }));
            // const data = resp.data
            //   ?.filter(d => d.rootCauseTypeId !== 13)  // Filter out the Build option.
            //   .map(d => ({
            //     value: d.rootCauseTypeId,
            //     label: d.type,
            //   }));

            const data = resp.data
                ?.filter(d => d.rootCauseTypeId !== 13)  // Filter out the Build option.
                .map(d => {
                    const matchedType = RootCauseTypeList.find(item => item.value === d.rootCauseTypeId);
                    return {
                        value: d.rootCauseTypeId,
                        label: d.type,
                        imagePath: matchedType ? matchedType.imagePath : null
                    };
                });

            const sortedData = data.sort((a, b) => a.label.localeCompare(b.label));
            setCauseTypes(sortedData);
        } else if (resp.status === HTTPResponse.Unauthorized) {


            LogOut();
        }
    };

    const selectedCauseType = Array.isArray(causeTypes)
        ? causeTypes.find(
            (option) => option.value === actionItemDetails?.rootCauseTypeId
        )
        : null;

    const handleDelete = (workLogDetailId) => {
        setError((prev) => ({
            ...prev,
            showInitialCommentAddError: false
        }))
        setWorkLogDetailIdToDelete(workLogDetailId);
        setShowOnSaveModal(false);
        setShowDeleteModal(true);
    };

    const showOnSaveClicked = () => {
        //setFfYesOnSaveModal(true);
        ShowComment();
        setShowDeleteModal(false);

    }

    useEffect(() => {
        if (ifYesOnSaveModal) {
            // e.preventDefault();
            setShowOnSaveModal(false);
            setShowDeleteModal(false);
            setFfYesOnSaveModal(false);
            setIsCommentsAdded(false);
            if(!isCommentsAdded){
                return
            }
            HandleEdit();
        }
        // else if (ifYesOnSaveModal) {
        //   ValidateForm()
        // }
    }, [ifYesOnSaveModal])

    const deleteComment = async () => {
        if (workLogDetailIdToDelete !== null && workLogDetailIdToDelete > 0 && workLogDetailIdToDelete !== undefined) {
            try {
                await ActionItemService.DeleteComment(workLogDetailIdToDelete);
                //GetActionItemDetails();
                await updateWorkLogDetailsForComments();
            } catch (error) {
                ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
            } finally {
                setShowDeleteModal(false);
                setWorkLogDetailIdToDelete(null);
            }
        }
        else {
            const updatedActionItemDetails = {
                ...actionItemDetails,
                workLogDetails: [],
            };
            setworkLogDetail([]);
            setCommentsData([]);
            setActionItemDetails(updatedActionItemDetails);
            // setAddComments(false);
            setShowDeleteModal(false);
            // setError((prev) => ({
            //     ...prev,
            //     commentEmpty: true
            // }))
        }
    };

    const [showIcon, setShowIcon] = useState(false);

    useEffect(() => {
        //setShowIcon(atDesc != actionItemDetails.description ? true : false);
        //setShowIcon(

        if (actionItemDetails?.areadrpdwn && actionItemDetails.areadrpdwn?.value > 0) {
            GetEstimationOptions();
        }
        const tagVal = atDesc && stripHTMLTags(atDesc);
        const tagVal2 = actionItemDetails.description && stripHTMLTags(actionItemDetails.description);

        if (actionItemIds !== undefined && actionItemIds !== null && tagVal && tagVal2 && tagVal.trim() !== tagVal2.trim()) {
            // AddLocalStorageData("isDirty", true);
            markDirty();
        }
        if (actionItemIds === undefined || actionItemIds === null && tagVal && tagVal2 && tagVal.trim() !== tagVal2.trim()) {
            // AddLocalStorageData("isDirty", true);
            markDirty();
        }

        //);

    }, [actionItemDetails.description, actionItemDetails?.areadrpdwn])

    const handleCancelEditor = () => {
        setEditable(false);
        setActionItemDetails((prevDetails) => ({
            ...prevDetails,
            description: atDesc,
        }));
        clearDirty();
        // RemoveLocalStorageData("isDirty");
    }
    const HandleEditorChange = (editorState) => {

        setDescriptionEditorState(editorState);
        // const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlText = editorState;
        const text = htmlText
        const hasText = htmlText.trim() !== "" && htmlText.trim().length > 0;

        setActionItemDetails((prevDetails) => ({
            ...prevDetails,
            description: htmlText,
        }));
        setError((prev) => ({
            ...prev,
            description: !hasText,
        }));
    };


    const HandleEditorStateChange = (_event, editor) => {
        const editorState = editor.getData();

        setDescriptionEditorState(editorState);
        // const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlText = editorState;
        const text = htmlText
        const hasText = htmlText.trim() !== "" && htmlText.trim().length > 0;

        setActionItemDetails((prevDetails) => ({
            ...prevDetails,
            description: htmlText,
        }));
        setError((prev) => ({
            ...prev,
            description: !hasText,
        }));
    };

    const handleIconClick = () => {
        const editorData = actionItemDetails.description;
        //ToggleLoader(true); // Toggle loader
        setEditable(false);
        HandleEditorChange(editorData);
        HandleEdit();
    };
    const handleEditorFocus = () => {
        const hasText = actionItemDetails.description.trim() !== "";
    };
    const handleKeyDownn = () => {
        const hasText = actionItemDetails.description.trim() !== "";
    };

    const NavigateToEditActionItem = (item, actionItemId) => {
        //AddLocalStorageData("actionItemTitle", item.key.toString());
    };

    const title = GetLocalStorageData("actionItemTitle");
    // const [title, setTitle] = useState(() => GetLocalStorageData("actionItemTitle"));//

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior of Enter key
            HandleEdit(); // Call HandleEdit service
            setIsTitleEditable(false);
        }
    };

    const handleBlur = (e) => {
        // Handle blur events for the input field
    };

    const HandleTitleEdit = () => setEnableTitleEdit((prev) => !prev);

    function getInitials(name) {
        if (typeof name === "string" && name.trim() !== "") {
            const nameParts = name.trim().split(" ");
            if (nameParts.length >= 2) {
                return `${nameParts[0][0]}${nameParts[1][0]}`;
            } else if (nameParts.length === 1) {
                return `${nameParts[0][0]}`;
            }
        }
        return ""; // Handle cases where name is undefined or doesn't have enough parts.
    }
    const [activeTab, setActiveTab] = useState("Comments");

    const openContent = (event, tabName) => {
        setActiveTab(tabName);
        if (tabName === 'History') {
            getHistoryDetails();
        }

    };

    // const handleChange = (e, dataName) => {

    //   const value=e.target.value;
    //   if (/^[0-9\b]+$/.test(value)) {
    //   setActionItemDetails((prev) => ({
    //     ...prev,
    //     [dataName]: e.target.value,
    //   }));
    // }};
    const roleId = Number(GetLocalStorageData("teamId"));
    // const handleChange = (e, dataName) => {
    //     const value = e.target.value;

    //     if (/^\d*\.?\d*$/.test(value)) {

    //         setActionItemDetails((prev) => ({
    //             ...prev,
    //             [dataName]: value,
    //         }));
    //         setError((prev) => ({
    //             ...prev,
    //             [dataName]: false,
    //         }));
    //     }
    // };

    const handleChange = (e, dataName) => {
        let value = e.target.value;

        // Allow only numeric input with optional decimal point
        if (/^\d*\.?\d*$/.test(value)) {
            // Check if there's a decimal part and ensure it's less than or equal to 59
            const [integerPart, decimalPart] = value.split(".");

            if (decimalPart && decimalPart.length > 2) {
                // Restrict to 2 decimal places
                value = `${integerPart}.${decimalPart.slice(0, 2)}`;
            }

            if (decimalPart && parseInt(decimalPart) > 59) {
                // Limit decimal part to 59
                value = `${integerPart}.59`;
            }

            setActionItemDetails((prev) => ({
                ...prev,
                [dataName]: value,
            }));
            setError((prev) => ({
                ...prev,
                [dataName]: false,
            }));
        }

        markDirty();
        // AddLocalStorageData("isDirty", true);
    };


    const GetUploadedFiles = (files) => {
        setdocumentFile(files);

        if (files.length > 0)
            markDirty();
        // AddLocalStorageData("isDirty", true);
    };
    const handleTypeChange = (type) => {

        setSelectedType(type);
    };

    useEffect(() => {
        if (commentsData) {
              
            if (actionItemIds !== undefined) {
                // Convert newData object to an array of values
                // Extract date and time parts
                const [date, time] = commentsData.LoggedOn.split('T');
                const timeWithoutMs = time.split('.')[0]; // Remove milliseconds part

                //Combine date and time into a single value
                const formattedDateTime = `${date} ${timeWithoutMs}`;
                const newDataArray = [
                    {
                        actionItemId: commentsData.ActionItemId,
                        comments: commentsData.Comments,
                        issuesFixed: commentsData.IssuesFixed,
                        loggedBy: commentsData.LoggedBy,
                        loggedHours: commentsData.LoggedHours,
                        loggedOn: formattedDateTime,
                        projectId: commentsData.ProjectId,
                        workDone: commentsData.WorkDone,
                        workLogDetailId: commentsData.WorkLogDetailId,
                        loggedByName: GetLocalStorageData("userName"),
                    }
                ];
                const updatedActionItemDetails = {
                    ...actionItemDetails,
                    workLogDetails: newDataArray,
                };
                setActionItemDetails(updatedActionItemDetails);
                setworkLogDetail(newDataArray);
                // setError((prev) => ({
                //     ...prev,
                //     commentEmpty: false
                // }))
            }
            else {
                const newDataArray = [
                    {
                        actionItemId: commentsData.ActionItemId,
                        comments: commentsData.Comments,
                        issuesFixed: commentsData.IssuesFixed,
                        loggedBy: commentsData.LoggedBy,
                        loggedHours: commentsData.LoggedHours,
                        loggedOn: commentsData.loggedOn,
                        projectId: commentsData.ProjectId,
                        workDone: commentsData.WorkDone,
                        workLogDetailId: commentsData.WorkLogDetailId,
                        loggedByName: GetLocalStorageData("userName"),
                    }
                ];
                const updatedActionItemDetails = {
                    ...actionItemDetails,
                    workLogDetails: newDataArray,
                };
                setActionItemDetails(updatedActionItemDetails);
                setworkLogDetail(newDataArray);
                // setError((prev) => ({
                //     ...prev,
                //     commentEmpty: false
                // }))
            }

            // setworkLogDetail("save")
            if(actionItemDetails?.workLogDetails?.length>0 && !actionItemDetails?.workLogDetail?.actionItemId ){
                 HandleAddActionItem();
            }
        }
    }, [showAddComments])

    const handleCommentsData =async (data) => {
        setCommentsData(data);
        
        if (actionItemIds !== undefined) {
            const [date, time] = data.LoggedOn.split('T');
            const timeWithoutMs = time.split('.')[0]; // Remove milliseconds part
            // Combine date and time into a single value
            const formattedDateTime = `${date} ${timeWithoutMs}`;
            const newDataArray = [
                {
                    actionItemId: data.ActionItemId,
                    comments: data.Comments,
                    issuesFixed: data.IssuesFixed,
                    loggedBy: data.LoggedBy,
                    loggedHours: data.LoggedHours,
                    loggedOn: formattedDateTime,
                    projectId: data.ProjectId,
                    workDone: data.WorkDone,
                    workLogDetailId: data.WorkLogDetailId,
                    loggedByName: GetLocalStorageData("userName"),
                }
            ];
            const updatedActionItemDetails = {
                ...actionItemDetails,
                workLogDetails: newDataArray,
            };
            setActionItemDetails(updatedActionItemDetails);
            setworkLogDetail(newDataArray);
            setIsCommentsAdded(true);
            setAddComments(true);
            // setError((prev) => ({
            //     ...prev,
            //     commentEmpty: false,
            //     commetsError: false
            // }))

            markDirty();
            // AddLocalStorageData("isDirty", true);
        }
        else {
            const newDataArray = [
                {
                    actionItemId: data.ActionItemId,
                    comments: data.Comments,
                    issuesFixed: data.IssuesFixed,
                    loggedBy: data.LoggedBy,
                    loggedHours: data.LoggedHours,
                    loggedOn: data.loggedOn,
                    projectId: data.ProjectId,
                    workDone: data.WorkDone,
                    workLogDetailId: data.WorkLogDetailId,
                    loggedByName: GetLocalStorageData("userName"),
                }
            ];
            const updatedActionItemDetails = {
                ...actionItemDetails,
                workLogDetails: newDataArray,
            };
            setActionItemDetails(updatedActionItemDetails);
            setworkLogDetail(newDataArray);
            setIsCommentsAdded(true);
            setAddComments(true);
            // setError((prev) => ({
            //     ...prev,
            //     commentEmpty: false,
            //     commetsError: false
            // }))
            // AddLocalStorageData("statusDrpDwn", actionItemDetails.statusDrpDwn);

            // let localstatusDrpDwn = GetLocalStorageData("statusDrpDwn");

            markDirty();
            // AddLocalStorageData("isDirty", true);
        }
     
    };

    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 });
    const contextMenuRef = useRef(null);

    const handlePaste = async () => {
        const clipboardItems = await navigator.clipboard.read();
        const files = [];
        for (const item of clipboardItems) {
            for (const type of item.types) {
                if (type.startsWith('image/')) {
                    const blob = await item.getType(type);
                    // Set a default name if the blob does not have a name property
                    let fileName = blob.name || `image_${new Date().getTime()}.${type.split('/')[1]}`;
                    const file = new File([blob], fileName, {
                        type: blob.type,
                        lastModified: new Date().getTime()
                    });
                    files.push(file);
                }
            }
        }

        if (files.length > 0) {
            markDirty();
            // AddLocalStorageData("isDirty", true);
            handleDroppedFiles(files);
        }
    };
    const handleContextMenu = (e) => {
        e.preventDefault();
        setContextMenuPosition({ top: 0, left: 0 });
        setContextMenuVisible(true);
    };

    const handlePasteClick = () => {
        handlePaste();
        setContextMenuVisible(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                setContextMenuVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [contextMenuRef]);
    /* <summary>
    date: 19-06-2024
    Name: Mustakim 
    description: navigate to add actionItem page to add task for current project from editActionItem page  
    <summary>*/
    const handleAddAnother = () => {
        if (isMisc === 'misc' || actionItemDetails.isMisc) {
            navigate(`/AddActionItem/${projectId}`, { state: { misc: "misc" } })
        }
        else {
            // AddLocalStorageData("locationHistory", `/Overview/${projectId}`);
            AddLocalStorageData("locationHistory", `/EditActionItem/${bKey}`);
            navigate(`/AddActionItem/${projectId}`)
        }

    }

    let newWorkLogarray = [];
    newWorkLogarray = workLogDetail != null && workLogDetail !== undefined && workLogDetail.length > 0 ? workLogDetail : actionItemDetails.workLogDetails;
    const [isMisOtherModalOpen, setIsMisOtherModalOpen] = useState(false);
    const [isTrainingMiscOther, setIsTrainingMiscOther] = useState(false);
    const [isMiscOther, setIsMiscOther] = useState(false);

    const handleMiscChange = (selectedOption) => {
        if (selectedOption !== null && selectedOption !== undefined) {
            if (selectedOption.value === 5) {
                setIsMisOtherModalOpen(true);
                setIsTrainingMiscOther(true);
                setIsMiscOther(false);

            }
            else if (selectedOption.value === 17) {
                setIsMisOtherModalOpen(true);
                setIsTrainingMiscOther(false);
                setIsMiscOther(true);
            }
            else {
                setMiscValue(selectedOption);
                setActionItemDetails({
                    ...actionItemDetails,
                    miscId: selectedOption ? selectedOption.value : null
                });
                setError((prev) => ({
                    ...prev, isTypeMisc: false
                }))
            }
        }
        else {
            setIsMisOtherModalOpen(false);
            setIsTrainingMiscOther(false);
            setIsMiscOther(false);
            setIsMisOtherModalOpen(false);
            setIsTrainingMiscOther(false);
            setIsMiscOther(false);

            setMiscValue("");
            setActionItemDetails({
                ...actionItemDetails,
                miscId: selectedOption ? selectedOption.value : null
            });
            setError((prev) => ({
                ...prev, isTypeMisc: false
            }))
        }

        markDirty();
        // AddLocalStorageData("isDirty", true);
    };

    //   const handleMiscChange = (selectedOption) => {
    //     setActionItemDetails({
    //         ...actionItemDetails,
    //         MiscId: selectedOption ? selectedOption.value : null
    //     });
    // };
    const [miscOptions, setMiscOptions] = useState([]);
    const [miscValue, setMiscValue] = useState("");
    /* <summary>
    date: 25-06-2024
    Name: Mustakim Shaikh
    description:to get Miscellaneous dropdown labels and its sub options
    <summary>*/
    const [selectedProject, setSelectedProject] = useState({
        value: 0,
        label: "",
    });
    const GetMiscellaneousTimeLogOptions = async () => {
        const resp = await ReportService.GetMiscellaneousTimeLog(0,false);

        const options = resp && resp.data && resp.data
            .filter((category) => ["Training", "Meeting", "Other"].includes(category.key))
            .map((category) => ({
                label: category.key,
                options: category.value.map((subcategory) => ({
                    value: subcategory.miscellaneousTimeLogId,
                    label: subcategory.subValues,
                    comments:subcategory.comments,
                    miscTaskName:subcategory.miscTaskName
                })),
            }));

        setMiscOptions(options);
    };
    const closeMiscModal = () => {
        setIsMisOtherModalOpen(false);
        GetMiscellaneousTimeLogOptions();
    }
    const getSelectedValue = () => {
        let selectedValue = null;
        miscOptions.forEach(category => {
            category.options.forEach(subcategory => {
                if (subcategory.value === actionItemDetails.miscId) {
                    selectedValue = subcategory;
                    //setMiscValueSelected(selectedValue);
                }
            });
        });
        return selectedValue;
    };

    const isValidDate = (date) => {
        // Check for the specific invalid date
        if (date && date.toString() === 'Mon Jan 01 0001 00:00:00 GMT+0553 (India Standard Time)') {
            return false;
        }
        return true;
    };

    const validFilteredDueDate = isValidDate(filteredDueDate) ? filteredDueDate : null;

    let getSelectedSprint = selectedSprint === undefined || selectedSprint === null ? backlogOption : selectedSprint;


    const handleFocus = (e) => {
        e.target.select();
    };
    const [focusState, setFocusState] = useState(false);
    const [hasFocused, setHasFocused] = useState(false); // Tracks if focus has been done

    // const handleEditClick = (e) => {
    //     e.stopPropagation();
    //     setIsTitleEditable(true);
    //     setTimeout(() => {
    //         
    //         if (inputRef.current) {
    //             inputRef.current.focus();
    //             inputRef.current.select();
    //         }
    //     }, 0);
    // };
    const handleEditClick = (e) => {
        e.stopPropagation();
        setIsTitleEditable(true);
        setFocusState(true); // Set focus state to true when edit is clicked
    };
    useEffect(() => {
        if (isTitleEditable && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
            setHasFocused(true); // Set to true after focusing once
        }
    }, [isTitleEditable, hasFocused]);
    const handleTickClick = (e) => {
        e.stopPropagation();
        HandleEdit();

        setIsTitleEditable(false);

    };

    const handleCrossClick = (e) => {
        e.stopPropagation();
        setActionItemDetails((prev) => ({
            ...prev, title: originalTitle
        }))
        setIsTitleEditable(false);
    };

    // const handleClickOutside = (event) => {
    //     if (containerRef.current && !containerRef.current.contains(event.target)) {
    //         setIsTitleEditable(false);
    //     }
    // };
    const handleClickOutside = (event) => {
        if (event.target.closest('.col-12.col-md-12.col-lg-8.mt-3')) {
            return;
        }
        if (actionItemIds) {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target) &&
                !event.target.classList.contains('icons')
            ) {
                const t = GetLocalStorageData("originalTitle");

                //handleCrossClick(event);
                setActionItemDetails((prev) => ({
                    ...prev, title: originalTitle
                }))


                setIsTitleEditable(false);


            }

            if (
                DescRef.current &&
                !DescRef.current.contains(event.target) &&
                !event.target.classList.contains('icons')
            ) {

                const descState = GetLocalStorageData("EditModeDesc");
                const descData = GetLocalStorageData("DescValue");
                // if(descState){
                setEditable(false);
                //handleCancelEditor();
                setActionItemDetails((prevDetails) => ({
                    ...prevDetails,
                    description: atDesc,
                }));
                AddLocalStorageData("EditModeDesc", false)
                // }



            }
        }


    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [actionItemId, originalTitle, atDesc]);
    const handleSprintChange = () => {
        HandleSprintDropdownChange(selectedEvent, "sprintId");
        setShowModal(false);
    }
    
    useEffect(() => {
        if (sprintList && selectedSprint) {
            let sprintNameWithDate = sprintList.find(a => a.value === selectedSprint.value);
            if (sprintNameWithDate) {
                setSprintDrpdwn(prev => ({
                    ...prev,
                    previousSprint: sprintNameWithDate.label,
                }));
            }

        }
    }, [sprintList, selectedSprint]);

    //SYJ - Custom serach Filter on Linked Items.
    const customSort = (a, b) => {
        // Extract the numeric part from the label
        const numA = parseInt(a.label.match(/\d+/), 10);
        const numB = parseInt(b.label.match(/\d+/), 10);

        // Sort by numeric part in ascending order
        if (numA !== numB) {
            return numA - numB;
        }

        // If numeric part is the same, fall back to alphabetical sorting
        return a.label.localeCompare(b.label);
    };

    const customFilter = (option, inputValue) => {
        // Example: Match if the option label contains the input value in any case
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
    };


    const handleSaveDesc = () => {
        //setDescSaved(true)
        AddLocalStorageData("EditModeDesc", true)
    }

    const onDirtyModalCloseClick = () => {
        setShowDirtyPopup(false);
    };

    const onProceedClick = () => {
        setShowDirtyPopup(false);
       
        // RemoveLocalStorageData("isDirty");
        let pathToNavigate = GetLocalStorageData("pathvalue");

        if (pathToNavigate !== undefined && pathToNavigate !== "" && pathToNavigate !== null && pathToNavigate != "ShowComment") {
            navigate(pathToNavigate);
        } else if (pathToNavigate === "ShowComment") {
            if(isCommentEdit){
                ShowComment(commentEditData, true);
            }
            else{
                
            ShowComment(undefined, false);
            }
        } else {
            oncancel();
        }
    };
    const [estimationData, setEstimationData] = useState([]);
    const getGeneralData = async () => {
        try {
            const response = await GlobalEstimationService.GetGeneralData();
            if (response.status === HTTPResponse.OK) {
                //await getTshirtSizeEstimate();

                setEstimationData(response.data);
                //     const options=response && response.data.filter((item)=>item.estimationAreaId===actionItemDetails.areadrpdwn.value)
                //                         .map((item)=>({label:item.workItem,value:item.estimationDataId}));
                //     //setItems(generalData);
                //     setEstimatedItemOptions(options);
            }
            else {
                ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
            }
        }
        catch (error) {
            ToggleLoader(false);
            ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
    }
    const GetEstimationOptions = () => {

        const options = estimationData && estimationData.filter((item) => item.estimationAreaId === actionItemDetails.areadrpdwn?.value)
            .map((item) => ({ label: item.workItem, value: item.estimationDataId, tShirtSizeEstTime: item.tShirtSizeEstTime }));

        //setItems(generalData);
        setEstimatedItemOptions(options);
    }
const DeleteLinkedItem=async(id,relation,index,e)=>{
    try{
        ToggleLoader(true);
        let  updatedItems=null;
        if(actionItemIds){
            let formData = new FormData();

            let data = {
             deleteLinkedItem: true,
             linkedWorkItemBridgeId:id,
            };
            formData.append("actionItemModel", JSON.stringify(data));
        
                e.preventDefault();
                  
                  if(relation != 'parent'){
                     updatedItems=actionItemDetails.linkTaskActionItem[relation].filter((item,idx)=>idx !=index);
                  }
              
                    setActionItemDetails((prevDetails) => ({
                        ...prevDetails,
                        linkTaskActionItem: {
                        ...prevDetails.linkTaskActionItem,
                        [relation]: updatedItems,
                        },
                    }));
                    
                        const response= await ActionItemService.EditActionItem(formData);
                    if(response.status===HTTPResponse.OK){
                        ToggleLoader(false);
                        await updateWorkLogDetails();
                    } 
        }
        else{

             updatedItems = [...linkedItemsArray];
              updatedItems.splice(index, 1);
            setLinkedItemsArray(updatedItems);
        }
            
           
            ToggleLoader(false);
         }
    catch (error) {
        ToggleLoader(false);
        console.log("error:", error);
    }
}
    return (
        <>
            <section className="breadcrumb-block">
                <BreadcrumbComponent
                    //projectName={GetLocalStorageData("projectName")}
                    projectName={projectName
                        // ? projectName : GetLocalStorageData("projectName")
                    }
                    //title={title}
                    //title={GetLocalStorageData("actionItemTitle")}
                    title={bKey}
                    projectID={projectId}
                />
            </section>
            <main
                className="main-body-content-Edit-Issue"
                style={{ overflowX: "hidden" }}
            >
                <AddMiscellenousOther
                    isOpen={isMisOtherModalOpen}
                    setShowModal={closeMiscModal}
                    isTrainingOtherSelcted={isTrainingMiscOther}
                    isMiscOtherSelected={isMiscOther}
                />
                {showDefinition && (<DeleteModal
                    showDeleteModal={showDefinition}
                    onNoClick={closeShowDefinition}
                    //textToDisplay={["Code Issues:Occurs when the implemented module's functionality stops working as intended. Arises when the code's logic is misunderstood, resulting in unexpected behavior or incorrect outcomes. Merge Issues: These occur during code integration between branches and involve missing components during the merge process. Data Issues: Involves discrepancies between displayed data on the user interface and actual data.Compatibility Issues: Arise when code doesn't run smoothly across different systems, platforms, or environments due to varying configurations. Requirement Miss: Result from inadequate consideration of requirements, not documented correctly leading to unaddressed impacts on multiple areas affected by new changes.Incorrect Requirement Specifications: Arises when the requirements are misunderstood by the developer.Network issues: Network issues can have a significant impact on the performance, functionality, and reliability of software applications or systems that rely on network connectivity. I.e. Load balance, internet issue, server issue.Performance Issues: Usually happens when user request and response take time to load the data. It could be due to various reasons such as Connectivity etc.Responsive issues: Responsive issues occur when the design and layout of a website or application don't adapt properly to these different screen sizes, leading to elements overlapping, text becoming unreadable, images not fitting correctly, and overall poor user experience. Security Issues: Arise from the unauthorized access of confidential data, allowing users lacking proper authority to gain entry. Environmental Issues: Works on Dev and not on UAT or Prod, or works on UAT and not on Prod. Tester Error: Due to testing incorrectly "]}
                    rootCause={true}
                />)}
                {showModal && (

                    <DeleteModal
                        sprintDetails={sprintDrpdwn}
                        sprintConfirmModal={true}
                        onNoClick={closeSprintCModal}
                        onYesClick={handleSprintChange}
                    />
                )}
                {showDirtyPopup && (
                    <DeleteModal
                        showDeleteModal={showDirtyPopup}
                        isDirtyModal={true}
                        onNoClick={onDirtyModalCloseClick}
                        onYesClick={onProceedClick}
                    />
                )}
                <section className="Edit-issue " style={{ position: "relative" }}>
                    <div className="">

                        <div className="">
                            <div className="">

                                <section className="edit-task-body">
                                    <div className="container-fluid">
                                        <div className="edit-task" style={{ paddingBottom: 0 }}>





                                            {/* <div class="row">
                <div className="col-12 col-md-6 col-lg-4 mt-3">
                  <label>Type <span className="mandatory-field">*</span></label>
                  <div className="row">
                    <div className="col-5 col-sm-4 col-md-6 col-lg-6">
                      <input type="radio"/>
                      <span className="task-type">Project</span>
                    </div>
                    <div className="col-7 col-sm-6 col-md-6 col-lg-6">
                      <input type="radio"/>
                      <span className="task-type">Miscellaneous</span>
                    </div>
                  </div>
                </div>
</div> */}
                                            {/* {(!isMisc && actionItemIds) && (<div className="row">
                                                <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                    <label>Type <span className="mandatory-field">*</span></label>
                                                    <div className="row">
                                                        <div className="col-5 col-sm-4 col-md-6 col-lg-6">
                                                            <input type="radio" name="taskType"
                                                                //onClick={handleActiveRadio("Project")}
                                                                checked={!actionItemDetails.isMisc}
                                                            // disabled
                                                            />
                                                            <span className="task-type">Project</span>
                                                        </div>
                                                        <div className="col-7 col-sm-6 col-md-6 col-lg-6">
                                                            <input type="radio" name="taskType"
                                                                //onClick={handleActiveRadio("Misc")}
                                                                checked={actionItemDetails.isMisc}
                                                            //disabled
                                                            />
                                                            <span className="task-type">Miscellaneous</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)} */}

                                            <div className="row">
                                                {(actionItemIds && !actionItemDetails.isMisc === true || !actionItemIds && !isMisc) && (
                                                    <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                        <label>
                                                            Work Item Type{" "}
                                                            <span className="mandatory-field">*</span>
                                                        </label>
                                                        <Select
                                                            inputId="typeDrpDwn"
                                                            placeholder="Please Select"
                                                            value={actionItemDetails?.typeDrpDwn}
                                                            options={ActionItemTypes.filter(a => a.value != 7 && a.value != 8 && a.value != 9 && a.value != 10 && a.value != 11)}
                                                            onChange={(e) =>
                                                                HandleDropdownChange(e, "typeDrpDwn")
                                                            }
                                                            formatOptionLabel={(p) => (
                                                                <div>
                                                                    <img
                                                                        width={"20px"}
                                                                        height={"20px"}
                                                                        src={p?.imagePath}
                                                                        alt=""
                                                                        style={{ marginRight: "3px" }}
                                                                    />
                                                                    <span>{p.label}</span>
                                                                </div>
                                                            )}
                                                            isDisabled={actionItemDetails.isDefault}
                                                            isClearable={actionItemDetails.typeDrpDwn !== undefined || (!actionItemDetails.typeDrpDwn === null && actionItemDetails?.typeDrpDwn?.value > 0)}
                                                        />
                                                        <ErrorField
                                                            show={error.typeId}
                                                            message={AddEditActionItemErrorMsg.IssueType}
                                                        />
                                                    </div>)}
                                                {(actionItemIds && !actionItemDetails.isMisc === true || !actionItemIds && !isMisc) && (
                                                    <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                        <label>Sprint <span className="mandatory-field">*</span></label>

                                                        <Select
                                                            inputId="sprintId"
                                                            placeholder="Please Select"
                                                            value={sprintIdCompleted ? sprintList.find(a => a.value === sprintIdCompleted) : selectedSprint != undefined ? selectedSprint : getSelectedSprint}
                                                            options={sprintList}
                                                            onChange={(e) =>
                                                                actionItemIds ? HandleSprintDropdownChangeEdit(e) : HandleSprintDropdownChangeEdit(e)
                                                                //HandleSprintDropdownChange(e, "sprintId")
                                                            }
                                                            isDisabled={
                                                                (sprintNames && sprint_Ids) ||
                                                                actionItemDetails.isDefault === true
                                                            }
                                                            isClearable={selectedSprint !== undefined || (!selectedSprint === null && selectedSprint?.value > 0)}
                                                        />
                                                    </div>)}


                                                {/* {(!isMisc || actionItemDetails.isMisc) && ( */}
                                                {((isMisc === 'misc' || actionItemDetails.isMisc)) ? (
                                                    <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                        <label>Miscellaneous <span className="mandatory-field">*</span></label>
                                                        <Select
                                                            name="miscList"
                                                            isDisabled={selectedProject.value > 0}
                                                            // value={miscOptions.find(a => a.subValues === actionItemDetails.MiscId)}
                                                            value={getSelectedValue()}

                                                            placeholder="Please Select"
                                                            options={miscOptions}
                                                            //styles={customStyles}
                                                            //  value={miscValue} // Set the selected value
                                                            onChange={handleMiscChange} // Call the handler function on change

                                                            //components={{ DropdownIndicator }}
                                                            //components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} miscValue={miscValue} /> }} // Pass selectedProject as a prop


                                                            isClearable={miscValue !== undefined || (!miscValue === null || miscValue.value > 0)}
                                                        />
                                                        <ErrorField
                                                            show={error.isTypeMisc}
                                                            message={ReportErrorMessage.Misc}
                                                        />
                                                    </div>) : null}


                                                <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                    <label>
                                                        {/* Due Date <span className="mandatory-field">*</span> */}
                                                        Due Date <span className="mandatory-field">{actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label == "Done" ? "" : selectedSprint !== undefined && selectedSprint.value > 0 ? "*" : ""}</span>
                                                    </label>
                                                    <div className="dateandclear" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

                                                        <label>
                                                            <DatePicker
                                                                placeholderText="Select Date"
                                                                className="form-control"
                                                                dateFormat="dd-MM-yyyy"
                                                                width="150px"
                                                                selected={filteredDueDate}
                                                                onChange={handleDueDateChange}
                                                                showIcon={true}
                                                                onKeyDown={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                                minDate={selectedSprint !== null && selectedSprint !== undefined && selectedSprint.label === "Backlog" ? new Date() : sprintStartDate !== undefined && sprintStartDate !== null && sprintStartDate.toString() !== 'Mon Jan 01 0001 00:00:00 GMT+0553 (India Standard Time)' && (!isMisc === 'misc' || !actionItemDetails.isMisc) && sprintStartDate} // Set minimum date to sprint start date
                                                                maxDate={sprintEndDate !== undefined && sprintEndDate !== null && sprintEndDate.toString() !== 'Mon Jan 01 0001 00:00:00 GMT+0553 (India Standard Time)' && (!isMisc === 'misc' || !actionItemDetails.isMisc) ? sprintEndDate : null}   // Set maximum date to sprint end date
                                                                popperClassName="ag-custom-component-popup"
                                                                scrollableYearDropdown={true}
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                todayButton="Today"
                                                            />
                                                        </label>
                                                        {/* {actionItemIds && (
                                                            // <div className="row">

                                                            // <div className="col-12 col-md-12 col-lg-12 mt-3" style={{ display: 'flex', justifyContent: 'end' }}>
                                                            <div> <button style={{ padding: '6px 10px' }}
                                                                id="btnCreateAnother"
                                                                name="btncreateAnother"
                                                                className="save-btn"
                                                                onClick={handleAddAnother}
                                                                disabled={isProcessing} // Disable when processing
                                                            >
                                                                <span>Add Work Item</span>
                                                            </button></div>

                                                            // </div>

                                                            // </div>

                                                        )} */}
                                                    </div>
                                                    <ErrorField
                                                        show={error.dueDateEmpty}
                                                        message={CommonErrorMessages.Required}
                                                    />

                                                    {/* <ErrorField
                            show={error.dueDateInvalid}
                            message={CommonErrorMessages.DueDate}
                          /> */}
                                                    {isSprintFieldFilled && (
                                                        <>
                                                            {/* <ErrorField
                                show={error.dueDateEmpty}
                                message={CommonErrorMessages.Required}
                              /> */}
                                                            <ErrorField
                                                                show={error.dueDateInvalid}
                                                                message={CommonErrorMessages.DueDate}
                                                            />
                                                        </>
                                                    )}


                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                    <label>
                                                        Priority <span className="mandatory-field">*</span>
                                                    </label>
                                                    <Select
                                                        inputId="priorityDrpDwn"
                                                        placeholder="Please Select"
                                                        value={actionItemDetails?.priorityDrpDwn}
                                                        options={PriorityList}
                                                        onChange={(e) =>
                                                            HandleDropdownChange(e, "priorityDrpDwn")
                                                        }
                                                        formatOptionLabel={(p) => (
                                                            <div>
                                                                <img
                                                                    width={"20px"}
                                                                    height={"20px"}
                                                                    src={p?.imagePath}
                                                                    alt=""
                                                                    style={{ marginRight: "3px" }}
                                                                />
                                                                <span>{p.label}</span>
                                                            </div>
                                                        )}
                                                        isClearable={(actionItemDetails?.priorityDrpDwn !== undefined || (!actionItemDetails?.priorityDrpDwn == null && actionItemDetails?.priorityDrpDwn.value > 0))}
                                                    />
                                                    <ErrorField
                                                        show={error.priorityId}
                                                        message={AddEditActionItemErrorMsg.Priority}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                    <label>
                                                        Status <span className="mandatory-field">*</span>
                                                    </label>
                                                    <Select
                                                        inputId="statusDrpDwn"
                                                        placeholder="Please Select"
                                                        isDisabled={actionItemIds != null && actionItemIds != undefined && actionItemDetails.workLogDetails != undefined && actionItemDetails.workLogDetails.length == 0 ? true : false}
                                                        value={actionItemDetails?.statusDrpDwn}
                                                        //options={actionItemDetails?.statusActionList}
                                                        options={
                                                            (isMisc === 'misc' || actionItemDetails.isMisc || actionItemDetails.isDefault)
                                                                ? actionItemDetails?.statusActionList.filter(
                                                                    (option) => option.label === "To Do" || option.label === "In Progress" || option.label === "Done"
                                                                )
                                                                : actionItemDetails?.statusActionList
                                                        }
                                                        // options={actionItemStatusData}
                                                        onChange={(e) => {
                                                             
                                                            if (!isCommentsAdded ) {
                                                                if(ValidateForm() && actionItemIds){
                                                                   
                                                                    return;
                                                                }
                                                                setShowOnSaveModal(true);
                                                                setShowDeleteModal(true);
                                                            }
                                                            else {
                                                                HandleDropdownChange(e, "statusDrpDwn")
                                                            }
                                                        }}
                                                        formatOptionLabel={(p) => (
                                                            <div>
                                                                {p.imagePath != null &&
                                                                    p.imagePath != undefined && (
                                                                        <img
                                                                            width={"20px"}
                                                                            height={"20px"}
                                                                            src={p?.imagePath}
                                                                            alt=""
                                                                            style={{ marginRight: "3px" }}
                                                                        />
                                                                    )}
                                                                <span>{p.label}</span>
                                                            </div>
                                                        )}
                                                        isClearable={actionItemDetails?.statusDrpDwn !== undefined || (!actionItemDetails?.statusDrpDwn == null && actionItemDetails?.statusDrpDwn.value > 0)}
                                                    />
                                                    <ErrorField
                                                        show={error.statusId}
                                                        message={CommonErrorMessages.Required}
                                                    />
                                                </div>


                                                <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                    <label>
                                                        Assign To <span className="mandatory-field">*</span>
                                                    </label>
                                                    <Select
                                                        inputId="linkedActionItemId"
                                                        placeholder="Please Select"
                                                        value={actionItemDetails?.assigneeDrpDwn}
                                                        options={assigneeList}
                                                        onChange={(e) =>
                                                            HandleDropdownChange(e, "assigneeDrpDwn")
                                                        }
                                                        formatOptionLabel={(p) => (
                                                            <div>
                                                                {p.imagePath && (
                                                                    <img
                                                                        width={"20px"}
                                                                        height={"20px"}
                                                                        src={p?.imagePath}
                                                                        alt=""
                                                                        style={{ marginRight: "3px" }}
                                                                    />
                                                                )}
                                                                <span>{p.label}</span>
                                                            </div>
                                                        )}
                                                        isClearable={actionItemDetails?.assigneeDrpDwn !== undefined || (!actionItemDetails?.assigneeDrpDwn === null && actionItemDetails?.assigneeDrpDwn.value > 0)}
                                                    />
                                                    <ErrorField
                                                        show={error.assigneeId}
                                                        message={AddEditActionItemErrorMsg.Assignee}
                                                    />
                                                </div>


                                                <div className="col-12 col-md-12 col-lg-8 mt-2">
                                                    <label>
                                                        Title <span className="mandatory-field">*</span>
                                                    </label>
                                                    {actionItemIds === undefined ? (
                                                        <input
                                                            className="form-control custom-input-ns "
                                                            id="title"
                                                            type="text"
                                                            value={actionItemDetails.title}
                                                            //title={actionItemDetails.title}
                                                             maxLength={300}
                                                            onChange={HandleFieldChange}
                                                        // onFocus={handleFocus}
                                                        />
                                                    ) : (
        //                                                 <div className="title-hover-sec" ref={containerRef} style={{ position: 'relative' }} onClick={handleEditClick}>
        //                                                     <input
        //                                                         ref={inputRef}
        //                                                         className="form-control custom-input-ns custom-title"
        //                                                         id="title"
        //                                                         type="text"
        //                                                         value={actionItemDetails.title}
        //                                                         //onChange={(e) => setTitle(e.target.value)}
        //                                                         onChange={HandleFieldChange}
        //                                                         onKeyDown={handleKeyDown}
        //                                                         //readOnly={!isTitleEditable}
        //                                                         onBlur={handleBlur}
        //                                                         //onFocus={handleFocus}
        //                                                          maxLength={300}
        //                                                         onClick={handleEditClick}
        //                                                     //title={actionItemDetails.title}
        //                                                     //style={{ backgroundColor: isTitleEditable ? 'white' : '#f1f1f1', paddingRight: "38px" }}
        //                                                     />
        //                                                     {title && (
        //                                                         <img
        //                                                             width="19px"
        //                                                             className="edit-icon"
        //                                                             src="../Assets/images/Edit_icon.png"
        //                                                             alt="Edit"
        //                                                             // onClick={() => setIsTitleEditable(true)}
        //                                                             onClick={handleEditClick}
        //                                                             title="Edit"
        //                                                             style={{
        //                                                                 cursor: 'pointer',
        //                                                                 position: 'absolute',
        //                                                                 right: '10px',
        //                                                                 top: '19px',
        //                                                                 transform: 'translateY(-50%)',
        //                                                             }}
        //                                                         />
        //                                                     )}
        //                                                     {isTitleEditable && (
        //                                                         <div className="icons" >
        //                                                             <button
        //                                                                 className="edit-title-action-btn"
        //                                                                 alt="Tick"
        //                                                                 onClick={handleTickClick}
        //                                                                 title="Save"
        //                                                                 style={{
        //                                                                     cursor: 'pointer',
        //                                                                     marginRight: '5px',
        //                                                                 }}
        //                                                             ><img className="edit-title-action-icon" src="../Assets/images/tick (1).png" alt="" /></button>
        //                                                             <button
        //                                                                 className="edit-title-action-btn"
        //                                                                 alt="Cross"
        //                                                                 onClick={handleCrossClick}
        //                                                                 title="Cancel"
        //                                                                 style={{
        //                                                                     cursor: 'pointer',
        //                                                                 }}
        //                                                             ><img className="edit-title-action-icon" src="../Assets/images/cross (1).png" alt="" /></button>
        //                                                             {/* <button className="daily-button" style={{border:'none'}}  onClick={handleTickClick}>Daily</button>
        //     <button className="monthly-button"  style={{border:'none'}} onClick={handleCrossClick}>Weekly / Monthly</button>
        // */}
        //                                                         </div>
        //                                                     )}
        //                                                 </div>
        <div className={isTitleEditable?"title-sec":"title-hover-sec"}  ref={containerRef} style={{ position: 'relative',backgroundColor:'#ffffff' }} onClick={handleEditClick}>
   
   {/* {actionItemIds && actionItemDetails.title==="" && (
   < div className="form-control custom-input-ns custom-title"></div>
   )}
    */}
    {!isTitleEditable ? (
        // Render the title as an <h1> element when not editing
        <span
        title={actionItemDetails.title}
            className="title-display" 
            style={{
                cursor: 'pointer',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitLineClamp: 2,
                whiteSpace: 'normal', 
            }}
            onClick={() => setIsTitleEditable(true)}
        >
            {actionItemDetails.title}
        </span>
    ) : (
        // Render the title as an <textarea> when editing
        <input
            ref={inputRef}
            className="form-control custom-input-ns custom-title"
            id="title"
             maxLength={300}
            value={actionItemDetails.title}
            onChange={HandleFieldChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            rows={2}
            style={{ backgroundColor: 'white', paddingRight: '38px', resize: 'none', width: '100%' }}
            autoFocus
        />
    )}
<div className="edit-icon" style={{position: "absolute",
    backgroundColor: "rgb(199 199 199)",
    cursor:'pointer',
    height: "100%",
    width: "25px",
    right: "0",
    top: "0",
    alignContent: "center",
    textAlign: "-webkit-center"}}>{!isTitleEditable && (
        <img
            width="14px"
            className=""
            src="../Assets/images/Edit_icon.png"
            alt="Edit"
            onClick={() => setIsTitleEditable(true)}
            title="Edit"
          
        />
    )}
</div>
    
    {isTitleEditable && (
        <div className="icons">
            <button
                className="edit-title-action-btn"
                alt="Tick"
                onClick={handleTickClick}
                title="Save"
                style={{
                    cursor: 'pointer',
                    marginRight: '5px',
                }}
            >
                <img className="edit-title-action-icon" src="../Assets/images/tick (1).png" alt="" />
            </button>
            <button
                className="edit-title-action-btn"
                alt="Cross"
                onClick={handleCrossClick}
                title="Cancel"
                style={{
                    cursor: 'pointer',
                }}
            >
                <img className="edit-title-action-icon" src="../Assets/images/cross (1).png" alt="" />
            </button>
        </div>
    )}
</div>
                                                    )}
                                                    <ErrorField
                                                        show={error.title}
                                                        message={AddEditActionItemErrorMsg.Title}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4 mt-2">
                                                    <label>
                                                        Category <span className="mandatory-field">*</span>
                                                    </label>
                                                    <Select
                                                        inputId="CategoryDrpDwn"
                                                        placeholder="Please Select"
                                                        value={actionItemDetails?.categoryDrpDwn?.label != "" ? actionItemDetails.categoryDrpDwn : null}

                                                        options={categoryList}
                                                        onChange={(e) =>
                                                            HandleDropdownChange(e, "categoryDrpDwn")
                                                        }

                                                        //isClearable={actionItemDetails.categoryDrpDwn !== undefined || (!actionItemDetails.categoryDrpDwn === null && actionItemDetails?.categoryDrpDwn?.value > 0)}
                                                        isClearable={actionItemDetails?.categoryDrpDwn !== undefined && (!actionItemDetails?.categoryDrpDwn === null || actionItemDetails?.categoryDrpDwn.value > 0 || actionItemDetails?.categoryDrpDwn.value !== 0)}
                                                    />
                                                    <ErrorField
                                                        show={error.categoryId}
                                                        message={AddEditActionItemErrorMsg.Category}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row" >
                                                <div className="col-12 col-md-12 col-lg-8 mt-2 desc " ref={DescRef} >

                                                    <label style={{ position: 'relative' }}>
                                                        Description <span className="mandatory-field">*</span>
                                                        {actionItemIds !== null && actionItemIds !== undefined && (
                                                            <img
                                                                src="../Assets/images/check-mark.png"
                                                                width={20}
                                                                //onClick={handleIconClick}
                                                                style={{ cursor: 'pointer', marginLeft: '5px', display: showIcon ? 'inline-block' : 'none', position: 'absolute', bottom: '3px', right: '-25px' }} // Use display property
                                                            />
                                                        )}
                                                    </label>
                                                    <RichTextEditor
                                                        value={actionItemDetails.description}
                                                        onChange={HandleEditorStateChange}
                                                        isEditActionItem={actionItemIds ? true : false}
                                                        handleCancelEditor={handleCancelEditor}
                                                        handleSaveEditor={handleIconClick}
                                                        editModeDesc={handleSaveDesc}
                                                        changeDescState={() => setEditable(true)}
                                                        editable={editable}
                                                    />

                                                    <ErrorField
                                                        show={error.description}
                                                        message={AddEditActionItemErrorMsg.Description}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-4 mt-2">
                                                    <div className="time-track-grid-header-fbox">
                                                        <div>
                                                            <label className="time-track-grid-header">
                                                            Add Estimates (hrs:mins) {" "}
                                                                {actionItemIds && (<span className="mandatory-field">*</span>)}
                                                            </label>
                                                        </div>
                                                        {/* <div>
                              <a className="add-icon">  
                                <img src="Assets/images/add-record-icon.png" />
                              </a>
                            </div> */}
                                                    </div>
                                                    <div className="custom-grid-sec">
                                                        <table className="custom-grid">
                                                            <thead>
                                                                <th>Type</th>
                                                                <th className="align-center">Design</th>
                                                                <th className="align-center">Dev</th>
                                                                <th className="align-center">QA</th>
                                                                <th className="align-center">Total</th>
                                                            </thead>

                                                            <tbody>
                                                                <tr>
                                                                    <td>Original</td>
                                                                    <td className="align-center">
                                                                        <input
                                                                            className="align-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                border: error.designEstimates ? "2px solid #ff0018" : "1px solid #b5b5b5",
                                                                                borderRadius: "3px",
                                                                            }}
                                                                            type="text"
                                                                            value={actionItemDetails.designEstimates}
                                                                            maxLength={4}
                                                                            onChange={(e) =>
                                                                                handleChange(e, "designEstimates")
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.preventDefault(); // Prevent default behavior of Enter key
                                                                                    HandleEdit(); // Call HandleEdit function
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="align-center">
                                                                        <input
                                                                            className="align-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                border: error.devEstimates ? "2px solid #ff0018" : "1px solid #b5b5b5",
                                                                                borderRadius: "3px",
                                                                            }}
                                                                            type="text"
                                                                            value={actionItemDetails.devEstimates}
                                                                            maxLength={4}
                                                                            onChange={(e) =>
                                                                                handleChange(e, "devEstimates")
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.preventDefault(); // Prevent default behavior of Enter key
                                                                                    HandleEdit(); // Call HandleEdit function
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="align-center">
                                                                        <input
                                                                            className="align-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                border: error.qaEstimates ? "2px solid #ff0018" : "1px solid #b5b5b5",
                                                                                borderRadius: "3px",
                                                                                //borderColor:error.qaEstimates?"red":""
                                                                            }}
                                                                            type="text"
                                                                            value={actionItemDetails.qaEstimates}
                                                                            maxLength={4}
                                                                            onChange={(e) =>
                                                                                handleChange(e, "qaEstimates")
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.preventDefault(); // Prevent default behavior of Enter key
                                                                                    HandleEdit(); // Call HandleEdit function
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="align-center" style={{ fontWeight: 'bold' }}>
                                                                        {(
                                                                            Number(
                                                                                actionItemDetails.designEstimates
                                                                            ) +
                                                                            Number(actionItemDetails.devEstimates) +
                                                                            Number(actionItemDetails.qaEstimates)
                                                                        )
                                                                            .toFixed(2)
                                                                            .replace(/\.?0+$/, "")}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ width: "45px" }}>Rework</td>
                                                                    <td className="align-center">
                                                                        <input
                                                                            className="align-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                border: "1px solid #b5b5b5",
                                                                                borderRadius: "3px",
                                                                            }}
                                                                            type="text"
                                                                            value={actionItemDetails.designRework}
                                                                            maxLength={4}
                                                                            onChange={(e) =>
                                                                                handleChange(e, "designRework")
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.preventDefault(); // Prevent default behavior of Enter key
                                                                                    HandleEdit(); // Call HandleEdit function
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="align-center">
                                                                        <input
                                                                            className="align-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                border: "1px solid #b5b5b5",
                                                                                borderRadius: "3px",
                                                                            }}
                                                                            type="text"
                                                                            value={actionItemDetails.devRework}
                                                                            maxLength={4}
                                                                            onChange={(e) =>
                                                                                handleChange(e, "devRework")
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.preventDefault(); // Prevent default behavior of Enter key
                                                                                    HandleEdit(); // Call HandleEdit function
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="align-center">
                                                                        <input
                                                                            className="align-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                border: "1px solid #b5b5b5",
                                                                                borderRadius: "3px",
                                                                            }}
                                                                            type="text"
                                                                            value={actionItemDetails.qaRework}
                                                                            maxLength={4}
                                                                            onChange={(e) =>
                                                                                handleChange(e, "qaRework")
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.preventDefault(); // Prevent default behavior of Enter key
                                                                                    HandleEdit(); // Call HandleEdit function
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="align-center" style={{ fontWeight: 'bold' }}>
                                                                        {(
                                                                            parseFloat(
                                                                                actionItemDetails.designRework || 0
                                                                            ) +
                                                                            parseFloat(
                                                                                actionItemDetails.devRework || 0
                                                                            ) +
                                                                            parseFloat(
                                                                                actionItemDetails.qaRework || 0
                                                                            )
                                                                        )
                                                                            .toFixed(2)
                                                                            .replace(/\.?0+$/, "")}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Buffer</td>
                                                                    <td className="align-center">
                                                                        <input
                                                                            className="align-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                border: "1px solid #b5b5b5",
                                                                                borderRadius: "3px",
                                                                            }}
                                                                            type="text"
                                                                            value={actionItemDetails.designBuffer}
                                                                            maxLength={4}
                                                                            onChange={(e) =>
                                                                                handleChange(e, "designBuffer")
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.preventDefault(); // Prevent default behavior of Enter key
                                                                                    HandleEdit(); // Call HandleEdit function
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="align-center">
                                                                        <input
                                                                            className="align-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                border: "1px solid #b5b5b5",
                                                                                borderRadius: "3px",
                                                                            }}
                                                                            type="text"
                                                                            value={actionItemDetails.devBuffer}
                                                                            maxLength={4}
                                                                            onChange={(e) =>
                                                                                handleChange(e, "devBuffer")
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.preventDefault(); // Prevent default behavior of Enter key
                                                                                    HandleEdit(); // Call HandleEdit function
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="align-center">
                                                                        <input
                                                                            className="align-center"
                                                                            style={{
                                                                                width: "40px",
                                                                                border: "1px solid #b5b5b5",
                                                                                borderRadius: "3px",
                                                                            }}
                                                                            type="text"
                                                                            value={actionItemDetails.qaBuffer}
                                                                            maxLength={4}
                                                                            onChange={(e) =>
                                                                                handleChange(e, "qaBuffer")
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    e.preventDefault(); // Prevent default behavior of Enter key
                                                                                    HandleEdit(); // Call HandleEdit function
                                                                                }
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td className="align-center" style={{ fontWeight: 'bold' }}>
                                                                        {(
                                                                            parseFloat(
                                                                                actionItemDetails.designBuffer || 0
                                                                            ) +
                                                                            parseFloat(
                                                                                actionItemDetails.devBuffer || 0
                                                                            ) +
                                                                            parseFloat(
                                                                                actionItemDetails.qaBuffer || 0
                                                                            )
                                                                        )
                                                                            .toFixed(2)
                                                                            .replace(/\.?0+$/, "")}
                                                                    </td>
                                                                </tr>
                                                                {actionItemIds && (
                                                                    <tr >
                                                                        <td
                                                                            className="estimatedRow"
                                                                            style={{
                                                                                backgroundColor: "#cecece",
                                                                                fontFamily: "Lato-Bold !important",
                                                                            }}
                                                                        >
                                                                            Estimated Total
                                                                        </td>
                                                                        <td
                                                                            className="align-center estimatedRow"
                                                                            style={{
                                                                                backgroundColor: "#cecece",
                                                                                fontFamily: "Lato-Bold !important",
                                                                                fontWeight: 'bold'
                                                                            }}
                                                                        >
                                                                            {formatCorrectHours((
                                                                                Number(
                                                                                    actionItemDetails.designEstimates
                                                                                ) +
                                                                                Number(actionItemDetails.designRework) +
                                                                                Number(actionItemDetails.designBuffer)
                                                                            )
                                                                                .toFixed(2)
                                                                                .replace(/\.?0+$/, ""))}
                                                                        </td>
                                                                        <td
                                                                            className="align-center estimatedRow"
                                                                            style={{
                                                                                backgroundColor: "#cecece",
                                                                                fontFamily: "Lato-Bold !important",
                                                                                fontWeight: 'bold'
                                                                            }}
                                                                        >
                                                                            {formatCorrectHours((
                                                                                Number(actionItemDetails.devEstimates) +
                                                                                Number(actionItemDetails.devRework) +
                                                                                Number(actionItemDetails.devBuffer)
                                                                            )
                                                                                .toFixed(2)
                                                                                .replace(/\.?0+$/, ""))}
                                                                        </td>
                                                                        <td
                                                                            className="align-center estimatedRow"
                                                                            style={{
                                                                                backgroundColor: "#cecece",
                                                                                fontFamily: "Lato-Bold !important",
                                                                                fontWeight: "bold"
                                                                            }}
                                                                        >
                                                                            {formatCorrectHours((
                                                                                Number(actionItemDetails.qaEstimates) +
                                                                                Number(actionItemDetails.qaRework) +
                                                                                Number(actionItemDetails.qaBuffer)
                                                                            )
                                                                                .toFixed(2)
                                                                                .replace(/\.?0+$/, ""))}
                                                                        </td>
                                                                        <td
                                                                            className="align-center estimatedRow"
                                                                            style={{
                                                                                backgroundColor: "#cecece",
                                                                                fontFamily: "Lato-Bold !important",
                                                                                fontWeight: 'bold'
                                                                            }}
                                                                        >
                                                                            {formatCorrectHours((
                                                                                Number(
                                                                                    actionItemDetails.designEstimates
                                                                                ) +
                                                                                Number(actionItemDetails.designRework) +
                                                                                Number(actionItemDetails.designBuffer) +
                                                                                Number(actionItemDetails.devEstimates) +
                                                                                Number(actionItemDetails.devRework) +
                                                                                Number(actionItemDetails.devBuffer) +
                                                                                Number(actionItemDetails.qaEstimates) +
                                                                                Number(actionItemDetails.qaRework) +
                                                                                Number(actionItemDetails.qaBuffer)
                                                                            )
                                                                                .toFixed(2)
                                                                                .replace(/\.?0+$/, ""))}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                                {actionItemIds && (
                                                                    <tr>
                                                                        <td className="estimatedRow">
                                                                            Actual Time
                                                                        </td>
                                                                        <td
                                                                            //className="align-center estimatedRow  green "
                                                                            className={`align-center estimatedRow ${(designHrs > 0 ? designHrs : 0) >
                                                                                //Number(actionItemDetails.designEstimates)
                                                                                Number(
                                                                                    actionItemDetails.designEstimates
                                                                                ) +
                                                                                Number(
                                                                                    actionItemDetails.designRework
                                                                                ) +
                                                                                Number(actionItemDetails.designBuffer)
                                                                                ? "red"
                                                                                : "green"
                                                                                }`}
                                                                            style={{ width: "40px" }}
                                                                        >
                                                                            {/* {actionItemDetails.designLoggedHours} */}
                                                                            {formatCorrectHours(designHrs > 0 ? designHrs : 0)}

                                                                            {/* {formatCorrectHours(actionItemDetails.designLoggedHours > 0 ? Number(actionItemDetails.designLoggedHours).toFixed(2).replace(/\.?0+$/, "") : 0)} */}
                                                                          

                                                                        </td>
                                                                        <td
                                                                            className={`align-center estimatedRow ${(devHrs > 0 ? devHrs : 0) >
                                                                                //Number(actionItemDetails.devEstimates)
                                                                                Number(actionItemDetails.devEstimates) +
                                                                                Number(actionItemDetails.devRework) +
                                                                                Number(actionItemDetails.devBuffer)
                                                                                ? "red"
                                                                                : "green"
                                                                                }`}
                                                                            style={{ width: "40px" }}
                                                                        >
                                                                            {/* {actionItemDetails.devLoggedHours} */}
                                                                            {/* {formatCorrectHours(actionItemDetails.devLoggedHours > 0 ? Number(actionItemDetails.devLoggedHours).toFixed(2).replace(/\.?0+$/, "") : 0)} */}
                                                                            {formatCorrectHours(devHrs > 0 ? devHrs : 0)}
</td>
                                                                        <td
                                                                            className={`align-center estimatedRow ${(qaHrs > 0 ? qaHrs : 0) >
                                                                                //Number(actionItemDetails.qaEstimates)
                                                                                (
                                                                                    Number(
                                                                                        actionItemDetails.qaEstimates
                                                                                    ) +
                                                                                    Number(actionItemDetails.qaRework) +
                                                                                    Number(actionItemDetails.qaBuffer)
                                                                                )
                                                                                // .toFixed(2)
                                                                                // .replace(/\.?0+$/, "")
                                                                                ? "red"
                                                                                : "green"
                                                                                }`}
                                                                            style={{ width: "40px" }}
                                                                        >
                                                                            {/* {actionItemDetails.qaLoggedHours} */}
                                                                            {/* {formatCorrectHours(actionItemDetails.qaLoggedHours > 0 ? Number(actionItemDetails.qaLoggedHours).toFixed(2).replace(/\.?0+$/, "") : 0)} */}
                                                                            {formatCorrectHours(qaHrs > 0 ? qaHrs : 0)}
                                                                        </td>
                                                                        <td
                                                                            className={`align-center estimatedRow ${Number(
                                                                                actionItemDetails.designLoggedHours ||
                                                                                0,
                                                                                10
                                                                            ) +
                                                                                Number(
                                                                                    actionItemDetails.devLoggedHours || 0,
                                                                                    10
                                                                                ) +
                                                                                Number(
                                                                                    actionItemDetails.qaLoggedHours || 0,
                                                                                    10
                                                                                ) >
                                                                                //Number(actionItemDetails.devEstimates)
                                                                                (
                                                                                    Number(
                                                                                        actionItemDetails.designEstimates
                                                                                    ) +
                                                                                    Number(actionItemDetails.designRework) +
                                                                                    Number(actionItemDetails.designBuffer) +
                                                                                    Number(actionItemDetails.devEstimates) +
                                                                                    Number(actionItemDetails.devRework) +
                                                                                    Number(actionItemDetails.devBuffer) +
                                                                                    Number(actionItemDetails.qaEstimates) +
                                                                                    Number(actionItemDetails.qaRework) +
                                                                                    Number(actionItemDetails.qaBuffer)
                                                                                )
                                                                                ? "red"
                                                                                : "green"
                                                                                }`}

                                                                            style={{ width: "40px", fontWeight: 'bold' }}
                                                                        >
                                                                            {/* {Number(
                                        actionItemDetails.designLoggedHours ||
                                        0
                                      ) +
                                        Number(
                                          actionItemDetails.devLoggedHours || 0
                                        ) +
                                        Number(
                                          actionItemDetails.qaLoggedHours || 0
                                        ).toFixed(2)} */}

                                                                            {formatCorrectHours((Number(actionItemDetails.designLoggedHours ||
                                                                                0
                                                                            ) +
                                                                                Number(actionItemDetails.devLoggedHours || 0
                                                                                ) +
                                                                                Number(actionItemDetails.qaLoggedHours || 0
                                                                                )).toFixed(2).replace(/\.?0+$/, ""))}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <ErrorField
                                                        show={
                                                            error.designEstimates ||
                                                            error.devEstimates ||
                                                            error.qaEstimates
                                                        }
                                                        message={error.designEstimates ? AddEditActionItemErrorMsg.DesignEstimate : error.devEstimates ? AddEditActionItemErrorMsg.DevEstimate : error.qaEstimates ? AddEditActionItemErrorMsg.QaEstimate : AddEditActionItemErrorMsg.EstimatedHrs}
                                                    />


                                                    {role === ROLE_TYPE.ADMIN && (<div className="">
                                                        <div className="edit-task-accordion">
                                                            <div className="accordion" id="accordionExample4">
                                                                <div className="accordion-item"
                                                                    style={{ marginTop: actionItemIds !== null && actionItemIds !== undefined ? "10px" : "10px" }}
                                                                >
                                                                    <h2
                                                                        className="accordion-header"
                                                                        id="headingFour"
                                                                    >
                                                                        <button
                                                                            className="accordion-button"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#collapseFour"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapseFour"
                                                                        >
                                                                            Estimation
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id="collapseFour"
                                                                        className="accordion-collapse collapse show"
                                                                        aria-labelledby="headingFour"
                                                                        data-bs-parent="#accordionExample4"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div>
                                                                                <div style={{ display: 'flex' }} >
                                                                                    <div className="col-12 col-md-6 col-lg-6 " style={{ paddingLeft: '0px' }}>
                                                                                        <label>Estimation Area </label>
                                                                                        <Select
                                                                                            inputId="categoryDrpDwnTask"
                                                                                            placeholder="Please Select"
                                                                                            value={actionItemDetails?.areadrpdwn?.label != "" ? actionItemDetails?.areadrpdwn : null}

                                                                                            //options={categoryList}
                                                                                            options={ESTIMATION_AREA_OPTIONLIST}
                                                                                            onChange={(e) =>
                                                                                                HandleDropdownChange(e, "areadrpdwn")
                                                                                            }

                                                                                            isClearable={(actionItemDetails.areadrpdwn !== undefined && actionItemDetails.areadrpdwn.value != null) || (!actionItemDetails.areadrpdwn === null && actionItemDetails?.areadrpdwn?.value > 0)}
                                                                                        //    isClearable={actionItemDetails?.categoryDrpDwn !== undefined && (!actionItemDetails?.categoryDrpDwn === null || actionItemDetails?.categoryDrpDwn.value > 0 || actionItemDetails?.categoryDrpDwn.value !== 0)}
                                                                                        //    isClearable={
                                                                                        //     actionItemDetails?.categoryDrpDwnTask?.value !== null  // Only clearable when "All" is not selected
                                                                                        //   }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-12 col-md-6 col-lg-6   ">
                                                                                        <label>Estimated Work Item</label>
                                                                                        <Select
                                                                                            inputId="workItemdrpdwn"
                                                                                            placeholder="Please Select"
                                                                                            value={actionItemDetails?.workItemdrpdwn?.value > 0 ? actionItemDetails.workItemdrpdwn : null}
                                                                                            isDisabled={!actionItemDetails?.areadrpdwn?.value > 0}
                                                                                            options={estimatedItemOptions}
                                                                                            // options={[
                                                                                            //     { label: 'All', value: null },  
                                                                                            //     ...categoryList               
                                                                                            //   ]}
                                                                                            onChange={(e) =>
                                                                                                HandleDropdownChange(e, "workItemdrpdwn")
                                                                                            }

                                                                                            isClearable={actionItemDetails.workItemdrpdwn !== undefined || (!actionItemDetails.workItemdrpdwn === null && actionItemDetails?.workItemdrpdwn?.value > 0)}
                                                                                        //    isClearable={actionItemDetails?.categoryDrpDwn !== undefined && (!actionItemDetails?.categoryDrpDwn === null || actionItemDetails?.categoryDrpDwn.value > 0 || actionItemDetails?.categoryDrpDwn.value !== 0)}
                                                                                        //    isClearable={
                                                                                        //     actionItemDetails?.categoryDrpDwnTask?.value !== null  // Only clearable when "All" is not selected
                                                                                        //   }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: 'flex' }} >
                                                                                    <div className="col-12 col-md-6 col-lg-6 mt-2  " style={{ paddingLeft: '0px' }}>
                                                                                        <label>Number of Controls </label>
                                                                                        <input
                                                                                            style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "100%", height: "35px", borderRadius: "0.25rem" }}
                                                                                            type="text"
                                                                                            id="control"
                                                                                            value={actionItemDetails.noOfControls === null ? 0 : actionItemDetails.noOfControls}
                                                                                            //  maxLength={MaxCharacterCount.EstDescription}
                                                                                            //    onChange={(e) =>
                                                                                            //      handleInputChange(index, "description", e.target.value,item.estimationDataId)
                                                                                            //    }
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-12 col-md-6 col-lg-6 mt-2   ">
                                                                                        <label>Number of Pages</label>
                                                                                        <input
                                                                                            style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "100%", height: "35px", borderRadius: "0.25rem" }}
                                                                                            type="text"
                                                                                            id="noOfPages"
                                                                                            value={actionItemDetails.noOfPages === null ? 0 : actionItemDetails.noOfPages}
                                                                                            //  maxLength={MaxCharacterCount.EstDescription}
                                                                                            onChange={HandleFieldChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mt-2">
                                                                                    <div className="brief-info-sec-estimation">
                                                                                        <label>Default Estimate (Hrs): </label>
                                                                                        <span>
                                                                                            {
                                                                                                ((estimatedItemOptions.find((item) => item.value === actionItemDetails.workItemdrpdwn?.value)?.tShirtSizeEstTime || 0)
                                                                                                    * (actionItemDetails.noOfControls) * (actionItemDetails.noOfPages) || 0)
                                                                                            }
                                                                                        </span>

                                                                                    </div>
                                                                                    <div className="brief-info-sec-estimation">
                                                                                        <label>Default Estimate (Days): </label>
                                                                                        <span>
                                                                                            {
                                                                                                (((estimatedItemOptions.find((item) => item.value === actionItemDetails.workItemdrpdwn?.value)?.tShirtSizeEstTime || 0)
                                                                                                    * (actionItemDetails.noOfControls) * (actionItemDetails.noOfPages)) / 8 || 0)
                                                                                            }
                                                                                        </span>
                                                                                    </div>

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)}

                                                </div>
                                                <div className="col-12 col-md-12 col-lg-8 mt-3 ">
                                                    <div
                                                        onDrop={(e) => {
                                                            e.preventDefault();
                                                            const files = e.dataTransfer.files;

                                                            handleDroppedFiles(files);

                                                            markDirty();
                                                            // AddLocalStorageData("isDirty", true);
                                                        }}
                                                        onDragOver={(e) => {
                                                            e.preventDefault();
                                                            setDescriptionDragged(true);
                                                        }}
                                                        // onContextMenu={handleContextMenu}
                                                        style={{ position: 'relative' }}
                                                    >
                                                        {actionItemIds != undefined &&
                                                            actionItemDetails.fileLinkList &&
                                                            actionItemDetails.videoLinkList ? (
                                                            <MultipleFileUpload
                                                                key={fileUploadComponentKey}
                                                                DontShowInComment={true}
                                                                load={reload}
                                                                getFileList={getUploadedFiles}
                                                                getvideoLinkList={GetvideoLinkList}
                                                                fileList={actionItemDetails.fileLinkList}
                                                                videoLinkList={actionItemDetails.videoLinkList}
                                                                type={"Edit"}
                                                                maxFileSizeLimit={SetMaxError}
                                                                files={files}
                                                                showFilesFrom={""}
                                                                handleDroppedFiles={handleDroppedFiles}
                                                                //  handlePasteFiles={handleContextMenu}
                                                                handleEdit={() => actionItemIds !== undefined && actionItemIds !== null && setNeedToHandleEdit(true)}
                                                                handlePasteFiles={() => handlePaste()}
                                                            />
                                                        ) : (
                                                            <MultipleFileUpload
                                                                DontShowInComment={true}
                                                                getFileList={GetUploadedFiles}
                                                                getvideoLinkList={GetvideoLinkList}
                                                                fileList={actionItemDetails.fileLinkList}
                                                                videoLinkList={actionItemDetails.videoLinkList}
                                                                // videoLinkList={[]}
                                                                // fileList={[]}
                                                                type={"Create"}
                                                                showFilesFrom={""}
                                                                clearAttachments={clearAttachments} // Pass the clearAttachments state
                                                                maxFileSizeLimit={SetMaxError}
                                                                files={files}
                                                                // handlePasteFiles={handleContextMenu}
                                                                handleEdit={() => setNeedToHandleEdit(false)}
                                                                handlePasteFiles={() => handlePaste()}
                                                            />
                                                        )}
                                                    </div>
                                                    {contextMenuVisible && (
                                                        <ul
                                                            ref={contextMenuRef}
                                                            style={{
                                                                position: 'absolute',
                                                                top: contextMenuPosition.top,
                                                                left: contextMenuPosition.left,
                                                                listStyle: 'none',
                                                                padding: '10px',
                                                                backgroundColor: 'white',
                                                                border: '1px solid #ccc',
                                                                //zIndex: 1000,
                                                            }}
                                                        >
                                                            <button onClick={handlePasteClick} style={{ cursor: 'pointer' }}>
                                                                Click to paste copied image
                                                            </button>
                                                        </ul>
                                                    )}
                                                </div>


                                                <div className="col-12 col-md-12 col-lg-4 mt-3">
                                                    {actionItemIds ? (
                                                        <>
                                                            {actionItemDetails?.typeDrpDwn?.label === "Bug" ? <div>
                                                                <label>Root Cause</label>{" "}<i style={{ cursor: 'pointer' }} onClick={() => setShowDefinition(true)} className="fa fa-info-circle" aria-hidden="true"></i>
                                                                {roleId == TEAM_TYPE.DEV
                                                                    //&& actionItemDetails.typeDrpDwn !== undefined && actionItemDetails.typeDrpDwn.label === "Bug"
                                                                    //&& (rootCauseTypeId === null || rootCauseTypeId === 0) 
                                                                    && actionItemDetails.statusDrpDwn !== undefined && actionItemDetails.statusDrpDwn.label !== "To Do"
                                                                    // && actionItemDetails.statusDrpDwn.label !== "In Progress"
                                                                    && <span className="mandatory-field"> {" "}*</span>}
                                                                <Select
                                                                    inputId="RootCausetypeDrpDwn"
                                                                    placeholder="Please Select"
                                                                    value={selectedCauseType}
                                                                    options={causeTypes}
                                                                    isDisabled={roleId != TEAM_TYPE.DEV}
                                                                    onChange={(e) => HandleRootDropdownChange(e)}
                                                                    formatOptionLabel={(p) => (
                                                                        <div>
                                                                            {p.imagePath != null &&
                                                                                p.imagePath != undefined && (
                                                                                    <img
                                                                                        width={"20px"}
                                                                                        height={"20px"}
                                                                                        src={p?.imagePath}
                                                                                        alt=""
                                                                                        style={{ marginRight: "3px" }}
                                                                                    />
                                                                                )}
                                                                            <span>{p.label}</span>
                                                                        </div>
                                                                    )}
                                                                    isClearable={selectedCauseType !== undefined || (!selectedCauseType === null && selectedCauseType.value > 0)}
                                                                />
                                                                <ErrorField
                                                                    show={error.rootCause}
                                                                    message={AddEditActionItemErrorMsg.Rootcause}
                                                                />


                                                            </div> : actionItemDetails?.typeDrpDwn?.label === "Code Review" ? <div style={{ border: '1px solid rgb(181, 181, 181)', borderRadius: '3px', padding: '4px', width: 'fit-content', alignContent: 'center' }}><label style={{ marginBottom: '0px' }}>Total Number of Issues Fixed:{" "}{actionItemDetails.issuesFixed}</label></div> : <div style={{ height: '68px' }}></div>}
                                                            <div className="brief-info-sec">
                                                                <label>Created: </label>
                                                                <span>
                                                                    {actionItemDetails.createdOn !== "" &&
                                                                        actionItemDetails.createdOn !== null &&
                                                                        actionItemDetails.createdOn !== undefined &&
                                                                        // formatDate(actionItemDetails.createdOn)}
                                                                        actionItemDetails.createdOn}
                                                                </span>
                                                            </div>
                                                            <div className="brief-info-sec">
                                                                <label>Updated: </label>
                                                                <span>
                                                                    {actionItemDetails.editedOn !== "" &&
                                                                        actionItemDetails.editedOn !== null &&
                                                                        actionItemDetails.editedOn !== undefined &&
                                                                        // formatDate(actionItemDetails.editedOn)}
                                                                        actionItemDetails.editedOn}
                                                                </span>
                                                            </div>
                                                            <div className="brief-info-sec">
                                                                <label>Reporter: </label>
                                                                <span>
                                                                    {actionItemDetails.reporterFullName}
                                                                </span>
                                                            </div>
                                                            <div className="brief-info-sec">
                                                                <label>#Fail Retest: </label>
                                                                <span>
                                                                    {actionItemDetails.testCount}
                                                                </span>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div style={{ height: "180px" }}></div>
                                                    )}
                                         {(!actionItemIds || actionItemDetails.isDefault !=true )&&(           
                                         <div className="mt-2">
                                                        <div className="edit-task-accordion">
                                                            <div className="accordion" id="accordionExample">
                                                                <div className="accordion-item" style={{ marginTop: actionItemIds !== null && actionItemIds !== undefined && actionItemDetails?.typeDrpDwn?.label === "Bug" ? "45px" : actionItemIds !== null && actionItemIds !== undefined && actionItemDetails?.typeDrpDwn?.label !== "Bug" ? "35px" : "28px" }}>
                                                                    <h2
                                                                        className="accordion-header"
                                                                        id="headingTwo"
                                                                    >
                                                                        <button
                                                                            className="accordion-button"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#collapseTwo"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapseTwo"
                                                                        >
                                                                            Link Task
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id="collapseTwo"
                                                                        className="accordion-collapse collapse show"
                                                                        aria-labelledby="headingTwo"
                                                                        data-bs-parent="#accordionExample"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div>
                                                                            <div>
                                                                                    <Select
                                                                                        inputId="relationshipDrpDwn"
                                                                                        placeholder="Work Item Relationship"
                                                                                        value={!actionItemIds && actionItemDetails?.relationshipDrpDwn?.label != "" ? actionItemDetails.relationshipDrpDwn:actionItemIds ? actionItemDetails.relationshipDrpDwn : null}

                                                                                        //options={categoryList}
                                                                                        options={(actionItemDetails?.linkTaskActionItem?.parent?.actionItemId>0 ||
                                                                                            linkedItemsArray.some(item => item.relationshipValue === 1)
                                                                                        )?relationshipList.filter((item)=>item.value !=1) :relationshipList}
                                                                                        onChange={(e) =>
                                                                                            HandleDropdownChange(e, "relationshipDrpDwn")
                                                                                        }

                                                                                        //isClearable={actionItemDetails.categoryDrpDwn !== undefined || (!actionItemDetails.categoryDrpDwn === null && actionItemDetails?.categoryDrpDwn?.value > 0)}
                                                                                        //    isClearable={actionItemDetails?.categoryDrpDwn !== undefined && (!actionItemDetails?.categoryDrpDwn === null || actionItemDetails?.categoryDrpDwn.value > 0 || actionItemDetails?.categoryDrpDwn.value !== 0)}
                                                                                        isClearable={
                                                                                            actionItemDetails?.categoryDrpDwnTask?.value !== null  // Only clearable when "All" is not selected
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div style={{ marginTop: 8 }}>
                                                                                    <Select
                                                                                        inputId="categoryDrpDwnTask"
                                                                                        placeholder="Project Category"
                                                                                        value={actionItemDetails?.categoryDrpDwnTask?.label != "" ? actionItemDetails.categoryDrpDwnTask : null}

                                                                                        //options={categoryList}
                                                                                        options={[
                                                                                            { label: 'All', value: null },
                                                                                            ...categoryList
                                                                                        ]}
                                                                                        onChange={(e) =>
                                                                                            HandleDropdownChange(e, "categoryDrpDwnTask")
                                                                                        }

                                                                                        //isClearable={actionItemDetails.categoryDrpDwn !== undefined || (!actionItemDetails.categoryDrpDwn === null && actionItemDetails?.categoryDrpDwn?.value > 0)}
                                                                                        //    isClearable={actionItemDetails?.categoryDrpDwn !== undefined && (!actionItemDetails?.categoryDrpDwn === null || actionItemDetails?.categoryDrpDwn.value > 0 || actionItemDetails?.categoryDrpDwn.value !== 0)}
                                                                                        isClearable={
                                                                                            actionItemDetails?.categoryDrpDwnTask?.value !== null  // Only clearable when "All" is not selected
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div style={{ marginTop: 8 }}>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        inputId="selectedLinkedAI"
                                                                                        placeholder="Link Work Item"
                                                                                        value={
                                                                                            actionItemDetails.selectedLinkedAI.value>0??null
                                                                                        }
                                                                                        isDisabled={actionItemDetails.relationshipDrpDwn==="" ||!actionItemDetails.relationshipDrpDwn }
                                                                                        
                                                                                        options={
                                                                                            actionItemDetails?.categoryDrpDwnTask?.value
                                                                                                ? actionItemList
                                                                                                    .filter((a) => a.projectCategoryId === actionItemDetails?.categoryDrpDwnTask.value
                                                                                                    && a.isDefault !=true 
                                                                                                    && a.actionItemId !=actionItemDetails?.linkTaskActionItem?.parent?.actionItemId 
                                                                                                    &&
                                                                                                    !actionItemDetails.linkTaskActionItem?.children?.some(child => child.actionItemId === a.actionItemId) && 
                                                                                                    !actionItemDetails.linkTaskActionItem?.associatedWorkItem?.some(item => item.actionItemId === a.actionItemId                     
                                                                                                    ) 
                                                                                                    && !linkedItemsArray.some(link=>link.linkedAI=== a.actionItemId )
                                                                                                )
                                                                                                    .map((lead) => ({
                                                                                                        value: lead.actionItemId,
                                                                                                        label: lead.key + ":" + lead.title,
                                                                                                    }))
                                                                                                    .sort(customSort) 
                                                                                                : actionItemList .filter((a) => 
                                                                                                    a.isDefault !=true && a.actionItemId !=actionItemDetails?.linkTaskActionItem?.parent?.actionItemId&&
                                                                                                !actionItemDetails.linkTaskActionItem?.children?.some(child => child.actionItemId === a.actionItemId) && 
                                                                                                !actionItemDetails.linkTaskActionItem?.associatedWorkItem?.some(item => item.actionItemId === a.actionItemId) 
                                                                                                && !linkedItemsArray.some(link=>link.linkedAI=== a.actionItemId )
                                                                                            )
                                                                                                .map((lead) => ({
                                                                                                        value: lead.actionItemId,
                                                                                                        label: lead.key + ":" + lead.title,
                                                                                                    }))
                                                                                                    .sort(customSort) 
                                                                                        }
                                                                                        isSearchable={true}
                                                                                        filterOption={customFilter} // Apply custom filtering
                                                                                        isClearable={!actionItemDetails.selectedLinkedAI === null || actionItemDetails?.selectedLinkedAI?.value > 0}
                                                                                        onChange={(e) => {
                                                                                            HandleDropdownChange(
                                                                                                e,
                                                                                                "selectedLinkedAI"
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="mt-2">
                                      <label>Sub Task</label>
                                      <div>
                                        <a className="sub-task-link">
                                          https://edotpulse.com/165?ProjectId=1
                                        </a>
                                      </div>
                                    </div> */}
                                                                            {/* <div className="mt-2">
                                                                                <label>Sub Task</label>
                                                                                <div>
                                                                                    {actionItemDetails.subTaskItems
                                                                                        .length > 0
                                                                                        ? actionItemDetails.subTaskItems.map(
                                                                                            (subTask) => (
                                                                                                <a
                                                                                                    style={{
                                                                                                        marginRight: "6px",
                                                                                                    }}
                                                                                                    className="sub-task-link"
                                                                                                    key={subTask.key}
                                                                                                    target="_blank"
                                                                                                    rel="noreferrer"

                                                                                                    href={
                                                                                                        "/EditActionItem/" +
                                                                                                        subTask.key
                                                                                                    }
                                                                                                    onClick={(e) =>
                                                                                                        NavigateToEditActionItem(
                                                                                                            subTask,
                                                                                                            subTask.actionItemID
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    {`${subTask.key} - ${subTask.title}`}<br />
                                                                                                </a>
                                                                                            )
                                                                                        )
                                                                                        : "No Sub-Tasks"}
                                                                                </div>
                                                                            </div> */}
                                                                            <div className="mt-2">
                                                                            <div>
  Parent:
  <div className="linked-item" style={{ display: 'flex', alignItems: 'center' }}>
    {actionItemIds?(  actionItemDetails.linkTaskActionItem?.parent && (
      <>
        
        {ActionItemTypes.find(type => type.value === actionItemDetails.linkTaskActionItem?.parent?.actionItemType) && (
          <img
            src={ActionItemTypes.find(type => type.value === actionItemDetails.linkTaskActionItem?.parent.actionItemType)?.imagePath}
            alt={ActionItemTypes.find(type => type.value === actionItemDetails.linkTaskActionItem?.parent.actionItemType)?.label}
            style={{ marginRight: '6px', width: '16px', height: '16px' }}
          />
        )}
        <a
          style={{ marginRight: "6px" }}
          className="sub-task-link"
          key={actionItemDetails.linkTaskActionItem?.parent?.key}
          title={actionItemDetails.linkTaskActionItem?.parent?.title}
          target="_blank"
          rel="noreferrer"
          href={"/EditActionItem/" + actionItemDetails.linkTaskActionItem?.parent.key}
        >
          {`${actionItemDetails.linkTaskActionItem?.parent?.key} - ${actionItemDetails.linkTaskActionItem?.parent?.title}`}<br />
        </a>
        <button className=" delete-linked-item" type="button" style={{ margin: 0,   }}>
                            <img style={{ width: '16px' }}
                              title="Delete"
                             onClick={(e) => DeleteLinkedItem(actionItemDetails.linkTaskActionItem?.parent?.linkedWorkItemBridgeId,"parent",0, e)}
                              src="../Assets/images/cross-icon.png"
                              alt=""
                            ></img>
                          </button>
      </>
    )): (
        /* If actionItemIds is not defined, map over linkedItemsArray */
        linkedItemsArray &&
        linkedItemsArray
          .filter((item) => item.relationshipValue === 1)
          .map((item,index) => (
            <div key={item.value} style={{ display: 'flex', alignItems: 'center' }}>
              <a
                style={{ marginRight: "6px" }}
                className="sub-task-link"
                href={"/EditActionItem/" + item.key}
                key={item.value}
                title={item.label}
                target="_blank"
                rel="noreferrer"
              >
                {`${item.label}`}<br />
              </a>
              <button className="delete-linked-item" type="button" style={{ margin: 0 }}>
                <img
                  style={{ width: '16px' }}
                  title="Delete"
                  onClick={(e) => DeleteLinkedItem(item.value, "parent", index, e)}  
                  src="../Assets/images/cross-icon.png"
                  alt=""
                />
              </button>
            </div>
          ))
      )}
  </div>
</div>

                                                                                <div>Child:
                                                                                {actionItemIds? actionItemDetails.linkTaskActionItem?.children?.map((item,index)=>{
                                                                                const actionItemType = ActionItemTypes.find(type => type.value === item.actionItemType);
                                                                              
                                                                                return (
                                                                                  <div className="linked-item" key={item.key} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                                                    {actionItemType && (
                                                                                      <img 
                                                                                        src={actionItemType.imagePath} 
                                                                                        alt={actionItemType.label} 
                                                                                        style={{ marginRight: '6px', width: '16px', height: '16px' }} 
                                                                                      />
                                                                                    )}
                                                                                    <a
                                                                                      style={{
                                                                                        marginRight: "6px",
                                                                                      }}
                                                                                      className="sub-task-link"
                                                                                      target="_blank"
                                                                                      title={item.title}
                                                                                      rel="noreferrer"
                                                                                      href={"/EditActionItem/" + item.key}
                                                                                    >
                                                                                      {/* Display the key and title */}
                                                                                      {`${item.key} - ${item.title}`}
                                                                                      <br />
                                                                                    </a>
                                                                                    <button className=" delete-linked-item" type="button" style={{ margin: 0,   }}>
                                                                                    <img style={{ width: '16px' }}
                                                                                      title="Delete"
                                                                                     onClick={(e) => DeleteLinkedItem(item.linkedWorkItemBridgeId,"children",index, e)}
                                                                                      src="../Assets/images/cross-icon.png"
                                                                                      alt=""
                                                                                    ></img>
                                                                                   </button>
                                                                                  </div>
                                                                                );
                                                                              }):(
                                                                                linkedItemsArray &&
                                                                                linkedItemsArray
                                                                                  .filter((item) => item.relationshipValue === 2)
                                                                                  .map((item,index) => (
                                                                                    <div className="linked-item" key={item.value} style={{ display: 'flex', alignItems: 'center' }}>
                                                                                      <a
                                                                                        style={{ marginRight: "6px" }}
                                                                                        href={"/EditActionItem/" + item.key}
                                                                                        className="sub-task-link"
                                                                                        key={item.value}
                                                                                        title={item.label}
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                      >
                                                                                        {`${item.label}`}<br />
                                                                                      </a>
                                                                                      <button className="delete-linked-item" type="button" style={{ margin: 0 }}>
                                                                                        <img
                                                                                          style={{ width: '16px' }}
                                                                                          title="Delete"
                                                                                          onClick={(e) => DeleteLinkedItem(item.value, "parent", index, e)}  
                                                                                          src="../Assets/images/cross-icon.png"
                                                                                          alt=""
                                                                                        />
                                                                                      </button>
                                                                                    </div>
                                                                                  ))
                                                                              )} 
                                                                                </div>
                                                                                <div >Associated Work Item:
                                                                                {actionItemIds? actionItemDetails.linkTaskActionItem?.associatedWorkItem?.map((item,index) => {
  // Find the action type object based on the item's actionItemType value
  const actionItemType = ActionItemTypes.find(type => type.value === item.actionItemType);

  return (
    <div className="linked-item" key={item.key} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      
      {actionItemType && (
        <img 
          src={actionItemType.imagePath} 
          alt={actionItemType.label} 
          style={{ marginRight: '6px', width: '16px', height: '16px' }} 
        />
      )}
      <a
        style={{
          marginRight: "6px",
        }}
        className="sub-task-link"
        title={item.title}
        target="_blank"
        rel="noreferrer"
        href={"/EditActionItem/" + item.key}
      >
        
        {`${item.key} - ${item.title}`}
        <br />
      </a>
      <button className="delete-linked-item" type="button" style={{ margin: 0,   }}>
                            <img style={{ width: '16px' }}
                              title="Delete"
                             onClick={(e) => DeleteLinkedItem(item.linkedWorkItemBridgeId,"associatedWorkItem",index, e)}
                              src="../Assets/images/cross-icon.png"
                              alt=""
                            ></img>
                          </button>
    </div>
  );
})    :(
    linkedItemsArray &&
                                                                                linkedItemsArray
                                                                                  .filter((item) => item.relationshipValue === 3)
                                                                                  .map((item,index) => (
                                                                                    <div className="linked-item" key={item.value} style={{ display: 'flex', alignItems: 'center' }}>
                                                                                      <a
                                                                                        style={{ marginRight: "6px" }}
                                                                                        className="sub-task-link"
                                                                                        key={item.value}
                                                                                        title={item.label}
                                                                                         href={"/EditActionItem/" + item.key}
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                      >
                                                                                        {`${item.label}`}<br />
                                                                                      </a>
                                                                                      <button className="delete-linked-item" type="button" style={{ margin: 0 }}>
                                                                                        <img
                                                                                          style={{ width: '16px' }}
                                                                                          title="Delete"
                                                                                          onClick={(e) => DeleteLinkedItem(item.value, "parent", index, e)}  
                                                                                          src="../Assets/images/cross-icon.png"
                                                                                          alt=""
                                                                                        />
                                                                                      </button>
                                                                                    </div>
                                                                                  ))
)                                                                         }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-6 col-md-4 col-lg-4 mt-3">
                                                        <div className="comment-btn-sec">

                                                            {actionItemIds === undefined &&
                                                                <div className="row ">
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 select-task-col ">
                                                                        <form>
                                                                            <div style={{ display: 'flex', justifyContent: 'start' }}>    <button
                                                                                className="File_upload"
                                                                                type="button"
                                                                                id="commentButton"
                                                                                title="Comment"
                                                                                onClick={() => ShowComment()}
                                                                            >
                                                                                <img
                                                                                    src="../Assets/images/Comment_icon.png"
                                                                                    width={20}
                                                                                />{" "}
                                                                                Comment<span className="mandatory-field">{" "}*</span>
                                                                            </button></div>
                                                                    
                                                                        </form>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>

                                                </div> */}
                                                {/* {actionItemIds && ( */}
                                                {/* {((newWorkLogarray !== null && newWorkLogarray.length > 0) || historyData.length > 0) && ( */}
                                                {((actionItemDetails.workLogDetails !== null && actionItemDetails.workLogDetails.length > 0) || historyData.length > 0) && (
                                                    <div className="col-12 col-md-12 col-lg-12 mt-3">
                                                        <div className="edit-task-accordion">
                                                            <div className="accordion" id="accordionExample">
                                                                <div className="accordion-item">
                                                                    <h2
                                                                        className="accordion-header"
                                                                        id="headingThree"
                                                                    >
                                                                        <button
                                                                            className="accordion-button"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target="#collapseThree"
                                                                            aria-expanded="true"
                                                                            aria-controls="collapseThree"
                                                                        >
                                                                            Activity
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id="collapseThree"
                                                                        className="accordion-collapse collapse show"
                                                                        aria-labelledby="headingThree"
                                                                        data-bs-parent="#accordionExample"
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <div>
                                                                                <div className="activity-link-tab">
                                                                                    <button
                                                                                        className={`tablinks comment-tab ${activeTab === "Comments"
                                                                                            ? "Active"
                                                                                            : ""
                                                                                            }`}
                                                                                        style={{ display: actionItemDetails.workLogDetails.length > 0 ? "" : "none" }}
                                                                                        onClick={(event) =>
                                                                                            openContent(event, "Comments")
                                                                                        }
                                                                                        id="defaultOpen"
                                                                                    >
                                                                                        <span>Comments</span>
                                                                                    </button>
                                                                                    <button
                                                                                        className={`tablinks history-tab ${activeTab === "History"
                                                                                            ? "Active"
                                                                                            : ""
                                                                                            }`}
                                                                                        // style={{ display: historyData.length > 0 ? "" : "none" }}
                                                                                        style={{ display: actionItemIds ? "" : "none" }}
                                                                                        onClick={(event) =>
                                                                                            openContent(event, "History")
                                                                                        }
                                                                                    >
                                                                                        <span>History</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                id="Comments"
                                                                                className={`tabcontent ${activeTab === "Comments"
                                                                                    ? "active"
                                                                                    : ""
                                                                                    }`}
                                                                            >
                                                                                {actionItemDetails.workLogDetails.length > 0 && actionItemDetails.workLogDetails?.map(
                                                                                    // {newWorkLogarray?.map(
                                                                                    (x) => (
                                                                                        <div
                                                                                            className="tabcontent-data"
                                                                                            style={{
                                                                                                borderBottom:
                                                                                                    "1px solid #cdcdcd",
                                                                                                marginTop: "20px",
                                                                                            }}
                                                                                            key={x.workLogDetailId}
                                                                                        >
                                                                                            <div className="">
                                                                                                <div className="profile-edit-delete-fbox">
                                                                                                    <div className="profile-name-fbox">
                                                                                                        <div className="">
                                                                                                            <span className="profile-sec">
                                                                                                                <LetteredAvatar
                                                                                                                    editTask={true}
                                                                                                                    name={x.loggedByName}
                                                                                                                    bgcolor={x.color !== undefined ? x.color : bgcolor}
                                                                                                                    profileImageComments={x.loggedByPath !== undefined ? x.loggedByPath : profileImg}
                                                                                                                    isComments={true}
                                                                                                                ></LetteredAvatar>{" "}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <label className="profile-name-sec">
                                                                                                            <span>
                                                                                                                {x.loggedByName}
                                                                                                            </span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    {x.loggedBy ==
                                                                                                        GetLoggedInUserID() && (
                                                                                                            <div>
                                                                                                                {x.workDone !== 99 &&
                                                                                                                    <img
                                                                                                                        title="delete"
                                                                                                                        width="19px"
                                                                                                                        height="23px"
                                                                                                                        className="edit-icons"
                                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                                        src="Assets/images/deleteIcon.png"
                                                                                                                        onClick={() =>
                                                                                                                            handleDelete(
                                                                                                                                x.workLogDetailId
                                                                                                                            )
                                                                                                                        }
                                                                                                                    />}{" "}

                                                                                                                <img
                                                                                                                    width="19px"
                                                                                                                    className="edit-icons"
                                                                                                                    src="../Assets/images/Edit_icon.png"
                                                                                                                    alt=""
                                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                                    onClick={() =>{ setIsCommentEdit(true);
                                                                                                                        setCommentEditData(x);
                                                                                                                        ShowComment(x, true)}
                                                                                                                       
                                                                                                                    }
                                                                                                                    title="Edit"
                                                                                                                />
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                                {x.workLogDetailId > 0 &&
                                                                                                    <div className="date-hours-fbox mt-1" style={{ display: 'flex', justifyContent: "space-between" }}>
                                                                                                        <span className="hours-sec">
                                                                                                            {/* {(x.loggedOn)} -{" "}
                                                    {x.loggedHours}h */}
                                                                                                            {formatDate(x.loggedOn)} - {formatLoggedHours(x.loggedHours)}

                                                                                                        </span>



                                                                                                        {x.editedOn ? <span className="hours-sec">
                                                                                                            Last Edited: {formatDate(x.editedOn)}

                                                                                                        </span> : <span className="hours-sec">

                                                                                                            Last Edited: {formatDate(x.createdOn)}

                                                                                                        </span>}
                                                                                                    </div>
                                                                                                }
                                                                                                <div>
                                                                                                    <div
                                                                                                        className="comment-sec"
                                                                                                        style={{
                                                                                                            wordBreak: "break-word",
                                                                                                        }}
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: x.comments,
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                            <div
                                                                                id="History"
                                                                                className={`tabcontent ${activeTab === "History"
                                                                                    ? "active"
                                                                                    : ""
                                                                                    }`}
                                                                            >

                                                                                <div className="tabcontent-data">

                                                                                    {historyData.length > 0 &&
                                                                                        historyData.map(
                                                                                            (historyRecord, index) => {
                                                                                                const editedByIndex =
                                                                                                    historyRecord.fields.indexOf(
                                                                                                        "Edited By"
                                                                                                    );

                                                                                                const editedByValue =
                                                                                                    editedByIndex !== -1
                                                                                                        ? historyRecord.newValues[
                                                                                                        editedByIndex
                                                                                                        ]
                                                                                                        : "";

                                                                                                // Only render the `spaced-border` div if fields.length > 1
                                                                                                if (
                                                                                                    historyRecord.fields.length >
                                                                                                    0
                                                                                                ) {
                                                                                                    return (
                                                                                                        <div
                                                                                                            key={index}
                                                                                                            className="spaced-border"
                                                                                                        >
                                                                                                            <div className="history-profile-name-fbox">

                                                                                                                <div className="profile-sec">
                                                                                                                    <span className="profile-initials">
                                                                                                                        {/* {getInitials(historyRecord.editedBy[0])} */}
                                                                                                                        <LetteredAvatar name={historyRecord.editedBy[0]} bgcolor={historyRecord.color} profileImageComments={historyRecord.editedByImagePath} commentsHistory={true} isComments={false} />
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className="profile-name-sec">
                                                                                                                    <span>{historyRecord.editedBy}{" "}</span>
                                                                                                                    <span className="update-txt">
                                                                                                                        made changes - {historyRecord.editedDates[0]}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="custom-history-grid-sec mt-2">
                                                                                                                <table className="custom-grid">
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th style={{ textAlign: 'center' }}>Field</th>
                                                                                                                            <th style={{ textAlign: 'center' }}>Original Value</th>
                                                                                                                            <th style={{ textAlign: 'center' }}>New Value</th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {historyRecord.fields.map((field, idx) => (
                                                                                                                            <tr key={idx}>
                                                                                                                                <td>{field}</td>
                                                                                                                                {/* <td >{historyRecord.originalValues[idx]}</td> */}
                                                                                                                                {/* <td >{historyRecord.newValues[idx]}</td> */}
                                                                                                                                <td>
                                                                                                                                    {isNaN(Number(historyRecord.originalValues[idx]))
                                                                                                                                        ? historyRecord.originalValues[idx]
                                                                                                                                        : Number(historyRecord.originalValues[idx]).toFixed(2).replace(/\.?0+$/, "")}
                                                                                                                                </td>

                                                                                                                                {/* Check if the new value can be parsed as a number */}
                                                                                                                                <td>
                                                                                                                                    {isNaN(Number(historyRecord.newValues[idx]))
                                                                                                                                        ? historyRecord.newValues[idx]
                                                                                                                                        : Number(historyRecord.newValues[idx]).toFixed(2).replace(/\.?0+$/, "")}
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        ))}
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );
                                                                                                } else {
                                                                                                    return null;
                                                                                                }
                                                                                            }
                                                                                        )}




                                                                                    {historyData.length > 0 && (
                                                                                        <div className="history-profile-name-fbox spaced-border">
                                                                                            <div className="profile-sec">
                                                                                                {/* <span className="profile-initials">
                                                  {getInitials(createdBy)}
                                                </span> */}
                                                                                                <span className="profile-initials">
                                                                                                    {/* {getInitials(historyRecord.editedBy[0])} */}
                                                                                                    <LetteredAvatar name={createdBy} bgcolor={color} profileImageComments={profilePath} commentsHistory={true} isComments={false} />
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className="profile-name-sec">
                                                                                                <span>{createdBy} </span>
                                                                                                <span className="update-txt">
                                                                                                    {/* created issue -{Dateformat(createdOn)} */}
                                                                                                    created issue - {" "}{createdOn}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* <ErrorField
                                                    show={error.commentEmpty}
                                                    message={AddEditActionItemErrorMsg.Comments}
                                                /> */}


                                                <ErrorField
                                                    show={error.showInitialCommentAddError}
                                                    message={AddEditActionItemErrorMsg.NewComments}
                                                />

                                                {/* <ErrorField
                                                    show={error.commetsError}
                                                    message={AddEditActionItemErrorMsg.commentErroOnStatusChange}
                                                /> */}


                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                            >
                                                {" "}
                                                {/* <ErrorField

                                                    show={
                                                        error.assigneeId ||
                                                        error.description ||
                                                        error.designEstimates ||
                                                        error.devEstimates ||
                                                        error.qaEstimates ||
                                                        error.priorityId ||
                                                        error.statusId ||
                                                        error.title ||
                                                        (!actionItemDetails.isMisc && error.typeId) ||
                                                        error.commentEmpty ||
                                                        error.dueDateEmpty ||
                                                        error.dueDateInvalid ||
                                                        error.categoryId || error.rootCause
                                                    }
                                                    message={AddEditActionItemErrorMsg.ValidateAllFields}
                                                /> */}

                                            </div>

                                            <div className="row">

                                                <div className="col-12 col-md-12 col-lg-12 mt-3">
                                                    <div className="save-cancel-sec floating-btn-sec">
                                                        {actionItemIds === undefined ? (
                                                            <button
                                                                id="btnCreateAnother"
                                                                name="btncreateAnother"
                                                                className="save-btn"
                                                                onClick={OnSave}
                                                                disabled={isProcessing} // Disable when processing
                                                            >
                                                                <span>Add another item</span>
                                                            </button>
                                                        ) : <button
                                                            id="btnCreateAnother"
                                                            name="btncreateAnother"
                                                            className="save-btn"
                                                            onClick={handleAddAnother}
                                                            disabled={isProcessing} // Disable when processing
                                                        >
                                                            <span>Add Work Item</span>
                                                        </button>}
                                                        <button
                                                            className="save-btn"
                                                            disabled={isProcessing}
                                                            // onClick={OnSave}
                                                            // onClick={actionItemIds !== undefined && actionItemIds !== null ? ShowComment : ShowComment}
                                                            onClick={() => {
                                                                if (actionItemIds !== undefined && actionItemIds !== null) {
                                                                    ShowComment();
                                                                } else {
                                                                    if (!ValidateForm()) {
                                                                        ShowComment();
                                                                    }
                                                                }
                                                            }}
                                                            style={{ width: "70px !important", textAlign: "center", textAlignLast: "center" }}
                                                        >
                                                            {/* {actionItemIds !== undefined && actionItemIds !== null ? "Comment & Save" : "Save"} */}
                                                            Comment & Save
                                                        </button>
                                                        <button className="cancel-btn custom-width" onClick={OnCancel}>Cancel</button>
                                                        {/* onClick={OnCancel} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>


                                <br />
                            </div>
                        </div>
                    </div>
                </section >

                {showCommentModal && (
                    <AddEditComments
                    isCommentEdit={isCommentEdit}
                    previousCommentData={previousCommentData}
                        isOpen={showCommentModal}
                        title={actionItemDetails.title}
                        setShowModal={ShowComment}
                        projectId={projectId}
                        actionItemId={actionItemId}
                        workLogDetail={workLogDetail}
                        workItemType={workItemType}
                        isMainPageSave={actionItemIds !== undefined && actionItemIds !== null ? HandleEdit:null}
                        handleDroppedFiles={handleDroppedFiles}
                        onCommentsSubmit={handleCommentsData}
                    />
                )
                }
                {
                    showDeleteModal && (
                        <DeleteModal
                            showDeleteModal={showDeleteModal}
                            onYesClick={showOnSaveModal ? showOnSaveClicked : deleteComment}
                            onNoClick={() => {
                                showOnSaveModal ? setShowDeleteModal(false) :
                                    setWorkLogDetailIdToDelete(null); setShowDeleteModal(false);
                            }}
                            textToDisplay={showOnSaveModal ? "Please enter or review your comments to log Work Done" : "Are you sure you want to delete?"}
                            isCommentsAdded={true}
                            />
                    )
                }
            </main >
            <ScrollPage />
        </>
    );
};

export default EditActionItem;
