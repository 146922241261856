import React, { useState, useEffect, useContext, useCallback } from "react";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { Link, useNavigate } from "react-router-dom";
import {
  DefaultAGGridProps,
  HTTPResponse,
  ROLE_TYPE,
  Roles,
  Team,
  ToastMessageType,
} from "../Helpers/Enums";
import UserCommonCell from "../Components/UserCommonCell";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ManageUsersService } from "../WebApiServices/ManageUsers.Service";
import UserStatusCell from "../Components/UserStatusCell";
import {
  AddLocalStorageData,
  GetLocalStorageData,
  RemoveLocalStorageData,
  changePageTitle,
} from "../Helpers/Utilities";
import AGGrid from "../Components/AGGrid";
import { ToastMessages } from "../Helpers/HelperText";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
import DeleteModal from "../Components/DeleteModal";
import { UserRolesPermission } from "../WebApiServices/RolesPermission.service";
import ErrorPage from "./ErrorPage";
import Select from "react-select";

const ManageUsers = () => {
  const navigate = useNavigate();
  const { LogOut } = useUIContext();
  useEffect(() => {
    changePageTitle("Employees");
    RemoveLocalStorageData("key");
    RemoveLocalStorageData("newlySelectedProjects");
  }, []);
  const { ToggleLoader, ShowToastMessage } = useContext(AppContext);
  const [rowData, setRowData] = useState([]);
  const [editUser, setEditUser] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const userId = GetLocalStorageData("userId");
  const loggedInUserId = userId;

  const [showDeleteModalPopUp, setShowDeleteModal] = useState(false);
  const [selectedEmployeeId, setselectedEmployeeId] = useState(0);

  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusChanged, setStatusChanged] = useState(false);
  //SYJ - User Roles Permission
  const [adminPermission, setAdminPermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });

  const [projectLeadPermission, setProjectLeadPermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });

  const [employeePermission, setEmployeePermission] = useState({
    ManageEmp: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageRolesPermission: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    ManageProject: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    TimeLog: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    },
    MyTask: {
      add: 0,
      edit: 0,
      view: 0,
      delete: 0,
      addOther: 0,
      editOther: 0,
      viewOther: 0,
      deleteOther: 0
    }
  });



  const roleId = Number(GetLocalStorageData("roleID"));

  //   const GetAllUser = async () => {
  //       try {
  //           ToggleLoader(true);
  //           const userId = GetLocalStorageData("userId");
  //           const response = await ManageUsersService.GetAllUsers();

  //           const datas = await ManageUsersService.GetProfileData(userId);
  //           if (datas.data && datas.data.length > 0) {
  //               const firstData = datas.data[0];
  //               AddLocalStorageData("imagePath", firstData.userPic);
  //           }

  //           if (response.status === HTTPResponse.OK) {
  //               const loggedInUserId = GetLocalStorageData("userId");
  //               const loggedInUser = response.data.find(user => user.employeeId === loggedInUserId);

  //               const usersWithFullName = response.data.map(user => ({
  //                   ...user,
  //                   fullName: `${user.firstName} ${user.lastName}`,
  //                   roleName: user.roleDropDown.label,
  //                   teamName: user.teamDropDown.label,
  //                   phoneNo: user.phoneNo,
  //                   joiningDate: user.joiningDate,
  //                   terminationDate: user.terminationDate,
  //                   designation: user.designation,
  //                   ProfileImageId: user.ProfileImageId,
  //                   userPic: user.userPic,
  //                   status: user.status
  //               }));

  //               // Store the index of the logged-in user
  //               let loggedInUserIndex = -1;
  //               if (loggedInUser) {
  //                   loggedInUserIndex = usersWithFullName.findIndex(user => user.employeeId === loggedInUserId);
  //               }

  //               // Filter out the logged-in user from the list
  //               const filteredUsers = usersWithFullName.filter(user => user.employeeId !== loggedInUserId);

  //               // Include logged-in user in the list at their original position
  //               if (loggedInUser && loggedInUserIndex !== -1) {
  //                   filteredUsers.splice(loggedInUserIndex, 0, {
  //                       ...loggedInUser,
  //                       fullName: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
  //                       roleName: loggedInUser.roleDropDown.label,
  //                       teamName: loggedInUser.teamDropDown.label,
  //                       phoneNo: loggedInUser.phoneNo,
  //                       joiningDate: loggedInUser.joiningDate,
  //                       terminationDate: loggedInUser.terminationDate,
  //                       designation: loggedInUser.designation,
  //                       ProfileImageId: loggedInUser.ProfileImageId,
  //                       userPic: loggedInUser.userPic,
  //                       status: loggedInUser.status
  //                   });
  //               }
  //               /* <summary>
  //                date: 22-03-2024
  //                Name: Mustakim Shaikh
  //                description:to show employee details of only whose project lead is loggedin.
  //      <summary>*/
  //               if (roleId == ROLE_TYPE.LEAD) {
  //                   const projectsNameAssignedToLoggedInLead = GetLocalStorageData("ProjectsLeading");
  //                   const filteredUsersInLeadProjects = filteredUsers.filter(user =>
  //                       user.projectEmployeeList && user.projectEmployeeList.some(project => projectsNameAssignedToLoggedInLead.includes(project))
  //                   );
  //                   setRowData(filteredUsersInLeadProjects);
  //               } else {
  //                   setRowData(filteredUsers);
  //               }
  //               ToggleLoader(false);
  //           }
  //           if (response.status === HTTPResponse.Unauthorized) {
  //               LogOut();
  //           }
  //       } catch (error) {
  //           ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
  //       }
  //   };

  // const handleStatusChange = () => {
  //   setStatusChanged(true);
  // };

  // useEffect(() => {
  //   GetAllUser();
  //   GetAllRolesPermission();
  //   setStatusChanged(false)
  // }, [statusChanged]);

  const GetAllUser = async () => {
    try {
      ToggleLoader(true);
      const userId = GetLocalStorageData("userId");
      const response = await ManageUsersService.GetAllUsers();

      const datas = await ManageUsersService.GetProfileData(userId);
      if (datas.data && datas.data.length > 0) {
        const firstData = datas.data[0];
        AddLocalStorageData("imagePath", firstData.userPic);
      }

      if (response.status === HTTPResponse.OK) {

        const loggedInUserId = GetLocalStorageData("userId");

        const usersWithFullName = response.data.map(user => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
          roleName: user.roleDropDown.label,
          teamName: user.teamDropDown.label,
          phoneNo: user.phoneNo,
          joiningDate: user.joiningDate,
          terminationDate: user.terminationDate,
          designation: user.designation,
          ProfileImageId: user.ProfileImageId,
          userPic: user.userPic,
          status: user.status,
          projectEmployeeList: user.projectEmployeeList // Added this line if projectEmployeeList is a property of user
        }));

        let filteredUsers = applyFilters(usersWithFullName, loggedInUserId);

        // Set filtered user data
        setRowData(filteredUsers);
        ToggleLoader(false);
      }

      if (response.status === HTTPResponse.Unauthorized) {
        LogOut();
      }
    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  const applyFilters = (users, loggedInUserId) => {
    // Determine if any filters are applied
    const isFilterApplied = selectedRole || selectedTeam || selectedStatus;

    let filteredUsers = users;

    if (selectedRole !== null && selectedRole.value !== 0) {
      filteredUsers = filteredUsers.filter(user => user.roleDropDown.value === selectedRole.value);
    }
    if (selectedTeam !== null && selectedTeam.value !== 0) {
      filteredUsers = filteredUsers.filter(user => user.teamDropDown.value === selectedTeam.value);
    }

    if (selectedStatus !== null && selectedStatus.value === null) {
      filteredUsers = filteredUsers;
    }
    if (selectedStatus !== null && selectedStatus.value != null) {
      filteredUsers = filteredUsers.filter(user => user.status === selectedStatus.value);
    }
    // if (selectedStatus !== null) {
    //   filteredUsers = filteredUsers.filter(user => user.status === selectedStatus.value);
    // }


    // Filter users based on the role of the logged-in user
    if (roleId === ROLE_TYPE.LEAD) {
      const projectsNameAssignedToLoggedInLead = GetLocalStorageData("ProjectsLeading");
      const projectLeading = GetLocalStorageData("projectsLead");
      
       
      filteredUsers = filteredUsers.filter(user =>
        user.projectEmployeeList && user.projectEmployeeList.some(project => projectLeading.includes(project))
      );
    }

    // Include the logged-in user in the filtered list only if it matches the filter criteria and it's not already in the list
    const loggedInUser = users.find(user => user.employeeId === loggedInUserId);
    if (loggedInUser && (!isFilterApplied || meetsFilterCriteria(loggedInUser))) {
      const isAlreadyIncluded = filteredUsers.some(user => user.employeeId === loggedInUserId);
      if (!isAlreadyIncluded) {
        filteredUsers.unshift(loggedInUser); // Add logged-in user to the beginning of the list
      }
    }

    return filteredUsers;
  };

  const meetsFilterCriteria = (user) => {
    return (
      (!selectedRole || user.roleDropDown.value === selectedRole.value) &&
      (!selectedTeam || user.teamDropDown.value === selectedTeam.value) &&
      (!selectedStatus || user.status === selectedStatus.value)
      //(selectedStatus === null || user.status === selectedStatus.value)
    );
  };

  const handleStatusChange = () => {
    setStatusChanged(true);
  };

  useEffect(() => {
    GetAllUser();
    GetAllRolesPermission();
    setStatusChanged(false);
  }, [statusChanged, selectedRole, selectedTeam, selectedStatus]); // Added selectedRole, selectedTeam, and selectedStatus to dependency array


  const HandleDropdownChange = (e, id, drp) => {
    if (e !== null && e !== undefined) {
      if (drp === "roleId") {
        setSelectedRole(e);
      } else if (drp === "teamName") {
        setSelectedTeam(e);
      } else if (drp === "status") {
        setSelectedStatus(e.value === null ? { value: null, label: "All" } : e);
        // setSelectedStatus(e.value);
      }
    }
    else {
      if (drp === "roleId") {
        setSelectedRole(null);
      } else if (drp === "teamName") {
        setSelectedTeam(null);
      } else if (drp === "status") {
        setSelectedStatus(null);
      }
    }
    // GetAllUser();
  };
  // const handleClearDate = () => {
  //   setSelectedRole({ value: 0, label: "All" });
  //   setSelectedTeam({ value: 0, label: "All" });
  //   setSelectedStatus({ value: null, label: "All" });
  //     GetAllUser();
  // };

  const handleClearDate = () => {
    setSelectedRole(null);
    setSelectedTeam(null);
    //setSelectedStatus(null); 
    setSelectedStatus(null);
    // GetAllUser();
  };





  /* <summary>
  date: 08-11-2023
  Name: Mustakim Shaikh
  description: AG grid column defintions
  <summary>*/
  // const columnDefs = [
  const gridColumns = (adminPermission, projectLeadPermission, employeePermission, loggedInUserId) => {
    return [
      {
        headerName: "",
        field: "",
        width: 50,
        filter: false,
        sortable: false,
        cellRenderer: (params) => (
          <div style={{ width: "50px", padding: "14px 5px" }}>
            <div
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: params.data.color,
              }}
              title="Profile Color"
            ></div>
          </div>
        ),
        headerClass: "header",
      },
      {
        headerName: "Name",
        field: "fullName",
        flex: 1,
        filter: false,
        sortable: true,
        cellRenderer: (params) => (
          <UserCommonCell data={params.data} fieldName="fullName" />
        ),
        headerClass: "header",
      },
      {
        headerName: "Email",
        field: "email",
        width: 300,
        filter: false,
        sortable: true,
        cellRenderer: (params) => (
          <UserCommonCell data={params.data} fieldName="email" />
        ),
        headerClass: "header",
      },
      {
        headerName: "Role ",
        field: "roleName",
        width: 200,
        flex: 1,
        filter: false,
        sortable: true,
        cellRenderer: (params) => (
          <UserCommonCell data={params.data} fieldName="roleName" />
        ),
        headerClass: "header",
      },
      {
        headerName: "Team ",
        field: "teamName",
        width: 100,
        filter: false,
        sortable: true,
        cellRenderer: (params) => (
          <UserCommonCell data={params.data} fieldName="teamName" />
        ),
        headerClass: "header",
      },
      //{
      //  headerName: "Password",
      //  field: "Password",
      //  width: 150,
      //  filter: false,
      //  sortable: false,
      //  cellRenderer: (params) => (
      //    <UserCommonCell data={params.data} fieldName="password" />
      //  ),
      //  headerClass: "header",
      //},

      {
        headerName: "",
        field: "status",
        flex: 1,
        filter: false,

        sortable: false,
        unSortIcon: false,
        cellRenderer: (params) => (
          <UserStatusCell
            active={params.data.status}
            employeeId={params.data.employeeId}
            context="ManageUsers"
            onStatusChange={handleStatusChange}


          />
        ),
        headerClass: "header",
      },
      {
        headerName: "",
        field: "EditButton",
        width: 60,
        filter: false,
        hide: roleId !== ROLE_TYPE.ADMIN && adminPermission.ManageEmp.editOther === 0 || roleId === ROLE_TYPE.EMPLOYEE && employeePermission.ManageEmp.edit === 0
          || roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.ManageEmp.editOther === 0 || roleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageEmp.edit === 0,
        sortable: false,
        unSortIcon: false,
        cellRenderer: (params) => (
          <UserCommonCell
            data={params.data}
            fieldName="EditButton"
            onEditClicked={handleEditButton}
            // modalOpen={modalOpen}
            // closeModal={closeModal}
            setEditUser={setEditUser}
          />
        ),
        headerClass: "header",
      },
      {
        headerName: "",
        field: "DeleteButton",
        width: 50,
        filter: false,
        sortable: false,
        hide:
          roleId !== ROLE_TYPE.ADMIN && adminPermission.ManageEmp.deleteOther === 0 ||
          roleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageEmp.delete === 0 ||
          roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.ManageEmp.deleteOther === 0 ||
          roleId === ROLE_TYPE.EMPLOYEE && employeePermission.ManageEmp.delete === 0,
        unSortIcon: false,
        pinned: "right",
        unSortIcon: false,
        cellRenderer: (params) => {
          // Extract the data for the row
          const rowData = params.data;

          // Extract loggedInUserId from params
          const loggedInUserId = params.loggedInUserId;

          // Check if the row represents the logged-in user
          if (rowData.employeeId === userId) {
            return null; // Don't render anything for the logged-in user's row
          }

          // Render the delete button for other rows
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "center",
                height: "100%",
              }}
            >
              <button
                className="edit-button"
                onClick={(e) => handleDelete(e, rowData)}
              >
                <i
                  className="fa fa-2x fa-trash"
                  aria-hidden="true"
                  style={{ color: "#e6072c",marginTop:'6px' }}
                  title="Delete"
                />
              </button>
            </div>
          );
        },
        headerClass: "header",
      }
    ];
  }

  const defaultColDef = {
    sortable: true,
    flex: 1,
    cellClass: "td",
  };

  const gridOptions = {
    //columnDefs,
    defaultColDef,
    rowHeight: DefaultAGGridProps.ROW_HEIGHT,
  };

  const handleEditButton = (user) => {
    AddLocalStorageData("isFromProfileClicked", false);
    navigate("/EditEmployee", {
      state: {
        employeeDetails: user, ProfileMode: false
      }
    });
  };

  const handleAddUser = () => {
    navigate("/AddEmployee", {
      state: {
        ProfileMode: false
      }
    });
  };


  const navigateToRolesPermission = () => {
    navigate('/RolesAndPermissions');
  }

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  /* <summary>
date: 09-02-2024
Name: MS
description: Clear input field on click 
<summary>*/
  const handleClearInput = () => {
    setSearchQuery("");
  };
  //SYJ - Search Depending on Grid values
  const filteredRowData = rowData.filter(
    (user) =>
      (searchQuery.length > 0 &&
        user.fullName.toLowerCase().includes(searchQuery.toLowerCase())) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (user.teamDropDown.label != null &&
        user.teamDropDown.label != undefined &&
        user.teamDropDown.label
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (user.roleDropDown.label != null &&
        user.roleDropDown.label != undefined &&
        user.roleDropDown.label
          .toLowerCase()
          .includes(searchQuery.toLowerCase()))
  );

  const onSortChanged = (e) => {
    e.api.refreshCells();
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }, []);


  //SYJ - Delete User from Grid But not from DB
  const handleDelete = async (event, item) => {
    event.stopPropagation();
    setselectedEmployeeId(item.employeeId);
    setShowDeleteModal(true);
  };

  const OnDeleteModalYesClick = async () => {
    try {
      let idToDelete = selectedEmployeeId;
      if (idToDelete > 0) {
        var deleteRes = await ManageUsersService.DeleteEmployee(idToDelete);
        if (deleteRes.data === "IsLead") {
          ShowToastMessage(ToastMessages.UserIsLead, ToastMessageType.Fail);
        }
        else if (deleteRes.data === "IsDeleted") {
          GetAllUser();
          setselectedEmployeeId(0);
          ShowToastMessage(ToastMessages.UserDelete, ToastMessageType.Success);
        }
      }
      GetAllUser();
      setShowDeleteModal(false);
    }
    catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };

  const OnCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  //Set Roles Permission

  const GetAllRolesPermission = async () => {
    try {
      ToggleLoader(true);
      //Default Array llist to filter data
      let adminList = [];
      let adminOtherList = [];
      let leadList = [];
      let leadOtherList = [];
      let employeeList = [];
      let employeeOtherList = [];
      const responseDefaultRP = await UserRolesPermission.GetDefaultRolePermissions();
      const responseUserSaveRP = await UserRolesPermission.GetUserSavedRolePermissions();

      // Function to get the list of role IDs that exist in the user saved role permissions data
      const existingRoleIds = responseUserSaveRP.data.map(item => item.roleId);


      // Bind data based on the existing role IDs
      if (existingRoleIds.includes(1)) {
        adminList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
        adminOtherList = responseUserSaveRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 2);
      }
      if (existingRoleIds.includes(2)) {
        employeeList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
        employeeOtherList = responseUserSaveRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 2);
      }
      if (existingRoleIds.includes(3)) {
        leadList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
        leadOtherList = responseUserSaveRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 2);
      }

      // If no role IDs 1, 2, or 3 data is present, bind the default list
      if (adminList.length === 0) {
        adminList = responseDefaultRP.data.filter(item => item.roleId === 1 && item.staticRolePermissionValueId === 1);
      }
      if (employeeList.length === 0) {
        employeeList = responseDefaultRP.data.filter(item => item.roleId === 2 && item.staticRolePermissionValueId === 1);
      }
      if (leadList.length === 0) {
        leadList = responseDefaultRP.data.filter(item => item.roleId === 3 && item.staticRolePermissionValueId === 1);
      }

      if (adminList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setAdminPermission({
          ManageEmp: {
            add: adminList.some(item => item.meadd === 0) ? 0 : 1,
            edit: adminList.some(item => item.meedit === 0) ? 0 : 1,
            view: adminList.some(item => item.meview === 0) ? 0 : 1,
            delete: adminList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            add: adminList.some(item => item.mrpadd === 0) ? 0 : 1,
            edit: adminList.some(item => item.mrpedit === 0) ? 0 : 1,
            view: adminList.some(item => item.mrpview === 0) ? 0 : 1,
            delete: adminList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            add: adminList.some(item => item.mpadd === 0) ? 0 : 1,
            edit: adminList.some(item => item.mpedit === 0) ? 0 : 1,
            view: adminList.some(item => item.mpview === 0) ? 0 : 1,
            delete: adminList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            add: adminList.some(item => item.tladd === 0) ? 0 : 1,
            edit: adminList.some(item => item.tledit === 0) ? 0 : 1,
            view: adminList.some(item => item.tlview === 0) ? 0 : 1,
            delete: adminList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            add: adminList.some(item => item.mtadd === 0) ? 0 : 1,
            edit: adminList.some(item => item.mtedit === 0) ? 0 : 1,
            view: adminList.some(item => item.mtview === 0) ? 0 : 1,
            delete: adminList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        });
      }
      if (leadList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setProjectLeadPermission({
          ManageEmp: {
            add: leadList.some(item => item.meadd === 0) ? 0 : 1,
            edit: leadList.some(item => item.meedit === 0) ? 0 : 1,
            view: leadList.some(item => item.meview === 0) ? 0 : 1,
            delete: leadList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            add: leadList.some(item => item.mrpadd === 0) ? 0 : 1,
            edit: leadList.some(item => item.mrpedit === 0) ? 0 : 1,
            view: leadList.some(item => item.mrpview === 0) ? 0 : 1,
            delete: leadList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            add: leadList.some(item => item.mpadd === 0) ? 0 : 1,
            edit: leadList.some(item => item.mpedit === 0) ? 0 : 1,
            view: leadList.some(item => item.mpview === 0) ? 0 : 1,
            delete: leadList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            add: leadList.some(item => item.tladd === 0) ? 0 : 1,
            edit: leadList.some(item => item.tledit === 0) ? 0 : 1,
            view: leadList.some(item => item.tlview === 0) ? 0 : 1,
            delete: leadList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            add: leadList.some(item => item.mtadd === 0) ? 0 : 1,
            edit: leadList.some(item => item.mtedit === 0) ? 0 : 1,
            view: leadList.some(item => item.mtview === 0) ? 0 : 1,
            delete: leadList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        });
      }

      if (employeeList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setEmployeePermission({
          ManageEmp: {
            add: employeeList.some(item => item.meadd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.meedit === 0) ? 0 : 1,
            view: employeeList.some(item => item.meview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            add: employeeList.some(item => item.mrpadd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.mrpedit === 0) ? 0 : 1,
            view: employeeList.some(item => item.mrpview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            add: employeeList.some(item => item.mpadd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.mpedit === 0) ? 0 : 1,
            view: employeeList.some(item => item.mpview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            add: employeeList.some(item => item.tladd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.tledit === 0) ? 0 : 1,
            view: employeeList.some(item => item.tlview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            add: employeeList.some(item => item.mtadd === 0) ? 0 : 1,
            edit: employeeList.some(item => item.mtedit === 0) ? 0 : 1,
            view: employeeList.some(item => item.mtview === 0) ? 0 : 1,
            delete: employeeList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        });
      }

      if (adminOtherList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setAdminPermission(prevState => ({
          ...prevState,
          ManageEmp: {
            ...prevState.ManageEmp,
            addOther: adminOtherList.some(item => item.meadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.meedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.meview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            ...prevState.ManageRolesPermission,
            addOther: adminOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.mrpview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            ...prevState.ManageProject,
            addOther: adminOtherList.some(item => item.mpadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.mpedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.mpview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            ...prevState.TimeLog,
            addOther: adminOtherList.some(item => item.tladd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.tledit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.tlview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            ...prevState.MyTask,
            addOther: adminOtherList.some(item => item.mtadd === 0) ? 0 : 1,
            editOther: adminOtherList.some(item => item.mtedit === 0) ? 0 : 1,
            viewOther: adminOtherList.some(item => item.mtview === 0) ? 0 : 1,
            deleteOther: adminOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        }));
      }

      if (leadOtherList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setProjectLeadPermission(prevState => ({
          ...prevState,
          ManageEmp: {
            ...prevState.ManageEmp,
            addOther: leadOtherList.some(item => item.meadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.meedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.meview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            ...prevState.ManageRolesPermission,
            addOther: leadOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.mrpview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            ...prevState.ManageProject,
            addOther: leadOtherList.some(item => item.mpadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.mpedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.mpview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            ...prevState.TimeLog,
            addOther: leadOtherList.some(item => item.tladd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.tledit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.tlview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            ...prevState.MyTask,
            addOther: leadOtherList.some(item => item.mtadd === 0) ? 0 : 1,
            editOther: leadOtherList.some(item => item.mtedit === 0) ? 0 : 1,
            viewOther: leadOtherList.some(item => item.mtview === 0) ? 0 : 1,
            deleteOther: leadOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        }));
      }

      if (employeeOtherList.length > 0) {
        // Update the adminPermission state with the filtered admin list
        setEmployeePermission(prevState => ({
          ManageEmp: {
            ...prevState.ManageEmp,
            addOther: employeeOtherList.some(item => item.meadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.meedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.meview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.medelete === 0) ? 0 : 1,
          },
          ManageRolesPermission: {
            ...prevState.ManageRolesPermission,
            addOther: employeeOtherList.some(item => item.mrpadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.mrpedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.mrpview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.mrpdelete === 0) ? 0 : 1,
          },
          ManageProject: {
            ...prevState.ManageProject,
            addOther: employeeOtherList.some(item => item.mpadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.mpedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.mpview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.mpdelete === 0) ? 0 : 1,
          },
          TimeLog: {
            ...prevState.TimeLog,
            addOther: employeeOtherList.some(item => item.tladd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.tledit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.tlview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.tldelete === 0) ? 0 : 1,
          },
          MyTask: {
            ...prevState.MyTask,
            addOther: employeeOtherList.some(item => item.mtadd === 0) ? 0 : 1,
            editOther: employeeOtherList.some(item => item.mtedit === 0) ? 0 : 1,
            viewOther: employeeOtherList.some(item => item.mtview === 0) ? 0 : 1,
            deleteOther: employeeOtherList.some(item => item.mtdelete === 0) ? 0 : 1,
          }
        }));
      }

      ToggleLoader(false);

    } catch (error) {
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
  };


  return (
    <>

      {showDeleteModalPopUp && (
        <DeleteModal
          showDeleteModal={showDeleteModalPopUp}
          onYesClick={OnDeleteModalYesClick}
          onNoClick={OnCloseDeleteModal}
          textToDisplay={"Are you sure you want to delete?"}
        />
      )}
      {
        roleId === ROLE_TYPE.ADMIN || roleId !== ROLE_TYPE.ADMIN && adminPermission.ManageEmp.viewOther === 1 || roleId === ROLE_TYPE.EMPLOYEE && employeePermission.ManageEmp.view === 1
          || roleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageEmp.view === 1 || roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.ManageEmp.viewOther === 1 ?
          <>
            <section className="breadcrumb-block"><BreadcrumbComponent /></section>

            <div>
              <main id="manage-user" className="main-body-content-project" style={{ paddingBottom: "6rem" }}>
                <section className="manageUsers">
                  <div className="container-fluid">
                    <div
                      className=""
                      style={{ display: "flex", flex: 1, gap: "8px", flexWrap: "wrap" }}
                    >
                      {/* <div className="col-12 col-md-6 col-lg-3"> */}
                      <div className="growth-flex" style={{ alignContent: "end" }}>
                        {/* <label>
                      Search <span className="isRequiredField"></span>
                    </label> */}
                        <div className="searchBar">
                          <input
                            className="searchQueryInput-class"
                            id="searchQueryInput"
                            type="text"
                            name="searchQueryInput"
                            placeholder="Search Employees"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                          />

                          <button
                            id="searchQuerySubmit"
                            type="submit"
                            name="searchQuerySubmit"
                            onClick={handleClearInput}
                          >

                            {searchQuery ? (
                              <img src="Assets/images/removeIcon.png" width="20px" />
                            ) : (
                              <img
                                src="Assets/images/Icon feather-search.png"
                                style={{ width: "20px", cursor: "pointer" }}
                              />
                            )}
                          </button>
                        </div>
                      </div>

                      {/* <div className="col-12 col-md-6 col-lg-3"> */}
                      <div className="growth-flex">
                        <label>
                          Role <span className="isRequiredField"></span>
                        </label>

                        <Select
                          inputId="roleDropDown"
                          placeholder="Please Select"
                          // value={user.roleDropDown}
                          value={selectedRole}
                          options={[
                            { value: 0, label: "All" },
                            ...Roles
                          ]}
                          onChange={(e) =>
                            HandleDropdownChange(e, "roleDropDown", "roleId")
                          }
                          isClearable={selectedRole !== undefined || (!selectedRole === null && selectedRole?.value > 0)}
                        />

                      </div>

                      {/* <div className="col-12 col-md-6 col-lg-3"> */}
                      <div className="growth-flex">
                        <label>
                          Team <span className="isRequiredField"></span>
                        </label>
                        {/* <input className="form-control custom-input" type="text" value="Software" /> */}

                        <Select
                          inputId="teamDropDown"
                          placeholder="Please Select"
                          //value={user.teamDropDown}
                          value={selectedTeam}
                          options={[
                            { value: 0, label: "All" },
                            ...Team.slice().sort((a, b) => a.label.localeCompare(b.label))
                          ]}
                          onChange={(e) =>
                            HandleDropdownChange(e, "teamDropDown", "teamName")
                          }
                          isClearable={selectedTeam !== undefined || (!selectedTeam === null && selectedTeam?.value > 0)}
                        />



                      </div>

                      {/* <div className="col-12 col-md-6 col-lg-3"> */}
                      <div className="growth-flex">
                        <label>
                          Status <span className="isRequiredField"></span>
                        </label>
                        {/* <input className="form-control custom-input" type="text" value="Software" /> */}

                        <Select
                          inputId="statusDropDown"
                          placeholder="Please Select"
                          value={selectedStatus}
                          options={[
                            { label: "All", value: null },
                            { label: "Active", value: true },
                            { label: "Inactive", value: false },
                          ]}
                          onChange={(e) => HandleDropdownChange(e, "statusDropDown", "status")}
                          isClearable={selectedStatus !== undefined || (!selectedStatus === null && selectedStatus?.value > 0)}
                        />

                      </div>
                    </div>
                    <div
                      className="project-tab  table-responsive"
                      style={{ overflowY: "hidden" }}
                    >
                      {(roleId === ROLE_TYPE.ADMIN || roleId !== ROLE_TYPE.ADMIN && adminPermission.ManageEmp.viewOther === 1 || roleId === ROLE_TYPE.EMPLOYEE && employeePermission.ManageEmp.viewOther === 1
                        || roleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageEmp.view === 1 || roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.ManageRolesPermission.viewOther === 1) &&
                        <table className="Project-table">
                          <AGGrid
                            rowData={filteredRowData}
                            gridOptions={gridOptions}
                            colDefs={gridColumns(adminPermission, projectLeadPermission, employeePermission, loggedInUserId)}
                            onGridReady={onGridReady}
                            pagination="10"
                            onSortChanged={(e) => onSortChanged(e)}
                          />
                        </table>}
                    </div>
                  </div>

                </section>  <div className="floating-btn-manageemployee">
                  {/* <div className="col-12 col-md-2 save-cancel-div" style={{ marginTop: "32px" }} > */}
                  {/* <div className="" style={{
                      alignContent: "flex-end"
                    }}> */}
                  <button
                    className="add-employee-btn"
                    onClick={handleClearDate}
                    style={{
                      width: "70px",
                      textAlign: "center",
                      marginRight: "8px"
                    }}
                  >
                    Reset
                  </button >
                  {/* </div> */}


                  {/* {roleId === ROLE_TYPE.ADMIN && (<div className="col-12 col-md-6 col-lg-6  text-right"> 
                </div>)} */}
                  {/* <div className="col-12 col-md-6 col-lg-8  text-right"> */}

                  {/* <div className="" style={{
                      alignContent: "flex-end",
                    }}> */}
                  {(roleId === ROLE_TYPE.ADMIN || roleId !== ROLE_TYPE.ADMIN && adminPermission.ManageRolesPermission.viewOther === 1 || roleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageRolesPermission.view === 1 || roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.ManageRolesPermission.viewOther === 1) && (
                    <button className="add-employee-btn" onClick={navigateToRolesPermission} style={{ marginRight: "8px" }}>
                      Manage Roles and Permissions
                    </button>)}
                  {(roleId === ROLE_TYPE.ADMIN || roleId !== ROLE_TYPE.ADMIN && adminPermission.ManageEmp.addOther === 1 || roleId === ROLE_TYPE.EMPLOYEE && employeePermission.ManageEmp.add === 1
                    || roleId === ROLE_TYPE.LEAD && projectLeadPermission.ManageEmp.add === 1 || roleId === ROLE_TYPE.EMPLOYEE && projectLeadPermission.ManageRolesPermission.addOther === 1) &&
                    <button className="add-employee-btn" onClick={handleAddUser}>
                      Add Employee
                    </button>}
                  {/* </div> */}
                </div>
              </main>
            </div></>

          //: <ErrorPage />

          : roleId === ROLE_TYPE.EMPLOYEE && (employeePermission.ManageEmp.view === 0 || projectLeadPermission.ManageEmp.viewOther === 0) && <ErrorPage />
      }
    </>
  );
};

export default ManageUsers;
