import React, { useState } from "react";
import DeleteModal from "../../Components/DeleteModal";
import { GetLocalStorageData, isStringEmpty } from "../../Helpers/Utilities";
import ErrorField from "../../Components/ErrorField";
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContextProvider";
import { ToastMessages, TshirtSizeError } from "../../Helpers/HelperText";
import { HTTPResponse, ToastMessageType } from "../../Helpers/Enums";
import { GlobalEstimationService } from "../../WebApiServices/GlobalEstimation.service";
import { useEffect } from "react";

const TshirtSize=({ getTshirtSize })=>{
  const {ShowToastMessage,ToggleLoader}=useContext(AppContext);
    const [items, setItems] = useState([
    //   {
    //     tshirtSize:"",
    //     scopeOfWork:"",
    // estimatedTime:"" ,
    // estimatedDays:"" ,
    //   }
    ]);
    const [showDeleteModalPopUp, setShoweleteModalPopUp] = useState(false);
    const [itemIndex, setItemIndex] = useState(null);
    const [id, setId] = useState(null);
    const[isEdited,setIsEdited]=useState(Boolean)
    const [confirmModal,setConfirmModal]=useState(false);
   const[errors,setErrors]=useState({
    tshirtSize_error:false && [],
    tshirtsize_duplicate:false && [],
    scopeOfWork_error :false && [],
    estimatedTime_error :false && [],
    // :false && [],
   })
   const userId = GetLocalStorageData("userId");
    const handleAddNewSize = () => {
      setItems([...items, { tshirtSize: "", scopeOfWork: "", estimatedTime: "", estimatedDays: "",createdBy:userId, canBeDeleted:true }]);
      setErrors((prev)=>({
        ...prev,
        tshirtSize_error:false ,
    scopeOfWork_error :false ,
    estimatedTime_error :false ,
    tshirtsize_duplicate:false,
   
   // estimatedDays_error :false ,
      }))
    };
    

    useEffect(()=>{
      getTshirtSizeEstimate();
    },[])
  const getTshirtSizeEstimate=async()=>{
    const response =await GlobalEstimationService.GetTshirtSizeEstimate();
    try{
      ToggleLoader(true)
    if (response.status===HTTPResponse.OK){
         
      setItems(response.data);
  //     setItems([(prev)=>({
  //       ...prev,
  //       tshirtSizeEstimatesId:response.data.tshirtSizeEstimatesId,
  //            tshirtSize:response.data.tshirtSize,
  //       scopeOfWork:response.data.scopeOfWork,
  //   estimatedTime:response.data.estimatedTime,
  //  // estimatedDays: response.data.,
  //     })])
      ToggleLoader(false)
    }
    else{
      ToggleLoader(false)
      ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
    
  }
  
  catch (error) {
    ToggleLoader(false);
    ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
}
}
    const handleInputChange = (index, field, value,id) => {
         
      if (field === "tshirtSize" || field === "scopeOfWork") {
        if(field === "scopeOfWork" && value.length>200){
          return
        }
        if(field === "tshirtSize" && value.length>50){
          return
        }
        const isAlphabet = /^[A-Za-z]*$/;
       // const isAlphanumeric = /^[A-Za-z0-9]*$/;
        const isAlphanumeric = /^[A-Za-z0-9 ]*$/;

        if (!isAlphanumeric.test(value) ) {
          return; // Exit if the input contains non-alphabet characters
        }
      } 
      else if (field === "estimatedTime" || field === "estimatedDays") {
        const isInteger = /^[0-9]*$/;
       if(value.length>3 ||!isInteger.test(value)){
        return
       }
       
      }
      const updatedItems = [...items];
      updatedItems[index][field] = value;

      updatedItems[index]["editedBy"] = userId;
    if(id){
      setIsEdited(true);
    }  
     
  setErrors((prev) => {
    const newErrors = { ...prev };

    if (newErrors.tshirtSize_error?.[index]?.error) {
      newErrors.tshirtSize_error[index].error = isStringEmpty(updatedItems[index].tshirtSize);
    }

    if (newErrors.scopeOfWork_error?.[index]?.error) {
      newErrors.scopeOfWork_error[index].error = isStringEmpty(updatedItems[index].scopeOfWork);
    }

    if (newErrors.estimatedTime_error?.[index]?.error) {
      newErrors.estimatedTime_error[index].error = isStringEmpty(updatedItems[index].estimatedTime);
    }
    if (newErrors.tshirtsize_duplicate?.[index]?.error) {
      newErrors.tshirtsize_duplicate[index].error = isStringEmpty(updatedItems[index].tshirtSize);
    }
    return newErrors;
  });
    };
    const handleDeleteRow = (index,tshirtSizeEstimatesId) => {
      setId(tshirtSizeEstimatesId)
      setItemIndex(index)
     // setId(id)
      setShoweleteModalPopUp(true);
    };
    const OnCloseDeleteModal = () => {  
      setShoweleteModalPopUp(false);
    };
    const OnDeleteModalYesClick=async()=>{
        
      if (itemIndex !== null && id !=null) { 
        const response=await GlobalEstimationService.DeleteTshirtSizeEstimate(id);
        if(response.status===HTTPResponse.OK){
        
          await getTshirtSize();
         
          ShowToastMessage(ToastMessages.DataDelete, ToastMessageType.Success);
          setShoweleteModalPopUp(false);
          setItemIndex(null);

          
        }
          else{
            
          ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
                    }
                  
        }
        const updatedItems = [...items];
        updatedItems.splice(itemIndex, 1);
        setItems(updatedItems);
        setShoweleteModalPopUp(false);
        setErrors((prev)=>({
          ...prev,
          tshirtSize_error:false ,
      scopeOfWork_error :false ,
      estimatedTime_error :false ,
      tshirtsize_duplicate:false
     // estimatedDays_error :false ,
        }))
      };
    const Validate=()=>{
         
      let result=true
      if(items.length===0){
        return result=false;
      }
      const tshirtSizeError= items.map((item,index)=>({
        index:index,
        name:item.tshirtSize,
        error:isStringEmpty(item.tshirtSize),
      }));
      const anytshirtSizeEmpty=tshirtSizeError.some((item)=>item.error);
      const tshirtSizeCounts = {};
      const tshirtSizeDuplicateError = items.map((item, index) => {
        if (item.tshirtSize) {
          // Convert tshirtSize to lowercase for case-insensitive comparison
          const tshirtSizeLower = item.tshirtSize.toLowerCase();
          
          // Increment count for each t-shirt size
          tshirtSizeCounts[tshirtSizeLower] = (tshirtSizeCounts[tshirtSizeLower] || 0) + 1;
        }
    
        return {
          index: index,
          name: item.tshirtSize,
          error: tshirtSizeCounts[item.tshirtSize.toLowerCase()] > 1, // Error if it's a duplicate
        };
      });
    
      // Ignore the error for the first occurrence of each t-shirt size
      const tshirtSizeFirstOccurrence = {};
      const tshirtSizeErrorWithoutFirstDuplicates = tshirtSizeDuplicateError.map((error) => {
        if (!tshirtSizeFirstOccurrence[error.name]) {
        
          tshirtSizeFirstOccurrence[error.name] = true;
          return { ...error, error: error.error }; 
        }
        return error; // Keep the error for duplicates after the first
      });
    
      
      const anyTshirtSizeDuplicate = tshirtSizeErrorWithoutFirstDuplicates.some((item) => item.error);


      const scopeOfWorkError=items.map((item,index)=>({
        index:index,
        name:item.scopeOfWork,
        error:isStringEmpty(item.scopeOfWork),
      }))
      const anyScopeOfWorkEmpty=scopeOfWorkError.some((item)=>item.error);
      const estimatedTimeError= items.map((item,index)=>({
        index:index,
        name:item.estimatedTime,
        error:item.estimatedTime===0 ||item.estimatedTime==="" ||item.estimatedTime==="0" ||item.estimatedTime==="00" ||item.estimatedTime==="000" ,
      }))
      const anyEstTimeEmpty=estimatedTimeError.some((item)=>item.error);
      setErrors((prev)=>({
        ...prev,
        tshirtSize_error:tshirtSizeError,
        tshirtsize_duplicate:tshirtSizeDuplicateError,
        scopeOfWork_error:scopeOfWorkError,
        estimatedTime_error:estimatedTimeError,
      //  estimatedDays_error:estimatedDaysError
      }))
      if(anytshirtSizeEmpty ||anyScopeOfWorkEmpty || anyEstTimeEmpty ||anyTshirtSizeDuplicate )
       // ||anyEstDaysEmpty
       {
        result=false;
      }
      return result;
    }
    const OnSave=async()=>{
      if(Validate()){
          
        if(isEdited){
           setConfirmModal(true);
        }
       else{
        OnYesConfirmModal();
       }
      }
 
    }
    const OnCancelConfirmModal=()=>{
setConfirmModal(false);
    }
    const OnYesConfirmModal=async()=>{
        try{
          ToggleLoader(true)
            
        const response= await GlobalEstimationService.SaveTshirtEstimates(items);
       // const response=[]
        if (response.status===HTTPResponse.OK){
          ToggleLoader(false)
         await ShowToastMessage(ToastMessages.Added,ToastMessageType.Success); 
          setConfirmModal(false);
          setIsEdited(false);
          
         await getTshirtSizeEstimate();
         await getTshirtSize();
        }
        else{
          ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
   
      
      }
      catch (error) {
        ToggleLoader(false);
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
    }
    return(
        <>
        {confirmModal && (
              <DeleteModal
              globalEstimation={true}
              onYesClick={OnYesConfirmModal}
                onNoClick={OnCancelConfirmModal}
                textToDisplay={"Are you sure you want to delete?"}
              />
            )}
        {showDeleteModalPopUp && (
              <DeleteModal
                showDeleteModal={showDeleteModalPopUp}
                onYesClick={OnDeleteModalYesClick}
                onNoClick={OnCloseDeleteModal}
                textToDisplay={"Are you sure you want to delete?"}
              />
            )}
            
         <div className="container-fluid Tshirt">
                  <div
                    className="innerBox"
                    style={{
                      borderRadius: "0px",
                      padding: "2px 21px 10px",
                    }}
                  >
                    {/* <a
                      style={{
                        backgroundColor: " #ebe6f5",
                        fontFamily: "Lato-Bold",
                        color: "#684e85",
                        height: "10px",
                      }}
                    >
                      {" "}
                      Swimlanes
                    </a> */}

                    <div className="boxContenn" style={{ marginTop: "8px" }}>
                      <table style={{ borderCollapse: "collapse", width: "100%" }}>
                        <thead
                          style={{
                            //border: "1px solid #000",
                            padding: "5px",
                            textAlign: "center",
                            backgroundColor: "#684e85",
                            color: "white",
                            fontFamily: "Lato-Medium",
                          }}
                        >
                          <tr style={{height:'35px'}}>
                            <th style={{ padding: "5px", textAlign: 'center' }}>T Shirt Size</th>
                            <th style={{ padding: "5px", textAlign: 'center' }}>Scope Of Work</th>
                            <th style={{ padding: "5px", textAlign: 'center' }}>Estimated Time (Hrs)</th>
                            <th style={{ padding: "5px" ,textAlign: 'center'}}>Estimated Time (Days) </th>
                            <th style={{ padding: "5px" ,textAlign: 'center'}}></th>

                          </tr>
                        </thead>

                        <tbody>
                     
                {items &&  items.map((item, index) => (
                  <tr key={index} style={{ marginBottom: "5px",  height: "65px" }}>
                    <td style={{ textAlign: "center" }}>
                      <input
                        style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "90%", height: "35px", borderRadius: "0.25rem" }}
                        type="text"
                        value={item.tshirtSize}
                        onChange={(e) => handleInputChange(index, "tshirtSize", e.target.value,item.tshirtSizeEstimatesId)}
                      />
                      <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                                      {errors.tshirtSize_error && (
                                        <div
                                          // style={{
                                          //   position: "absolute",
                                          //   bottom: "0",
                                          //   width: "100%",
                                          //   display: "flex",
                                          //   justifyContent: "center",
                                          //   marginBottom: "-2px",
                                          // }}
                                        >
                                          <ErrorField
                                            show={errors.tshirtSize_error[index]?.error}
                                            message={TshirtSizeError.TshirtSize}
                                          />
                                        </div>
                                      )}
                                       {errors.tshirtsize_duplicate && (
                                        <div
                                          // style={{
                                          //   position: "absolute",
                                          //   bottom: "0",
                                          //   width: "100%",
                                          //   display: "flex",
                                          //   justifyContent: "center",
                                          //   marginBottom: "-2px",
                                          // }}
                                        >
                                          <ErrorField
                                            show={errors.tshirtsize_duplicate[index]?.error}
                                            message={TshirtSizeError.DuplicateSize}
                                          />
                                        </div>
                                      )}
                                    </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "90%", height: "35px", borderRadius: "0.25rem" }}
                        type="text"
                        value={item.scopeOfWork}
                        onChange={(e) => handleInputChange(index, "scopeOfWork", e.target.value,item.tshirtSizeEstimatesId)}
                      />
                       <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                      {errors.scopeOfWork_error &&(
                        <div>
                        <ErrorField
                        show={errors.scopeOfWork_error[index]?.error}
                        message={TshirtSizeError.ScopeOfWork}
                        />
                        </div>
                      )}</div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "90%", height: "35px", borderRadius: "0.25rem" }}
                        type="text"
                        value={item.estimatedTime}
                        onChange={(e) => handleInputChange(index, "estimatedTime", e.target.value,item.tshirtSizeEstimatesId)}
                      />
                      <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                      {errors.estimatedTime_error &&(
                        <div>
                        <ErrorField
                        show={errors.estimatedTime_error[index]?.error}
                        message={TshirtSizeError.EstimatedTime}
                        />
                        </div>
                      )}</div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {/* <input
                        style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "90%", height: "35px", borderRadius: "0.25rem" }}
                        type="text"
                        value={item.estimatedTime/8??0}
                        disabled
                        //onChange={(e) => handleInputChange(index, "estimatedDays", e.target.value)}
                      /> */}
                      {item.estimatedTime/8??0}
                       <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                      {errors.estimatedDays_error &&(
                        <div>
                        <ErrorField
                        show={errors.estimatedDays_error[index]?.error}
                        message={"re"}
                        />
                        </div>
                      )}</div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                    {item.canBeDeleted && (  <button onClick={() => handleDeleteRow(index,item.tshirtSizeEstimatesId)} title="Delete">
                        <img
                          src="Assets/images/deleteIcon.png"
                          style={{ width: "24px", height: "26px" }}
                        />
                      </button>
                    )}</td>
                  </tr>
                ))}
        
                        </tbody>
                      </table>
                    </div>

                    {/* <ErrorField
                      show={error.newItem}
                      message={CommonErrorMessages.DuplicateSwminName}
                    /> */}

                    {/* <div className="showNewInputField">
                      <button
                        className="showNewInputField"
                       // onClick={handleNewInputFields}
                      >
                        <img
                          src="Assets/images/AddIcons.png"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "4px",
                          }}
                        />{" "}
                        Add New Swimlane
                      </button>
                    </div> */}
                  </div>
                  <div className="save-cancel-sec">
                                                        
                                                            <button
                                                                id="btnCreateAnother"
                                                                name="btncreateAnother"
                                                                className="save-btn"
                                                               onClick={handleAddNewSize}
                                                                //disabled={isProcessing} // Disable when processing
                                                            >
                                                                <span>Add New Size</span>
                                                            </button>
                                                     
                                                        <button
                                                            className="save-btn custom-width"
                                                            //disabled={isProcessing}
                                                            onClick={OnSave}
                                                        >
                                                            Save
                                                        </button>
                                                        {/* <button className="cancel-btn custom-width">Cancel</button> */}
                                                    </div>
                </div>
        </>
    );
}
export default TshirtSize;