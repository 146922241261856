import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import 'ag-grid-community/styles//ag-theme-material.css';
import 'ag-grid-community/styles//ag-theme-balham.css';

export default function AGGrid(props) {
    const { gridOptions } = props;

    return (
        <div
            className="ag-theme-alpine"
            style={{
                height: props.height ?? props.isTimelog ? "670px" : props.isTimeTracker ? "590px" : props.isProject ? "67vh" : "605px",
                width: '100%',
                cursor: "pointer"
            }}            >
            <AgGridReact
                // autoHeight={props.isMyTasks}

                paginationAutoPageSize={true}
                rowDragMultiRow={true} // Allow dropping multiple rows
                rowDragManaged={props.rowDragManaged} // Enable managed row dragging
                onRowDragStart={props.onRowDragStart} // Event handler for drag start
                onRowDragEnter={props.onRowDragEnter} // Event handler for drag enter
                onRowDragEnd={props.onRowDragEnd} // Event handler for drag end
                rowDragEntireRow={props.rowDrag}
                onRowClicked={props.onRowClicked}
                onGridReady={props.onGridReady}
                suppressMenuHide={true}
                pagination={true}
                paginationPageSize={props.pagination}
                defaultColDef={{
                    sortable: true, editable: false, unSortIcon: true, filter: true, resizable: true, autoHeight: props.autoHeight, wrapHeaderText: true, autoHeaderHeight: true, wrapText: true,
                    textOverflow: "ellipsis", whiteSpace: "pre", overflow: "hidden",
                }}
                animateRows={true}
                tooltipShowDelay={0}
                tooltipHideDelay={2000}
                gridOptions={gridOptions}
                rowData={props.rowData}
                columnDefs={props.colDefs}
                rowHeight={props.isTimelog ? 48.5 : props.isTimeTracker ? 48.5 : 50}
                //autoHeight={false}
                overlayNoRowsTemplate={props.overlayNoRowsTemplate}
                onSortChanged={props.onSortChanged}
                pinnedBottomRowData={props.pinnedBottomRowData}
            >
            </AgGridReact>
        </div>


    )
}
