import React, { createContext, useEffect, useReducer, useContext } from "react";
import { AppReducerActions } from "../ReducerActions/AppReducerActions";
import { AppReducer } from "../Reducers/AppReducer";
import {
  AppContextInitialState,
  PAGE_ROUTE_ID,
  BREADCRUMB_PAGE_ROUTES,
  ToastTimeout,
} from "../Helpers/Enums";
import ToastComponent from "../Components/ToastComponent";
import Header from "../Components/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { RemoveLocalStorageData } from "../Helpers/Utilities";
import Loader from "../Components/Loader";
import Footer from "../Components/Footer";
import { LoginService } from "../WebApiServices/Login.service";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [appState, dispatchUIAction] = useReducer(AppReducer, {
    ...AppContextInitialState,
    breadCrumbs: BREADCRUMB_PAGE_ROUTES,
  });
  const location = useLocation();
  const navigate = useNavigate();

   // Set isDirty to true
   const markDirty = () => {
    dispatchUIAction({
      type: AppReducerActions.SET_IS_DIRTY,
      isDirty: true,
    });
  };

  // Set isDirty to false
  const clearDirty = () => {
    dispatchUIAction({
      type: AppReducerActions.SET_IS_DIRTY,
      isDirty: false,
    });
  };
  
  /* <summary>
  date: 13-09-2023
  Name: AP
  description: Effect to hide toast after 4 seconds
  <summary>*/
  useEffect(() => {
    if (appState.showToast) {
      setTimeout(() => {
        dispatchUIAction({
          type: AppReducerActions.SHOW_TOAST,
          message: "",
        });
      }, ToastTimeout);
    }

    return () => {};
  }, [appState]);

  const { pathname } = useLocation();
  /* <summary>
date: 11-10-2023
Name: AP
description: // Scroll to the top of the page when the component is mounted or when the location changes.
<summary>*/
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  function ShowToastMessage(message, toastType) {
    dispatchUIAction({
      type: AppReducerActions.SHOW_TOAST,
      message,
      toastType,
    });
  }

  function changePageData(pageDataChanged) {
    dispatchUIAction({
      type: AppReducerActions.PAGE_DATA_CHANGED,
      pageDataChanged,
    });
  }
  function SetProfileImage(imagePath) {
    dispatchUIAction({
      type: AppReducerActions.SET_PROFILE_IMAGE,
      imagePath,
    });
  }
  function SetProjectId(projectId, projectName) {
    const updatedBreadcrumbs = appState.breadCrumbs.map((breadcrumb) => {
      if (breadcrumb.id === PAGE_ROUTE_ID.PROJECTNAME) {
        return {
          ...breadcrumb,
          pageName: projectName,
        };
      }
      return breadcrumb;
    });

    dispatchUIAction({
      type: AppReducerActions.SET_PROJECTID,
      projectId,
      projectName,
      breadCrumbs: updatedBreadcrumbs,
    });
  }

  // reportId,
  function SetReportId(reportDate) {
    dispatchUIAction({
      type: AppReducerActions.SET_REPORTID,
      // reportId,
      reportDate,
    });
  }
  /* <summary>
  date: 07-10-2023
  Name: AP
  description: Set Action Item ID
  <summary>*/

  function SetActionItemId(actionItemId, actionItemName) {
    const updatedBreadcrumbs = appState.breadCrumbs.map((breadcrumb) => {
      if (breadcrumb.id === PAGE_ROUTE_ID.EDIT_ACTION_ITEM) {
        return {
          ...breadcrumb,
          pageName: actionItemName,
        };
      }
      return breadcrumb;
    });

    dispatchUIAction({
      type: AppReducerActions.SET_ACTIONITEM_ID,
      actionItemId,
      actionItemName,
      breadCrumbs: updatedBreadcrumbs,
    });
  }
  function SetRoleId(roleId) {
    dispatchUIAction({
      type: AppReducerActions.SET_ROLEID,
      roleId,
    });
  }

  /* <summary>
  date: 23-10-2023
  Name: NGG
  description: Toggle UI Loader
  <summary>*/
  function ToggleLoader(action) {
    dispatchUIAction({
      type: AppReducerActions.TOGGLE_LOADER,
      value: action,
    });
  }

  /* <summary>
  date: 12-10-2023
  Name: AP
  description: set the field to clear the create Action Item form 
  <summary>*/
  function SetActionItemClear(actionItemClear) {
    dispatchUIAction({
      type: AppReducerActions.SET_ACTION_ITEM_CLEAR,
      actionItemClear,
    });
  }

  /* <summary>
  date: 11-12-2023
  Name: MS
  description: Logout after token expiry
  <summary>*/
  function LogOut(userId) {
    dispatchUIAction({
      type: AppReducerActions.LOGOUT,
      value: userId,
    });
    
    RemoveLocalStorageData("lastVisitedProjectIds");  
    RemoveLocalStorageData("roleID");
    RemoveLocalStorageData("userName");
    RemoveLocalStorageData("userId");
    RemoveLocalStorageData("color");
    RemoveLocalStorageData("imagePath");
    RemoveLocalStorageData("selectedSprint");
    RemoveLocalStorageData("newlySelectedEmployee");
    RemoveLocalStorageData("newlySelectedProjects");
    RemoveLocalStorageData("projectName");
    RemoveLocalStorageData("key");
    RemoveLocalStorageData("ProjectAddDetails");
    RemoveLocalStorageData("ProjectId");
    RemoveLocalStorageData("ActionItemCount");
    RemoveLocalStorageData("actionItemTitle");
    RemoveLocalStorageData("activeTab");
    RemoveLocalStorageData("ProjectsLeading");
    RemoveLocalStorageData("email");
    RemoveLocalStorageData("lastVisitedProjectIds");  
    RemoveLocalStorageData("newProjectId")
    RemoveLocalStorageData("selectedFilters");
    
    RemoveLocalStorageData("newProjectId");

    RemoveLocalStorageData("isFromProfileClicked");
    RemoveLocalStorageData("empDetails");
    RemoveLocalStorageData("pathvalue");
    RemoveLocalStorageData("isDirty");
    RemoveLocalStorageData("activeTabGlobalEstimation");
    RemoveLocalStorageData("locationHistory");


ToggleLoader(false)
LoginService.UserLogout();
    navigate("/");
  }
  const { showToast, toastMessage } = appState;
  return (
    <AppContext.Provider
      value={{
        appState,
        SetProfileImage,
        ShowToastMessage,
        SetProjectId,
        dispatchUIAction,
        SetReportId,
        SetActionItemId,
        SetRoleId,
        changePageData,
        SetActionItemClear,
        ToggleLoader,
        LogOut,
        markDirty,  
        clearDirty,
      }}
    >
      <>
        {appState.isLoading && <Loader />}

        {/* {(location.pathname === "/ManageProjects" ||
          location.pathname === "/ManageUsers" ||
          location.pathname === "/AddProject" ||
          location.pathname === "/EditProject" ||
          location.pathname.includes("/EditActionItem") ||
          location.pathname.includes("/CreateActionItem") ||
          location.pathname === "/ActiveSprints" ||
          location.pathname === "/TimeLog" ||
          location.pathname === "/Profile" ||
          location.pathname === "/EditEmployee" ||
          location.pathname === "/AddEmployee" ||
          location.pathname.includes("/Overview") ||
          location.pathname === "/AddTimeLogging" ||
          location.pathname === "/MyTasks" ||
          location.pathname === "/RolesPermissions" ||
          location.pathname === "/MyTasks" ||
          location.pathname.includes("/AddActionItem") ||
          location.pathname.includes("/AddTask") ||location.pathname.includes("/Calendar") ||location.pathname.includes("/TimeTracker") ||
          location.pathname === "/EditTimeLogging") && <Header />} */}
            {location.pathname !== "/" && <Header />}
        <></>
        {showToast && toastMessage && <ToastComponent />}
        {children}
        {location.pathname !== "/" && <Footer />}
      </>
    </AppContext.Provider>
  );
};

export const useUIContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("UIContext was used outside the UIContextProvider");
  }

  return context;
};

export default AppContextProvider;
