import React, { useEffect, useState } from "react";
import Select from "react-select";
import DeleteModal from "../../Components/DeleteModal";
import { GetLocalStorageData, isStringEmpty } from "../../Helpers/Utilities";
import ErrorField from "../../Components/ErrorField";
import { GlobalEstimationService } from "../../WebApiServices/GlobalEstimation.service";
import { ESTIMATION_AREA, HTTPResponse, MaxCharacterCount, ToastMessageType } from "../../Helpers/Enums";
import { useContext } from "react";
import { AppContext } from "../../Contexts/AppContextProvider";
import { EstimationError, ToastMessages } from "../../Helpers/HelperText";
const Testing = (props) => {
  
  const {ShowToastMessage,ToggleLoader}=useContext(AppContext);
  
  const [items, setItems] = useState([
    // {
    //   estimatedWorkItem:"",
    //   workItemDesc:"",
    //   tshirtSize:"",
    // estimatedTime:"" ,
    // estimatedDays:"" ,
    // comments:""
    // }
  ]);
  const userId = GetLocalStorageData("userId");
  const[isEdited,setIsEdited]=useState(Boolean)
  const [confirmModal,setConfirmModal]=useState(false);
  const [showDeleteModalPopUp, setShoweleteModalPopUp] = useState(false);
  const [itemIndex, setItemIndex] = useState(null);
  const [id, setId] = useState(0);
  const[errors,setErrors]=useState({
    estimatedWorkItem_error:false && [],
    workItemDesc_error:false && [],

    tshirtSize_error:false && [],
    //scopeOfWork_error :false && [],
    estimatedTime_error :false && [],
    //estimatedDays_error :false && [],
    comments_error :false && [],
   })
  // Function to add a new row with input fields
  // const handleAddNewSize = () => {
  //   // Add a new item to the state with empty values
  //   setItems([...items, { tshirtSize: "", scopeOfWork: "", estimatedTimeHrs: "", estimatedTimeDays: "" }]);
  // };

  // // Function to handle input changes
  // const handleInputChange = (index, field, value) => {
  //   const updatedItems = [...items];
  //   updatedItems[index][field] = value;
  //   setItems(updatedItems);
  // };
  // const handleInputChange = (index, field, value) => {
  //   const updatedItems = [...items];
  //   updatedItems[index][field] = value;
  //   setItems(updatedItems);
  // };
  useEffect(()=>{
    getGeneralData();
  },[])
  const getGeneralData=async()=>{
    try{
      ToggleLoader(true)
      const response=await GlobalEstimationService.GetGeneralData();
      if (response.status===HTTPResponse.OK){
        //await getTshirtSizeEstimate();
          
        const generalData=response && response.data.filter((item)=>item.estimationAreaId===ESTIMATION_AREA.Testing)
        setItems(generalData);
        ToggleLoader(false)
       }
       else{
        ToggleLoader(false)
         ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
       }
     }
     catch (error) {
       ToggleLoader(false);
       ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
   }
   }
  const handleInputChange = (index, field, value,id) => {
      
    const updatedItems = [...items];
    if (field ==="workItem"  || field === "description") {
      // Allow only alphabets
      const isAlphabet =  /^[A-Za-z0-9 ]*$/;
     // const isAlphanumeric = /^[A-Za-z0-9 ]*$/;
      // const isInteger = /^[0-9]*$/;

      if (!isAlphabet.test(value)) {
        return; // Exit if the input contains non-alphabet characters
      }
    } else if (field === "estimatedTime" || field === "estimatedDays"|| field === "tshirtSizeEstimatesId") {
      // Allow only integers
      const isInteger = /^[0-9]*$/;
      if (!isInteger.test(value)) {
        return; // Exit if the input contains non-integer characters
      }
    }
    if (field === 'tshirtSizeEstimatesId') {
      // Find the corresponding item from tshirtSizeList using the selected value
      const selectedSize = props.tshirtSizeList.find((item) => item.value === value);
  
      // If found, set the estimatedTime for the updated item
      if (selectedSize) {
        updatedItems[index].estimatedTime = selectedSize.estimatedTime;
        
      }
    }
    
    updatedItems[index][field] = value;
    setItems(updatedItems);
    // setErrors((prev)=>({
    //   ...prev,
    //   estimatedWorkItem_error: {
    //     ...prev.estimatedWorkItem_error,
    //     [index]: {
    //       error: isStringEmpty(updatedItems[index].estimatedWorkItem),
    //     },
    //   },
    //   workItemDesc_error: {
    //     ...prev.workItemDesc_error,
    //     [index]: {
    //       error: isStringEmpty(updatedItems[index].workItemDesc),
    //     },
    //   },
    //   tshirtSize_error: {
    //     ...prev.tshirtSize_error,
    //     [index]: {
    //       error: isStringEmpty(updatedItems[index].tshirtSize),
    //     },
    //   },
    //   estimatedTime_error:{
    //     ...prev.estimatedTime_error,
    //     [index]:{
    //       error:isStringEmpty(updatedItems[index].estimatedTime),
    //     }
    //   },
    //   estimatedDays_error:{
    //     ...prev.estimatedDays_error,
    //     [index]:{
    //       error:isStringEmpty(updatedItems[index].estimatedDays),
    //     }
    //   },
    //   comments_error:{
    //     ...prev.comments_error,
    //     [index]:{
    //       error:isStringEmpty(updatedItems[index].comments),
    //     }
    //   },
    // }))
    if(id){
      setIsEdited(true);
    } 
      
    setErrors((prev) => {
      const newErrors = { ...prev };
      if (newErrors.estimatedWorkItem_error?.[index]?.error) {
        newErrors.estimatedWorkItem_error[index].error = isStringEmpty(updatedItems[index].workItem);
      }
      if (newErrors.workItemDesc_error?.[index]?.error) {
        newErrors.workItemDesc_error[index].error = isStringEmpty(updatedItems[index].description);
      }

      if (newErrors.tshirtSize_error?.[index]?.error) {
        newErrors.tshirtSize_error[index].error = updatedItems[index].tshirtSizeEstimatesId===""||updatedItems[index].tshirtSizeEstimatesId===0;
      }
  
      // if (newErrors.scopeOfWork_error?.[index]?.error) {
      //   newErrors.scopeOfWork_error[index].error = isStringEmpty(updatedItems[index].scopeOfWork);
      // }
  
      if (newErrors.estimatedTime_error?.[index]?.error) {
        newErrors.estimatedTime_error[index].error = isStringEmpty(updatedItems[index].estimatedTime);
      }
  
      // if (newErrors.estimatedDays_error?.[index]?.error) {
      //   newErrors.estimatedDays_error[index].error = isStringEmpty(updatedItems[index].estimatedDays);
      // }
      if (newErrors.comments_error?.[index]?.error) {
        newErrors.comments_error[index].error = isStringEmpty(updatedItems[index].comments);
      }
      return newErrors;
    });
  };
  const handleAddNewSize = () => {
    setItems([
      ...items,
      {
        workItem:"",
        description:"",
        tshirtSizeEstimatesId:"",
    estimatedTime:"" ,
    estimatedDays:"" ,
    comments:"",
    createdBy:userId,
    editedBy:userId,
    estimationAreaId:ESTIMATION_AREA.Testing,
    canBeDeleted:true
      },
    ]);
    setErrors((prev)=>({
      ...prev,
      estimatedWorkItem_error:false   ,
      workItemDesc_error:false  ,
      tshirtSize_error:false  ,
      //scopeOfWork_error :false && [],
      estimatedTime_error :false  ,
     // estimatedDays_error :false  ,
      comments_error :false   ,
    }))
  };
  const handleDeleteRow = (index,id) => {
      
    setItemIndex(index);
    setId(id);
   // setId(id)
    setShoweleteModalPopUp(true);
  };

  
  const OnCloseDeleteModal = () => {  
    setShoweleteModalPopUp(false);
  };
  const OnDeleteModalYesClick=async()=>{
      
    if (itemIndex !== null ) { 
        // let updatedItems = [...items];
        // updatedItems.splice(itemIndex, 1); 
        // setItems(updatedItems);
        // setShoweleteModalPopUp(false);
        // setItemIndex(null); // Reset itemIndex
        if( id >0){
  const item = {
          estimationDataId: id, // Assuming 'id' is the identifier of the item you want to delete
          isDelete: true,
        };
        try{
          const response=await GlobalEstimationService.SaveGeneral([item]);
        if(response.status===HTTPResponse.OK){
           ShowToastMessage(ToastMessages.DataDelete, ToastMessageType.Success);
        }
        }
        catch (error) {
          //ToggleLoader(false);
          ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
      }
        }
      
        
        const updatedItems = [...items];
        updatedItems.splice(itemIndex, 1);
        setItems(updatedItems);
        setShoweleteModalPopUp(false);
        setItemIndex(null); // Reset itemIndex
      
      }
    };
    const Validate=()=>{
      
        
      let result=true
if(items.length===0){
  return result=false;
}
const estimatedWorkItemError=items.map((item,index)=>({
  index:index,
  name:item.workItem,
  error:isStringEmpty(item.workItem),
}));
const anyEstimatedWorkItemEmpty=estimatedWorkItemError.some((item)=>item.error);



const workItemDescError=items.map((item,index)=>({
  index:index,
  name:item.description,
  error:isStringEmpty(item.description),
}));
const anyWorkItemDescEmpty=workItemDescError.some((item)=>item.error);
      const tshirtSizeError= items.map((item,index)=>({
        index:index,
        name:item.tshirtSizeEstimatesId,
        error:item.tshirtSizeEstimatesId===0 || item.tshirtSizeEstimatesId==='',
      }));
      const anytshirtSizeEmpty=tshirtSizeError.some((item)=>item.error);

      // const scopeOfWorkError=items.map((item,index)=>({
      //   index:index,
      //   name:item.scopeOfWork,
      //   error:isStringEmpty(item.scopeOfWork),
      // }))
      //const anyScopeOfWorkEmpty=scopeOfWorkError.some((item)=>item.error);


      
      // const estimatedTimeError= items.map((item,index)=>({
      //   index:index,
      //   name:item.estimatedTime,
      //   error:isStringEmpty(item.estimatedTime),
      // }))
      // const anyEstTimeEmpty=estimatedTimeError.some((item)=>item.error);

      // const estimatedDaysError=items.map((item,index)=>({
      //   index:index,
      //   name:item.estimatedDays,
      //   error:isStringEmpty(item.estimatedDays)
      // }))
     // const anyEstDaysEmpty=estimatedDaysError.some((item)=>item.error);

      // const commentsError=items.map((item,index)=>({
      //   index:index,
      //   name:item.comments,
      //   error:isStringEmpty(item.comments)
      // }))
      // const anyCommentsEmpty=commentsError.some((item)=>item.error);

      setErrors((prev)=>({
        ...prev,
        estimatedWorkItem_error:estimatedWorkItemError,
        workItemDesc_error:workItemDescError,
        tshirtSize_error:tshirtSizeError,
       // scopeOfWork_error:scopeOfWorkError,
        //estimatedTime_error:estimatedTimeError,
      //  estimatedDays_error:estimatedDaysError,
        //comments_error:commentsError
      }))

      if(anytshirtSizeEmpty 
        // || anyEstTimeEmpty 
       // ||anyEstDaysEmpty 
        ||anyEstimatedWorkItemEmpty ||anyWorkItemDescEmpty
       // ||anyCommentsEmpty
        ){
        result=false;
      }
      return result;
    }
    const OnSave=async()=>{
      if(Validate()){
          
        if(isEdited){
           setConfirmModal(true);
        }
       else{
        OnYesConfirmModal();
       }
      }
 
    }
    const OnYesConfirmModal=async()=>{
        
  
        try{
        
            
        const response= await GlobalEstimationService.SaveGeneral(items);
        //const response=[]
        if (response.status===HTTPResponse.OK){
         await ShowToastMessage(ToastMessages.Added,ToastMessageType.Success); 
          setConfirmModal(false);
          setIsEdited(false);
          
         await getGeneralData();
        }
        else{
          ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
        }
   
      
      }
      catch (error) {
        ToggleLoader(false);
        ShowToastMessage(ToastMessages.Fail, ToastMessageType.Fail);
    }
    }
    const tshirtSizeOptions =props &&  props.tshirtSizeList.map((item) => ({
      label: item.label,  
      value: item.value,
      estimatedTime:item.estimatedTime
    }));
    
    const OnCancelConfirmModal=()=>{
      setConfirmModal(false);
          }
          
  return (
    <>
      {confirmModal && (
              <DeleteModal
              globalEstimation={true}
              onYesClick={OnYesConfirmModal}
                onNoClick={OnCancelConfirmModal}
                textToDisplay={"Are you sure you want to delete?"}
              />
            )}
     {showDeleteModalPopUp && (
              <DeleteModal
                showDeleteModal={showDeleteModalPopUp}
                onYesClick={OnDeleteModalYesClick}
                onNoClick={OnCloseDeleteModal}
                textToDisplay={"Are you sure you want to delete?"}
              />
            )}
      <div className="container-fluid Tshirt">
        <div
          className="innerBox"
          style={{
            borderRadius: "0px",
            padding: "2px 21px 10px",
          }}
        >
          {/* <a
                      style={{
                        backgroundColor: " #ebe6f5",
                        fontFamily: "Lato-Bold",
                        color: "#684e85",
                        height: "10px",
                      }}
                    >
                      {" "}
                      Swimlanes
                    </a> */}

          <div className="boxContenn" style={{ marginTop: "8px" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead
                style={{
                  //border: "1px solid #000",
                  padding: "5px",
                  textAlign: "center",
                  backgroundColor: "#684e85",
                  color: "white",
                  fontFamily: "Lato-Medium",
                }}
              >
                <tr style={{height:'35px'}}>
                  <th style={{ padding: "5px", textAlign: "center" }}>
                    Estimated Work Item
                  </th>
                  <th style={{ padding: "5px", textAlign: "center" }}>
                    Work Item Description
                  </th>
                  <th style={{ padding: "5px", textAlign: "center" }}>
                    T-Shirt Size
                  </th>
                  <th style={{ padding: "5px", textAlign: "center" }}>
                    Estimated Time (Hrs)
                  </th>
                  <th style={{ padding: "5px", textAlign: "center" }}>
                    Estimated Time (Days){" "}
                  </th>
                  <th style={{ padding: "5px", textAlign: "center" }}>
                    Comments
                  </th>
                  <th style={{ padding: "5px", textAlign: "center" }}>
                  </th>
                </tr>
              </thead>

              <tbody>
                {items && items.length>0 && items.map((item, index) => (
                  <tr key={index} style={{ marginBottom: "5px", height: "65px" }}>
                    <td style={{ textAlign: "center" }}>
                      <input
                        style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "90%", height: "35px", borderRadius: "0.25rem" }}
                        type="text"
                        value={item.workItem}
                        maxLength={MaxCharacterCount.WorkItem}
                        onChange={(e) =>
                          handleInputChange(index, "workItem", e.target.value,item.estimationDataId)
                        }
                      />
                      <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                                      {errors.estimatedWorkItem_error && (
                                        <div
                                          // style={{
                                          //   position: "absolute",
                                          //   bottom: "0",
                                          //   width: "100%",
                                          //   display: "flex",
                                          //   justifyContent: "center",
                                          //   marginBottom: "-2px",
                                          // }}
                                          style={{   }}
                                        >
                                          <ErrorField
                                            show={errors.estimatedWorkItem_error[index]?.error}
                                            message={EstimationError.WorkItem}
                                          />
                                        </div>
                                      )}
                                    </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "90%", height: "35px", borderRadius: "0.25rem" }}
                        type="text"
                        value={item.description}
                        maxLength={MaxCharacterCount.EstDescription}
                        onChange={(e) =>
                          handleInputChange(index, "description", e.target.value,item.estimationDataId)
                        }
                      />
                      <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                                      {errors.workItemDesc_error && (
                                        <div
                                          // style={{
                                          //   position: "absolute",
                                          //   bottom: "0",
                                          //   width: "100%",
                                          //   display: "flex",
                                          //   justifyContent: "center",
                                          //   marginBottom: "-2px",
                                          // }}
                                           style={{   }}
                                        >
                                          <ErrorField
                                            show={errors.workItemDesc_error[index]?.error}
                                            message={EstimationError.Description}
                                          />
                                        </div>
                                      )}
                                    </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Select
                        inputId="tshirtSizeEstimatesId"
                        placeholder="Please Select"
                        options={tshirtSizeOptions} 
                        // Example: Use value and options if needed
                         value={ tshirtSizeOptions.find((i)=>i.value=== item.tshirtSizeEstimatesId)}
                        // options={tshirtSizeOptions}
                        onChange={(e) =>
                          handleInputChange(index, "tshirtSizeEstimatesId", e.value,item.estimationDataId)
                        }
                        menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
                                      menuPosition="fixed" // Use fixed positioning for the menu
                                      styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu is on top of other elements
                                      }}
                      />
                      <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                                      {errors.tshirtSize_error && (
                                        <div
                                          // style={{
                                          //   position: "absolute",
                                          //   bottom: "0",
                                          //   width: "100%",
                                          //   display: "flex",
                                          //   justifyContent: "center",
                                          //   marginBottom: "-2px",
                                          // }}
                                           style={{   }}
                                        >
                                          <ErrorField
                                            show={errors.tshirtSize_error[index]?.error}
                                             message={EstimationError.TShirtSize}
                                          />
                                        </div>
                                      )}
                                    </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {/* <input
                        style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "90%", height: "35px", borderRadius: "0.25rem" }}
                        type="text"
                        value={
                          tshirtSizeOptions.find((i) => i.value === item.tshirtSizeEstimatesId)?.estimatedTime || 0
                        }                  
                        onChange={(e) =>
                          handleInputChange(index, "estimatedTime", e.target.value)
                        }
                        disabled
                      /> */}
                     { tshirtSizeOptions.find((i) => i.value === item.tshirtSizeEstimatesId)?.estimatedTime || 0}
                      <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                                      {errors.estimatedTime_error && (
                                        <div
                                          // style={{
                                          //   position: "absolute",
                                          //   bottom: "0",
                                          //   width: "100%",
                                          //   display: "flex",
                                          //   justifyContent: "center",
                                          //   marginBottom: "-2px",
                                          // }}
                                           style={{   }}
                                        >
                                          <ErrorField
                                            show={errors.estimatedTime_error[index]?.error}
                                            message={"Required"}
                                          />
                                        </div>
                                      )}
                                    </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {/* <input
                        style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "90%", height: "35px", borderRadius: "0.25rem" }}
                        type="text"
                        value={
                          tshirtSizeOptions.find((i) => i.value === item.tshirtSizeEstimatesId)?.estimatedTime/8 || 0
                        }   
                        onChange={(e) =>
                          handleInputChange(index, "estimatedDays", e.target.value)
                        }
                        disabled
                      /> */}
                     {tshirtSizeOptions.find((i) => i.value === item.tshirtSizeEstimatesId)?.estimatedTime/8 || 0}  
                      <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                                      {errors.estimatedDays_error && (
                                        <div
                                          // style={{
                                          //   position: "absolute",
                                          //   bottom: "0",
                                          //   width: "100%",
                                          //   display: "flex",
                                          //   justifyContent: "center",
                                          //   marginBottom: "-2px",
                                          // }}
                                           style={{   }}
                                        >
                                          <ErrorField
                                            show={errors.estimatedDays_error[index]?.error}
                                            message={"Required"}
                                          />
                                        </div>
                                      )}
                                    </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        style={{ border: "1px solid #ced4da", padding: "0px 6px", width: "90%", height: "35px", borderRadius: "0.25rem" }}
                        type="text"
                        value={item.comments}
                        maxLength={MaxCharacterCount.EstComments}
                        onChange={(e) =>
                          handleInputChange(index, "comments", e.target.value,item.estimationDataId)
                        }
                      />
                      <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        position:'absolute',width:'100%',bottom:'1px'
                                      }}
                                    >
                                      {errors.comments_error && (
                                        <div
                                          // style={{
                                          //   position: "absolute",
                                          //   bottom: "0",
                                          //   width: "100%",
                                          //   display: "flex",
                                          //   justifyContent: "center",
                                          //   marginBottom: "-2px",
                                          // }}
                                        >
                                          <ErrorField
                                            show={errors.comments_error[index]?.error}
                                            message={"Required"}
                                          />
                                        </div>
                                      )}
                                    </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                    {item.canBeDeleted && (  <button onClick={() => handleDeleteRow(index,item.estimationDataId)} title="Delete">
                        <img
                          src="Assets/images/deleteIcon.png"
                          style={{ width: "24px", height: "26px" }}
                        />
                      </button>
                    )}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* <ErrorField
                      show={error.newItem}
                      message={CommonErrorMessages.DuplicateSwminName}
                    /> */}

          {/* <div className="showNewInputField">
                      <button
                        className="showNewInputField"
                       // onClick={handleNewInputFields}
                      >
                        <img
                          src="Assets/images/AddIcons.png"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "4px",
                          }}
                        />{" "}
                        Add New Swimlane
                      </button>
                    </div> */}
        </div>
        <div className="save-cancel-sec">
          <button
            id="btnCreateAnother"
            name="btncreateAnother"
            className="save-btn"
            onClick={handleAddNewSize}
            //disabled={isProcessing} // Disable when processing
          >
            <span>Add New</span>
          </button>

          <button
            className="save-btn custom-width"
            //disabled={isProcessing}
            onClick={OnSave}
          >
            Save
          </button>
          {/* <button className="cancel-btn custom-width">Cancel</button> */}
        </div>
      </div>
    </>
  );
};
export default Testing;
