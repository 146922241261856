import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ManageUsersService } from "../WebApiServices/ManageUsers.Service";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  HTTPResponse,
  MaxCharacterCount,
  ROLE_TYPE,
  Roles,
  Team,
  ToastMessageType,
} from "../Helpers/Enums";
import ErrorField from "../Components/ErrorField";
import DeleteModal from "../Components/DeleteModal";
import BreadcrumbComponent from "../Components/BreadcrumbComponent";
// Importing a function from another module

import {
  ToastMessages,
  LoginPageText,
  ManageUser,
} from "../Helpers/HelperText";
import {
  GetLocalStorageData,
  IsEmailValid,
  isStringEmpty,
  AddLocalStorageData,
  RemoveLocalStorageData,
  GetLoggedInUserID,
  formatCustomDate,
  changePageTitle
} from "../Helpers/Utilities";
import { ColorPicker } from "primereact/colorpicker";
import { AppContext, useUIContext } from "../Contexts/AppContextProvider";
import { LoginService } from "../WebApiServices/Login.service";
import { ProjectService } from "../WebApiServices/Project.service";
import CheckboxDropdown from "../Components/CheckBoxDropdown";
import ErrorPage from "./ErrorPage";
import Cropper from 'react-easy-crop';
import { Slider } from '@material-ui/core';

const AddEditEmployee = (props) => {
  const minDate = new Date(2004, 0, 1); // January is represented by 0
  const maxDate = new Date(2024, 11, 31); // December is represented by 11
  const [profileImage, setProfileImage] = useState("");
  const [profileImageOnChange, setProfileImageOnChange] = useState("");
  const [profileImageId, setProfileImageId] = useState("");
  const { editUser, EditProfile } = props;
  const { ShowToastMessage, ToggleLoader } = useContext(AppContext);
  const [addStyle, setStyle] = useState(false);
  const [newlySelectedProjects, setNewlySelectedProjects] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const { LogOut } = useUIContext();
  const [employeeId, setEmployeeId] = useState(0);
  const [previousSelectedOptions, setPreviousSelectedOptions] = useState([]);
  const loggedInUserId = GetLoggedInUserID();
  const navigate = useNavigate();
  const location = useLocation();
  const isEditEmployeePage = location.pathname.includes("EditEmployee");
  const [originalFile, setOriginalFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [isFromProfileClicked, setIsFromProfileClicked] = useState(GetLocalStorageData("isFromProfileClicked"));


  const updatesUser = (User) => {
    setUser(User);
  };


  // Initialize user state with provided data
  const [user, setUser] = useState({
    isLead:editUser ? editUser.isLead : false,
    employeeId: editUser ? editUser.employeeId : 0,
    firstName: editUser ? editUser.firstName : "",
    lastName: editUser ? editUser.lastName : "",
    email: editUser ? editUser.email : "",
    teamDropDown: editUser
      ? { value: editUser.teamId, label: editUser.teamName }
      : "",
    roleDropDown: editUser
      ? { value: editUser.roleId, label: editUser.roleName }
      : "",
    email: editUser ? editUser.email : "",
    phoneNo: editUser ? editUser.phoneNo : "",
    joiningDate: editUser ? editUser.joiningDate : null,
    terminationDate: editUser ? editUser.terminationDate : null,
    designation: editUser ? editUser.designation : "",
    color: editUser ? editUser.color : "#684e85",
    editedBy: editUser ? GetLocalStorageData("userId") : "",
    createdBy: editUser ? GetLocalStorageData("userId") : "",
    userPic: editUser
      ? editUser.profileImageId > 0
        ? editUser.userPic
        : null
      : null,
    // profileImageId: editUser ? editUser.profileImageId : "",
    status: editUser ? editUser.status : "",
    leaves: [],
    projectsData: [],
    //projectEmployeeList: editUser ? editUser.projectEmployeeList:[],
  });


  useEffect(() => {
    let isProfile = GetLocalStorageData("isFromProfileClicked");
    let profileMode = location.state !== null && location.state !== undefined && location.state.ProfileMode;

    if (isFromProfileClicked && isFromProfileClicked !== undefined && location.state !== null && location.state !== undefined && location.state.ProfileMode !== undefined) {
      setIsFromProfileClicked(profileMode);
    }
    else {
      setIsFromProfileClicked(isProfile);
    }

    let empDetails = location.state !== null && location.state !== undefined && location.state && location.state.employeeDetails;

    if (location.state.ProfileMode === false && empDetails !== null && empDetails !== undefined && empDetails.employeeId > 0) {
      changePageTitle("Edit Employee");
    }
    else if (location.state.ProfileMode === false && empDetails === undefined) {
      changePageTitle("Add Employee");
    }
    else {
      changePageTitle("Profile");
    }

    if (empDetails) {
      setUser({
        employeeId: empDetails.employeeId,
        firstName: empDetails.firstName,
        lastName: empDetails.lastName,
        email: empDetails.email,
        teamDropDown: { value: empDetails.teamId, label: empDetails.teamName },
        roleDropDown: { value: empDetails.roleId, label: empDetails.roleName },
        phoneNo: empDetails.phoneNo,
        joiningDate: empDetails.joiningDate,
        terminationDate: empDetails.terminationDate,
        designation: empDetails.designation,
        color: empDetails.color || "#684e85",
        editedBy: GetLocalStorageData("userId"),
        createdBy: GetLocalStorageData("userId"),
        userPic: empDetails.profileImageId > 0 ? empDetails.userPic : null,
        profileImageId: empDetails.profileImageId,
        projectEmployeeList: empDetails.projectEmployeeList,
        status: empDetails.status,
        leaves: empDetails.workLogDetailData || [],
        projectsData: empDetails.projectsData || [],
        isLead:empDetails.isLead
      });
      //SYJ - Commented to Show user profile img.
      // if ((isProfile !== undefined && isProfile !== null)) {
      // if (isProfile) {
      setProfileImageOnChange(empDetails.profileImageId > 0 ? empDetails.userPic : null);
      setProfileImage(empDetails.profileImageId > 0 ? empDetails.userPic : null);
      // }
      // else {
      //   setProfileImageOnChange(empDetails.profileImageId > 0 ? empDetails.userPic : null);
      //   setProfileImage(empDetails.profileImageId > 0 ? empDetails.userPic : null);
      // }

      setEmployeeId(empDetails.employeeId);
    }
  }, [location.state]);

  useEffect(() => {
    if (editUser != undefined) {
      setEmployeeId(user.employeeId);
      if (editUser) {
        setUser(editUser);
      } else {
        localStorage.removeItem("newlySelectedProjects");
        RemoveLocalStorageData("newlySelectedProjects");
      }
    }
  }, [editUser]);

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    email: false,
    invalidEmail: false,
    teamDropDown: false,
    emailExists: false,

    roleDropDown: false,
  });
  const [info, setInfo] = useState(false);
  const ValidateForm = async () => {
    const {
      firstName,
      lastName,
      email,
      teamDropDown,
      roleDropDown,
    } = user;

    setError((prev) => ({
      ...prev,
      firstName:
        isStringEmpty(firstName) ||
        firstName.length < MaxCharacterCount.UserName,
      lastName:
        isStringEmpty(lastName) || lastName.length < MaxCharacterCount.UserName,
      email: isStringEmpty(email),
      teamDropDown:
        teamDropDown === "" ? true : Number(teamDropDown.value) == 0,
      roleDropDown:
        roleDropDown === "" ? true : Number(roleDropDown.value) == 0,
    }));

    let result = false;

    if (
      firstName.length < MaxCharacterCount.UserName ||
      lastName.length < MaxCharacterCount.UserName ||
      isStringEmpty(firstName) ||
      isStringEmpty(lastName) ||
      isStringEmpty(email) ||
      teamDropDown === "" ||
      roleDropDown === ""
    ) {
      result = true;
    }
    if (!result && !isStringEmpty(email) && !IsEmailValid(email)) {
      setError((prev) => ({
        ...prev,
        invalidEmail: true,
      }));
      result = true;
    }

    if (!result && !isStringEmpty(email) && IsEmailValid(email)) {
      const response = await LoginService.CheckEmail(email, employeeId);
      if (response.data) {
        setError((prev) => ({
          ...prev,
          emailExists: true,
        }));
        result = true;
      } else {
        setError((prev) => ({
          ...prev,
          emailExists: false,
        }));
      }

      setError((prev) => ({
        ...prev,
        invalidEmail: false,
      }));
    }

    return result;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      if (!isStringEmpty(value) && !IsEmailValid(value)) {
        setError((prev) => ({
          ...prev,
          invalidEmail: true,
        }));
        setUser((prevUser) => ({
          ...prevUser,
          [name]: value,
        }));
      } else {
        setError((prev) => ({
          ...prev,
          [name]: false,
          invalidEmail: false,
          emailExists: false,
        }));
      }
    }

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    setUser({
      ...user,
      [name]: value,
      editedBy: GetLocalStorageData("userId"),
    });

    setError((prev) => ({
      ...prev,
      [name]: false,
    }));
  };
  const handleUpdateUser = async () => {
    ToggleLoader(true);
    //SYJ - Commented to Show user profile img.
    // if (isFromProfileClicked && isFromProfileClicked !== undefined) {
    //   AddLocalStorageData("isFromProfileClicked", isFromProfileClicked);
    // }
    // else if (!isFromProfileClicked && isFromProfileClicked !== undefined) {
    //   AddLocalStorageData("isFromProfileClicked", isFromProfileClicked);
    // }
    let isValidate = await ValidateForm();

    if (!isValidate) {
      // Construct user data including all fields and image
      const userData = {
        ...user,
        employeeId: employeeId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        teamDropDown: user.teamDropDown,
        teamId: user.teamDropDown.value,
        roleId: user.roleDropDown.value,
        roleDropDown: user.roleDropDown,
        phoneNo: user.phoneNo,
        joiningDate: user.joiningDate,
        terminationDate: user.terminationDate,
        designation: user.designation,
        color: user.color,
        editedBy: GetLocalStorageData("userId"),
        createdBy: GetLocalStorageData("userId"),
        profileImage: profileImage,
        profileImageId: user.profileImageId, //SYJ  Fixed profileimage deleting issue on color changes.
        projectEmployeeList: selectedProjects, // Include selected projects
        isLead:user.isLead
      };

      // Disable status if termination date is provided
      if (user.terminationDate) {
        userData.status = false;
      }

      // Create formData object
      let formData = new FormData();

      // Append ProfileData to formData
      formData.append("UserData", JSON.stringify(userData));

      // Append fileList if profileImage exists
      if (profileImage) {
        formData.append("fileList", profileImage);
      }
      const response = await ManageUsersService.AddEditUser(formData);

      if (user.terminationDate && response.data.isProjectLead === "IsLead") {
        ShowToastMessage(ToastMessages.EmployeeIsLead, ToastMessageType.Fail);
      }
      else {
        let isProfilemode = GetLocalStorageData("isFromProfileClicked");
        setIsFromProfileClicked(isFromProfileClicked);
        if (response.data && response.data.profileImageUrl !== undefined) {
          if (isProfilemode !== undefined && isProfilemode) {
            AddLocalStorageData("imagePath", response.data.profileImageUrl)
            AddLocalStorageData("isFromProfileClicked", isProfilemode)
            setProfileImageOnChange(response.data.profileImageUrl);
            setProfileImage(response.data.profileImageUrl);
            // AddLocalStorageData("roleID", response.data.roleId)          
          }
          else {
            //SYJ - added to Show user profile img.
            setProfileImageOnChange(response.data.profileImageUrl);
            setProfileImage(response.data.profileImageUrl);
          }
        } else {
          console.error("Profile image URL not found in response:", response);
        }
        UpdateAssignedProjectList();
        //&& response.data.employeeId === 0
        if (response.data.employeeId > 0) {
          setEmployeeId(response.data.employeeId);
        }
        if (user.employeeId === 0) {
          setEmployeeId(response.data.employeeId);
          await LoginService.SendWelcomeEmail(user.email); // Sending welcome email only when adding a new user and employee id is 0
        }

        if (response.status === HTTPResponse.OK) {
          ShowToastMessage(
            employeeId > 0 ? ManageUser.UserUpdate : ManageUser.UserAdd,
            ToastMessageType.Success
          );

          // Update dropdown values
          const updatedTeamDropDown = Team.find(
            (team) => team.value === user.teamDropDown.value
          );
          const updatedRoleDropDown = Roles.find(
            (role) => role.value === user.roleDropDown.value
          );
          setUser((prevUser) => ({
            ...prevUser,
            teamDropDown: updatedTeamDropDown,
            roleDropDown: updatedRoleDropDown,
          }));

          if (isFromProfileClicked)
            AddLocalStorageData("color", response.data.color);
          ToggleLoader(false);
          const updatedResponse = {
            ...response.data,
            //teamDropDown:updatedTeamDropDown,
            // roleDropDown: updatedRoleDropDown,
            teamName: updatedTeamDropDown.label,
            roleName: updatedRoleDropDown.label,
            userPic: response.data.profileImageUrl,
            isLead:response.data.isLead
          }
          navigate("/EditEmployee", {
            state: {
              employeeDetails: updatedResponse, ProfileMode: true
            },
          });
        } else if (response.status === HTTPResponse.Unauthorized) {
          LogOut();
        } else {
          ShowToastMessage(ToastMessages.UpdateFailed, ToastMessages.Fail);
        }
      }
    }

    ToggleLoader(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // You may want to perform additional validation here
    // setProfileImage(file);
    // setProfileImageOnChange(URL.createObjectURL(file));

    // AddLocalStorageData("imagePath", URL.createObjectURL(file));
    // setShowDeleteModal(false);
    if (file) {
      setOriginalFile(file); // Store the original file
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
        setShowCropModal(true);
      };
      reader.readAsDataURL(file);
      setProfileImage(file);
      setProfileImageOnChange(URL.createObjectURL(file));
      if (isFromProfileClicked) {
       // AddLocalStorageData("imagePath", URL.createObjectURL(file));
      }
      setShowDeleteModal(false);
    }
  };

  const handleDeleteImage = () => {
    setShowDeleteModal(true);
  };

  const OnDeleteModalYesClick = async () => {
    try {
      ToggleLoader(true);
      setProfileImageOnChange(null);
      setProfileImage(null);
      setUser({ ...user, profileImageId: null });

      setShowDeleteModal(false);
      setisDeleteYesClicked(true);
      ShowToastMessage(ManageUser.ProfilePicDelete, ToastMessageType.Success);
    } catch (error) {
      console.error("Error deleting profile Image:", error);
    } finally {
      ToggleLoader(false);
    }
  };

  const OnCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const HandleDropdownChange = (e, id, drp) => {
    if(id==="roleDropDown"){
      if(user.isLead && user.roleDropDown?.value===3){
        ShowToastMessage(ToastMessages.IsLead,ToastMessageType.Fail);
        return
      }
    }
    // Sort the team names alphabetically
    const sortedTeamOptions = Team.slice().sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    // Update user state
    setUser((prev) => ({
      ...prev,
      [id]: e,
      [drp]: drp === "teamName" ? e.label : Number(e.value),
      teamId: drp === "teamName" ? e.value : prev.teamId,
      // Retain previous role and team data if they are not being updated
      role: drp === "role" ? e.value : prev.role,
      teamName: drp === "teamName" ? e.label : prev.teamName,
    }));

    // Update user state with sorted team names for dropdown options
    setUser((prevUser) => ({
      ...prevUser,
      [id]: e,
      [drp]: drp === "teamName" ? e.label : e.value,
      teamOptions: sortedTeamOptions, // Add sorted team options to state
    }));

    // Reset error state
    setError((prev) => ({
      ...prev,
      [id]: false,
    }));
  };


  const handlePhoneNumberChange = (event) => {
    const input = event.target.value;
    const filteredInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    const maxLength = 10;
    const trimmedInput = filteredInput.slice(0, maxLength); // Limit to 10 characters

    // Check if the trimmedInput length is less than 10
    const isError = trimmedInput.length < 10;

    // Update the user state and error state
    setUser({ ...user, phoneNo: trimmedInput });
    setError((prevError) => ({ ...prevError, phoneNo: isError }));
  };

  const handleDesignationChange = (event) => {
    setUser({ ...user, designation: event.target.value });
  };

  const handleColorChange = (newColor) => {
    if (!newColor.startsWith("#")) {
      newColor = `#${newColor}`;
    }

    setUser((prevUser) => ({
      ...prevUser,
      color: newColor.toUpperCase(),
    }));
    if (isFromProfileClicked)
      AddLocalStorageData("color", newColor.toUpperCase());
  };

  const [projectsAssigned, setProjectsAssigned] = useState([{}]);
  let employeId = user.employeeId;

  const getProjects = async () => {
    if (employeId > 0) {
      const respProject = await ProjectService.GetAllProjects();
      const resp = await ProjectService.GetProjectsAssignedToEmployee(employeId);
      // let filteredProjectIfProjLead = respProject.data.length > 0 && respProject.data.filter(option => option.leadID !== employeId);

      let updatedRespProjects = resp.data.map(option => {
        return {
          ...option,              // Spread the existing properties
          isChecked: option.isProjectLead  // Set isChecked to the value of isProjectLead
        };
      });


      // Assuming respProject.data is your array of project objects
      let updatedProjects = respProject.data.map(option => {
        // Check if the project lead ID matches the employee ID
        const isProjectLead = option.leadID === employeeId;

        // Return the updated project object
        return {
          ...option,
          isChecked: isProjectLead // Set isChecked to true if the employee is the project lead
        };
      });

      setProjectsAssigned(updatedRespProjects);
      setPreviousSelectedOptions(updatedRespProjects);
      setAllProjects(updatedProjects); //
    }
  };

  useEffect(() => {
    getProjects();
  }, [user.employeeId]);

  const [selectedProjects, setSelectedProjects] = useState([]);

  useEffect(() => {
    const employeeIdAndName = projectsAssigned.map((project) => ({
      releaseId: project.projectId,
      projectName: project.projectName,
      loggedInUser: GetLoggedInUserID(),
      isChecked: project.isProjectLead
    }));
    setSelectedProjects(employeeIdAndName);
    getLocalSelectedProjects();
  }, [projectsAssigned]);

  const UpdateAssignedProjectList = async () => {
    const selectedProjectsToSave =
      newlySelectedProjects.length > 0
        ? newlySelectedProjects.map((project) => project.releaseId)
        : selectedProjects.map((project) => project.releaseId);
    const loggedInUserId = await GetLocalStorageData("userId");

    const resp = await ProjectService.UpdateProjectsAssignedToEmployee(
      employeId,
      selectedProjectsToSave,
      loggedInUserId
    );

    if (resp.status === HTTPResponse.Unauthorized) {
      LogOut();
    } else {
      ShowToastMessage(ToastMessages.UpdateFailed, ToastMessages.Fail);
    }
  };
  const handleIconClick = (projectId) => {
    if (selectedProjects.includes(projectId)) {
      setInfo(true);

      setSelectedProjects((prev) => prev.filter((id) => id !== projectId));
    } else {
      setSelectedProjects((prev) => [...prev, projectId]);
    }
  };

  const getLocalSelectedProjects = () => {
    //const localSelectedProjects = localStorage.getItem("newlySelectedProjects");
    const localSelectedProjects = GetLocalStorageData("newlySelectedProjects");

    //GetLocalStorageData("newlySelectedProjects");
    const projectsArray =
      localSelectedProjects !== undefined && localSelectedProjects !== null
        ? JSON.parse(localSelectedProjects)
        : [];
    if (Array.isArray(projectsArray)) {
      const employeeExists = projectsArray.some(
        (project) => project.employeeId === employeId
      );

      if (employeeExists) {
        // Employee is found, retrieve data until that point
        const projectsUntilEmployee = projectsArray.filter(
          (project) => project.employeeId === employeId
        );
        setNewlySelectedProjects(projectsUntilEmployee);
      } else {
        // Employee is not found, append the new data
        //  setNewlySelectedProjects([]);
      }
    } else {
      // setNewlySelectedProjects([]);
    }
  };
  const [showDeleteModalPopUp, setShowDeleteModal] = useState(false);
  //const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleteYesClicked, setisDeleteYesClicked] = useState(false);

  const handleProjectSelectionChange = (selectedOptions) => {
    // Retrieve existing data from local storage
    const localSelectedProjects = GetLocalStorageData("newlySelectedProjects");
    const projectsArray =
      localSelectedProjects !== undefined && localSelectedProjects !== null
        ? JSON.parse(localSelectedProjects)
        : [];

    const renamedItems = selectedOptions.map((option, index) => ({
      employeeId: employeId,
      releaseId: option.releaseId,
      projectName: option.name,
      isChecked: option.isChecked,
      loggedInUser: GetLoggedInUserID(),
    }));

    setSelectedProjects(renamedItems);
    setNewlySelectedProjects(renamedItems);

    // Update user state with the updated projectEmployeeList
    const updatedProjectEmployeeList = renamedItems.map((project) => ({
      projectId: project.releaseId,
      projectName: project.projectName,
      loggedInUser: project.loggedInUser,
    }));

    setUser((prevUser) => ({
      ...prevUser,
      projectEmployeeList: updatedProjectEmployeeList,
    }));

    // Filter out items from projectsArray that do not belong to the current employee
    const updatedProjectsArray = projectsArray.filter(
      (item) => item.empId !== employeId
    );

    // Combine existing data with new data
    const combinedData = [...updatedProjectsArray, ...renamedItems];

    const uniqueIdentifiers = new Set();
    const uniqueData = combinedData.filter((item) => {
      const identifier = `${item.employeeId}-${item.releaseId}`;
      if (!uniqueIdentifiers.has(identifier)) {
        uniqueIdentifiers.add(identifier);
        return true;
      }
      return false;
    });
    AddLocalStorageData("newlySelectedProjects", JSON.stringify(uniqueData));
  };

  const [data, setData] = useState({
    date: new Date(),
  });

  const computeInitials = (firstName, lastName) => {
    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();
    return firstInitial + lastInitial;
  };

  const handleExitAddEditEmployee = () => {
    ToggleLoader(true);
    navigate("/ManageUsers");
    ToggleLoader(false);
  };


  //Profile Image Crop
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = useCallback(async () => {
    try {
      const croppedFile = await getCroppedImg(imageSrc, croppedAreaPixels, originalFile);

      setProfileImage(croppedFile)
      if (isFromProfileClicked)
        //AddLocalStorageData("imagePath", URL.createObjectURL(croppedFile));
      setProfileImageOnChange(URL.createObjectURL(croppedFile));
      setShowCropModal(false);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  }, [croppedAreaPixels, imageSrc, originalFile]);

  const getCroppedImg = async (imageSrc, croppedAreaPixels, originalFile) => {
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Failed to generate blob.'));
            return;
          }

          const croppedFile = new File([blob], originalFile.name, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
          });

          resolve(croppedFile);
        }, 'image/jpeg');
      };

      image.onerror = (error) => reject(error);
    });
  };

  let roleId = Number(GetLocalStorageData("roleID"));
  let isFromProfilPage = location.state !== undefined && location.state !== null && location.state.ProfileMode !== undefined && location.state.ProfileMode !== null ? location.state.ProfileMode : false;

  return (
    <>
      {roleId === ROLE_TYPE.ADMIN || roleId === ROLE_TYPE.LEAD || isFromProfilPage ?
        (
          <div>
            {showDeleteModalPopUp && (
              <DeleteModal
                showDeleteModal={showDeleteModalPopUp}
                onYesClick={OnDeleteModalYesClick}
                onNoClick={OnCloseDeleteModal}
                textToDisplay={"Are you sure you want to delete?"}
              />
            )}

            {isFromProfileClicked ?
              (<section className="breadcrumb-block" style={{ backgroundColor: "#b5a5c4" }}>
                <div className="container-fluid">
                  <ul className="breadcrumb">
                    <li
                      style={{
                        display: "block",
                      }}
                    >
                      <React.Fragment>
                        <a>Profile</a>
                      </React.Fragment>
                    </li>
                  </ul></div>
              </section>) : (
                <section className="breadcrumb-block">
                  <BreadcrumbComponent />
                </section>)
            }
            <section className="add-employee-body">
              <div className="container-fluid" style={{ maxWidth: "980px!important" }}>
                <div className="add-employee">
                  <div className="row flex-switch">
                    <div className="col-12 col-md-12 col-lg-8">
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>
                            First Name <span className="isRequiredField">*</span>
                          </label>
                          {/* <input className="form-control custom-input" type="text" /> */}
                          <input
                            className="form-control"
                            type="text"
                            name="firstName"
                            maxLength={MaxCharacterCount.UserFNLN}
                            value={user.firstName}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                          {error.firstName ? (
                            <ErrorField
                              show={error.firstName}
                              message={ManageUser.FirstNameEmpty}
                            />
                          ) : (
                            <div style={{ height: "24px" }}>{"       "}</div>
                          )}
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>
                            Last Name <span className="isRequiredField">*</span>
                          </label>
                          {/* <input className="form-control custom-input" type="text" /> */}

                          <input
                            className="form-control"
                            type="text"
                            name="lastName"
                            value={user.lastName}
                            maxLength={MaxCharacterCount.UserFNLN}
                            onChange={handleInputChange}
                          />
                          {error.lastName ? (
                            <ErrorField
                              show={error.lastName}
                              message={ManageUser.LastNameEmpty}
                            />
                          ) : (
                            <div style={{ height: "24px" }}>{"       "}</div>
                          )}
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>
                            Email <span className="isRequiredField">*</span>
                          </label>
                          {/* <input className="form-control custom-input" type="text" value="abc@gmail.com" /> */}
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            value={user.email}
                            disabled={isFromProfileClicked ? true : false}
                            maxLength={MaxCharacterCount.UserEMail}
                            onChange={handleInputChange}
                          />
                          {error.email || error.invalidEmail || error.emailExists ? (
                            <>
                              {" "}
                              <ErrorField
                                show={error.email}
                                message={ManageUser.EmailEmpty}
                              />
                              <ErrorField
                                show={error.invalidEmail}
                                message={LoginPageText.InvalidEmail}
                              />
                              <ErrorField
                                show={error.emailExists}
                                message={LoginPageText.EmailExists}
                              />{" "}
                            </>
                          ) : (
                            <div style={{ height: "24px" }}>{"       "}</div>
                          )}
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>Phone Number</label>
                          {/* <input className="form-control custom-input" type="number" /> */}
                          <input
                            className="form-control custom-input"
                            type="text"
                            value={user.phoneNo}
                            maxLength={10}
                            minLength={10} // Maximum length set to 10 characters
                            onChange={handlePhoneNumberChange}
                          />
                          {error.phoneNo && (
                            <div className="phoneerror">{ManageUser.Phone}</div>
                          )}
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>
                            Team <span className="isRequiredField">*</span>
                          </label>
                          {/* <input className="form-control custom-input" type="text" value="Software" /> */}

                          <Select
                            inputId="teamDropDown"
                            placeholder="Please Select"
                            value={user.teamDropDown}
                            isDisabled={isFromProfileClicked ? true : false}
                            options={Team.slice().sort((a, b) =>
                              a.label.localeCompare(b.label)
                            )}
                            onChange={(e) =>
                              HandleDropdownChange(e, "teamDropDown", "teamName")
                            }
                          />
                          {error.teamDropDown ? (
                            <ErrorField
                              show={error.teamDropDown}
                              message={ManageUser.TeamEmpty}
                            />
                          ) : (
                            <div style={{ height: "24px" }}>{"       "}</div>
                          )}
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>
                            Role <span className="isRequiredField">*</span>
                          </label>

                          <Select
                        isDisabled={isFromProfileClicked}
                            inputId="roleDropDown"
                            placeholder="Please Select"
                            value={user.roleDropDown}
                            options={Roles}
                            onChange={(e) =>
                              HandleDropdownChange(e, "roleDropDown", "roleId")
                            }
                          />
                          {error.roleDropDown ? (
                            <ErrorField
                              show={error.roleDropDown}
                              message={ManageUser.RoleEmpty}
                            />
                          ) : (
                            <div style={{ height: "24px" }}>{"       "}</div>
                          )}
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>Designation</label>
                          <input
                            className="form-control custom-input"
                            type="text"
                            value={user.designation}
                            onChange={handleDesignationChange}
                            maxLength={MaxCharacterCount.Designation}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mt-3">
                          <label>Joining Date</label>
                          <div>
                            <label>
                              <DatePicker
                                placeholderText="Select Date"
                                className=" form-control "
                                //disabled={editMode}

                                dateFormat="dd-MM-yyyy"
                                //selected={data.date}
                                onChange={(date) => {
                                  setData((prev) => ({
                                    ...prev,
                                    date: date,
                                  }));
                                  setUser((prev) => ({ ...prev, joiningDate: date }));
                                }}
                                showIcon={true}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                // maxDate={new Date()}
                                minDate={minDate} // Set minimum date
                                maxDate={maxDate} // Set maximum date
                                selected={
                                  user.joiningDate
                                    ? new Date(user.joiningDate)
                                    : null
                                }
                                popperClassName="ag-custom-component-popup"
                                scrollableYearDropdown={true}
                                showYearDropdown
                                showMonthDropdown
                                todayButton="Today"
                              />{" "}
                            </label>
                          </div>
                        </div>


                        {/* Conditionally render these divs based on the page title */}
                        {isEditEmployeePage && (
                          <>
                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                              <label>Termination Date</label>

                              <div>
                                <label>
                                  <DatePicker
                                    className="form-control"
                                    dateFormat="dd-MM-yyyy"
                                    //selected={data.date}
                                    onChange={(date) => {
                                      setData((prev) => ({
                                        ...prev,
                                        date: date,
                                      }));
                                      setUser((prev) => ({
                                        ...prev,
                                        terminationDate: date,
                                      }));
                                    }}
                                    showIcon={true}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    minDate={
                                      user.joiningDate
                                        ? new Date(user.joiningDate)
                                        : null
                                    } // Set minimum date to the joining date
                                    maxDate={new Date()}
                                    selected={
                                      user.terminationDate
                                        ? new Date(
                                          user.terminationDate
                                        )
                                        : ""
                                    }
                                    placeholderText="Select Date"
                                    popperClassName="ag-custom-component-popup"
                                    scrollableYearDropdown={true}
                                    showYearDropdown
                                    showMonthDropdown
                                    todayButton="Today"
                                  />
                                </label>
                              </div>
                              <span className="text-muted">
                                Reassign projects if necessary before selecting the
                                termination date
                              </span>
                            </div>
                            {/*SYJ- Commneted as -> https://edotpulse.com/EditActionItem/PULSE-601 */}
                            {/* <div className="col-12 col-md-6 col-lg-6 mt-3">
                              <label>Assign Projects To</label>
                              <br />
                              <a href="/ManageProjects" className="custom-link">
                                Manage projects
                              </a>
                            </div> */}

                            {isFromProfileClicked ? (
                              <div className="col-12 col-md-6 col-lg-6 mt-3">
                                <label>Projects</label>
                                <div className="bubble-sec" style={{ display: 'flex', whiteSpace: 'nowrap', marginTop: '10px' }}>
                                  {roleId === ROLE_TYPE.ADMIN ? (
                                    <div className="bubble-sec1">
                                      <div className="bubble-display">
                                        <div>All Projects</div>
                                      </div>
                                    </div>
                                  ) : (
                                    user.projectsData.length === 0 ? "No Projects" :
                                      user.projectsData.map(project => (
                                        <div className="bubble-sec1" key={project.id} style={{ display: 'inline-block', marginRight: '10px' }}>
                                          <div className="bubble-display">
                                            <div>{project.name}</div>
                                          </div>
                                        </div>
                                      ))
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="col-12 col-md-6 col-lg-6 mt-3">
                                <label>Projects</label>
                                {info && (
                                  <span
                                    className="isRequiredField"
                                    style={{ fontSize: "smaller" }}
                                  >
                                    If you uncheck the checkbox and click Save, the project will
                                    be removed.
                                  </span>
                                )}
                                <div className="row">
                                  <div className="row">
                                    <div className="col-1 col-md-1 col-lg-1"></div>
                                  </div>
                                  <CheckboxDropdown
                                    optionsFilter={() => null}
                                    onChanges={() => null}
                                    loading={() => null}
                                    AddStyle={addStyle}
                                    scroll={true}
                                    isFromEmployee={true}
                                    releasesSelected={
                                      newlySelectedProjects.length > 0
                                        ? newlySelectedProjects.map((project) => ({
                                          releaseId: project.releaseId,
                                          name: project.projectName,
                                          isChecked: project.isChecked
                                        }))
                                        : selectedProjects.length > 0 &&
                                        selectedProjects.map((selected) => ({
                                          releaseId: selected.releaseId,
                                          name: selected.projectName,
                                          isChecked: selected.isChecked
                                        }))
                                    }
                                    selectEmployee={false}
                                    options={allProjects.sort((a, b) => a.name.localeCompare(b.name)).map((project) => ({
                                      releaseId: project.id,
                                      name: project.name,
                                      isChecked: project.isChecked
                                    }))}
                                    onChange={(dd) => {
                                      handleProjectSelectionChange(dd);
                                    }}
                                  />
                                </div>
                              </div>)
                            }
                            <div className="col-12 col-md-6 col-lg-6 mt-3">
                              <label>My Leaves</label>
                              {user.leaves.length > 0 ? (
                                <div className="leaves-sec" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                  {user.leaves.map((leave, index) => (
                                    <div key={index} className="leaves-main">
                                      <p className="leave-period">{formatCustomDate(leave.loggedOn)} </p>
                                      <p className="leave-reason">{leave.misctaskName} - {leave.comments} </p>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <p>No leaves data available</p>
                              )}
                            </div>

                          </>


                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-4 mt-3">
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-12 col-md-6 col-lg-12">
                          <div className="ct-profile-fbox">
                            <div className="ct-profile-sec">
                              <div className="image-wrap">
                                {profileImageOnChange != null &&
                                  profileImageOnChange != undefined &&
                                  profileImageOnChange != "" ? (
                                  <img
                                    src={profileImageOnChange}
                                    className="img-fluid"
                                    alt="Profile"
                                  />
                                ) : (
                                  <div
                                    className="user-profile-img-sec"
                                    style={{ backgroundColor: user.color }}
                                  >
                                    <div className="user-profile-img">
                                      <span>
                                        {computeInitials(
                                          user.firstName,
                                          user.lastName
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="info">
                                {/* Edit profile image */}
                                <label htmlFor="myfile" className="edit-profile">
                                  {profileImageOnChange != null &&
                                    profileImageOnChange != undefined &&
                                    profileImageOnChange != "" ? (
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                      style={{ color: "white", cursor: "pointer" }}
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-plus-square-o"
                                      aria-hidden="true"
                                      style={{ color: "white", cursor: "pointer" }}
                                    ></i>
                                  )}
                                </label>
                                {/* Delete profile image */}
                                {profileImageOnChange && (
                                  <span className="div-line"></span>
                                )}
                                {profileImageOnChange && (
                                  <span
                                    className="delete-profile"
                                    onClick={handleDeleteImage}
                                  >
                                    <i className="fa fa-trash" aria-hidden="true" style={{ cursor: "pointer" }}></i>
                                  </span>
                                )}
                              </div>
                              {/* File input for selecting new profile image */}
                              <input
                                type="file"
                                accept="image/*"
                                id="myfile"
                                className="file"
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                              />
                            </div>
                            {showCropModal && (
                              <div className="crop-modal">
                                <Cropper
                                  image={imageSrc}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={1}
                                  onCropChange={setCrop}
                                  onZoomChange={setZoom}
                                  onCropComplete={onCropComplete}
                                />
                                <Slider
                                  value={zoom}
                                  min={1}
                                  max={3}
                                  step={0.1}
                                  aria-labelledby="Zoom"
                                  onChange={(e, zoom) => setZoom(zoom)}
                                />
                                <div className="crop-controls">
                                  <button style={{
                                    zIndex: 9999, color: "#fff",
                                    backgroundColor: "#483567",
                                    padding: "2px 13px",
                                    height: "33px",
                                    borderRadius: " 3px",
                                    border: "1px solid #483567"
                                  }} onClick={handleCrop}>Crop</button>
                                  <button style={{
                                    zIndex: 9999, color: "#483567",
                                    backgroundColor: "#fff",
                                    padding: "2px 13px",
                                    height: "33px",
                                    borderRadius: " 3px",
                                    border: "1px solid #483567"
                                  }}
                                    onClick={() => {
                                      setShowCropModal(false);
                                      setProfileImageOnChange(null);
                                      setProfileImage(null);
                                      AddLocalStorageData("imagePath", null);
                                    }}
                                  >Cancel</button>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="assign-color-sec mt-3">
                            <div className="row">
                              <div className="col-5 col-xs-4 col-sm-3 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
                                <label>Assign Color</label>
                              </div>
                              <div className="col-7 col-xs-8 col-sm-9 col-md-7 col-lg-7 col-xl-8 col-xxl-7">
                                <div className="custom-colorpicker">
                                  <ColorPicker
                                    value={user.color}
                                    onChange={(e) => handleColorChange(e.value)}
                                  />
                                </div>

                                <div style={{ height: "24px" }}>{"       "}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3"></div>

                      </div>



                    </div>
                  </div>

                  <div className="save-cancel-sec floating-btn-profile">
                    <button
                      className="save-btn custom-width"
                      onClick={() => {
                        handleUpdateUser();
                      }}
                    >
                      Save
                    </button>
                    <button className="cancel-btn custom-width"
                      onClick={handleExitAddEditEmployee}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </section >
          </div >
        )
        : (<ErrorPage />)}
    </>
  );
};

export default AddEditEmployee;
